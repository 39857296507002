import { url } from "../common/const";
import { get, post, post_common } from "../common/https";


/**This service for get Inquiry list*/
export const GetInquiryList = () => {
  const getdata = get(`${url}/api/Inquiry/list`);
  return getdata;
};

