
import React, {useState, useEffect} from "react";
import {
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Tooltip,
  Row,
  Card,CardBody,CardHeader,FormFeedback,Collapse
} from "reactstrap";
import { destinationTypes } from "../../../components/constants/destinationTypes";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import SearchableSelectOption from "../../../components/Common/GloablMaster/SearchableSelectOption";
import CurrencySymbol from "../../../common/CurrencySymbol";
import CitySiteSeeingImages from "./CitySiteSeeingImages";


import { setIn, useFormik } from "formik";
import * as Yup from "yup";
//import LoadingButton from "../../common/LoadingButton";


const SlidePopupModal = ({
   slideDetails 
  ,isOpen 
  ,tog_scroll
  ,size
  ,scrollable
  ,readOnly
 
}) => {
  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");

  

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {  
      operation:   slideDetails?.operation,
      slide_name: slideDetails?.slide_name || "",
      slide_title: slideDetails?.slide_title || "",    
      slide_description: slideDetails?.slide_description || "",    
    },
    validationSchema: Yup.object({       
      slide_name: Yup.string().required("Please enter Slide Name"),      
    }),
    onSubmit: (values) => {
        alert(values);
        //SaveInquiryProcessData(values);      
          
    },
  });

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={tog_scroll}
        centered
        size={size}
        scrollable={scrollable}
      >
        <ModalHeader className="bg-light p-3">{`${!readOnly ? "Add" : "Edit"} - Home Slide`}</ModalHeader>
        <ModalBody>
          <form>
            
              <Row>
                      <Col lg={12}>                      
                              
                          <Row>
                              <Col lg={6}>
                                  <div className="mb-3">
                                      <Label  className="form-label" htmlFor="name">Name</Label>
                                  
                                      <Input
                                      type="text"
                                      id="slide_name"
                                      name="slide_name"
                                      className="form-control"                                   
                                      placeholder="Enter Slide Name"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.slide_name || ""}
                                      invalid={
                                      validation.touched.slide_name &&
                                      validation.errors.slide_name
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.slide_name &&
                                  validation.errors.slide_name ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.slide_name}
                                      </FormFeedback>
                                  ) : null}                                    

                                  </div>
                              </Col>
                              <Col lg={6}>

                              <div className="mb-3">
                                  <Label htmlFor="email" className="form-label">
                                      Title
                                  </Label>
                                  <Input
                                      type="text"
                                      id="text"
                                      name="text"
                                      className="form-control"                                   
                                      placeholder="Enter Slide Title"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.slide_title || ""}
                                      invalid={
                                      validation.touched.slide_title &&
                                      validation.errors.slide_title
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.slide_title &&
                                  validation.errors.slide_title ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.slide_title}
                                      </FormFeedback>
                                  ) : null}
                                  </div>                                 

                                  
                              </Col>
                          </Row>     

                          <Row>
                              <Col lg={12}>
                                  <div className="mb-3">
                                      <Label  className="form-label" htmlFor="name">Description</Label>
                                  
                                      <Input
                                      type="textarea"
                                      name="name"
                                      className="form-control"
                                      id="name"
                                      placeholder="Enter Slide Description"
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      value={validation.values.name || ""}
                                      invalid={
                                      validation.touched.name &&
                                      validation.errors.name
                                          ? true
                                          : false
                                      }
                                  />
                                  {validation.touched.name &&
                                  validation.errors.name ? (
                                      <FormFeedback type="invalid">
                                      {validation.errors.name}
                                      </FormFeedback>
                                  ) : null}                                    

                                  </div>
                              </Col>                            
                          </Row>                                                                                                    

                          <Row>
                              <Col lg={12}>                             
                                  {/* <CitySiteSeeingImages
                                        cancelHandller={false}
                                        getImageFileNames={getImageFileNames}
                                        handleAcceptedFiles={handleAcceptedFiles}
                                        modalSaveHandller={modalSaveHandller}
                                        onDeleteHandller={onDeleteHandller}
                                        onImagePrimaryHandller={onImagePrimaryHandller}
                                        removeSelectedImages={removeSelectedImages}
                                        selectedFiles={selectedFiles}
                                      /> */}
                              </Col>                            
                          </Row>   

                            <Row>
                                                            <Col lg={6}>                                                                   

                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="is_active">Is Active                                                                   
                                                                    </Label>
                                                                    <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                                                                        <Input 
                                                                            type="checkbox" 
                                                                            className="form-check-input" 
                                                                            id="is_fixed_departure"
                                                                            name="is_fixed_departure"
                                                                            value={validation?.values?.is_active}  
                                                                            checked = {validation?.values?.is_active}  
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                        />                 
                                                                    </div>
                                                                </div>

                                                            </Col>                                                          
                                                        </Row>        


                  </Col>
                
              </Row>   

          </form>
        </ModalBody>
        {/* <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div> */}
      </Modal>
    </>
  );
};

export default SlidePopupModal;
