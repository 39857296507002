import { Image, Space, Table } from "antd";
import React, { useRef } from "react";
import FeatherIcon from "feather-icons-react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Modal,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import Select from "react-select";
import classnames from "classnames";
import makeAnimated from "react-select/animated";
import {
  destinationTypes,
  destinationTypesFilter,
} from "../../components/constants/destinationTypes";
import { useEffect } from "react";
import { GetDestinationList } from "../../services/GlobalMasters/destinationService";
import { GetCityList } from "../../services/GlobalMasters/cityServices";
import { GetTransportRateList } from "../../services/GlobalMasters/transportRateService";
import Export from "../../components/Common/GloablMaster/export/Export";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import {
  DeletePackageImageList,
  GetPackageList,
  SavePackageList,
  savePackageImageList,
} from "../../services/Package";
import { url } from "../../services/common/const";
import {
  downloadExcel,
  filterUniqueDestination,
  filterUniqueTransport,
} from "../../functions/downloadtoExcel";
import ColumnVisibleAndHide from "../../components/Common/GloablMaster/columnvisibleAndHide/ColumnVisibleAndHide";
import Mastersearch from "../../components/Common/GloablMaster/Mastersearch";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "../../assets/scss/pages/package.scss";
import DestinationAndPackage from "../../components/package/DestinationAndPackage";
import "../../assets/scss/antdTable/antdTable.scss";
import PackageTransportRate from "../../components/package/PackageTransportRate";
import PackageInclusions from "../../components/package/PackageInclusions";
import PackageImages from "../../components/package/PackageImages";
import PackageItinerary from "../../components/package/PackageItinerary";
import { appName } from "../../common/applicationName";
import { getSessionUsingSessionStorage } from "../../services/common/session";
import PackageSiteSeeing from "../../components/package/PackageSiteSeeing";
import PackageExlusions from "../../components/package/PackageExlusions";
import { getCitySiteSeeingList } from "../../services/GlobalMasters/citySiteSeeingService";
import PackageGuideLines from "../../components/package/PackageGuideLines";
import UseAuth from "../../components/UseAuth";
import { useNavigate } from "react-router-dom";
import { authError } from "../../services/common/const";
import PackageCity from "../../components/package/PackageCity";

import {  
  showDestinationType ,
  showDestination,

  destinationTypeList, 
  destinationList ,
  cityList ,

  defaultDestinationType ,
  defaultDestination ,
  defaultCity 

  ,defaultCityOption
} 
from "../../services/common/GlobalVariables";

const animatedComponents = makeAnimated();
const InternationalPackage = () => {
  UseAuth();
  document.title = `${appName}-International Package`;
  // document.title = "Customize Package | TravelNinjaz B2B";
  const [package_id, set_package_id] = useState(0);
  const searchInput = useRef(null);
  const [userName, setUserName] = useState();
  const [dataSourceSiteSeeing, setDataSourceSiteSeeing] = useState([]); /**This is for all */
  const [dataSourceSite, setDataSourceSite] = useState([]); /**This is for only matches destination id */

  const navigate = useNavigate();

  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const [showInvalid, setShowInvalid] = useState(false);
  const [, setImagePreviewFileName] = useState([]);
  const [packageName, setPackageName] = useState(undefined);
  const [filterColumnWiseTransport, setFilterColumnWiseTransport] = useState(
    []
  );
  const [selectedFiles, setselectedFiles] = useState([]);
  const [getImageFileNames, setGetImageFileNames] = useState([]);
  const [deleteImageList, setDeleteImageList] = useState([]);
  const [isPrimaryImage, setIsPrimaryImage] = useState({
    package_image_id: null,
    is_primary: false,
  });
  const [packageItinerary, setPackageItinerary] = useState([
    {
      id: 1,
      day: 1,
      packageItineraryTitle: undefined,
      packageItineraryDescription: undefined,
    },
  ]);
  const { package_image_id, is_primary } = isPrimaryImage;
  /**image select */
  function handleAcceptedFiles(files) {
    setIsNullChild({ ...isNullChild, imagelistNullable: true });
    files.map((file) => {
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        is_primary: false,
      });
    });
    setselectedFiles(files);
  }
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  /**This state for a check send cityandnights or inclusions send value null or not*/
  const [isNullChild, setIsNullChild] = useState({
    cityAndNightsNullable: false,
    inclusionsNullable: false,
    imagelistNullable: false,
    itineraryNullable: false,
  });
  const {
    cityAndNightsNullable,
    inclusionsNullable,
    imagelistNullable,
    itineraryNullable,
  } = isNullChild;
  /**This state for read Only and edit only*/
  const [readOnly, setReadOnly] = useState(false);
  /**selection state */
  const [modalValue, setModalValue] = useState({
    destinationType: {
      label: "International",
      value: 2,
    },
    destination: null,
    transport: null,
    package_name: "",
    nights: null,
    package_commision: null,
    is_active: true,
    fromDate: null,
    packageGuideLines: "",
    toDate: null,
  });
  const {
    destinationType,
    destination,
    transport,
    package_name,
    nights,
    package_commision,
    is_active,
    fromDate,
    toDate,
    packageGuideLines,
  } = modalValue;
  /**This is state for select options*/
  const [selectOptions, setSelectOptions] = useState({
    destinationOptions: [],
   // cityOptions: [],
    transportOptions: [],
  });
  let { destinationOptions
    //, cityOptions, 
    ,transportOptions } = selectOptions;
  const [cityOptions, setCityOptions] = useState([]);

  const [specialCheckBox, setSpecialCheckBox] = useState({
    isbestselling: false,
    ishoneymoonpackage: false,
    isfamilypackage: false,
  });
  const [imagePreviewFile, setImagePreviewFile] = useState(undefined);
  const [imageFile, setIamgeFile] = useState();
  const [exportedData, setExportedData] = useState([]);
  const [modal_scroll, setmodal_scroll] = useState(false);
  const [searchTextantd, setSearchTextantd] = useState("");
  const [packageDescription, setPackageDescription] = useState(undefined);
  const [searchedColumn, setSearchedColumn] = useState("");
  /*Original Api Data**/
  const [dataSource, setDataSource] = useState({
    destinationList: [],
    //cityList: [],
    transportList: [],
    packageList: [],
  });
  let { destinationList, 
    //cityList,
     transportList, packageList } = dataSource;
  /**This is for hidden/show column*/
  const [switchCheck, setSwitchCheck] = useState({
    createdBy: true,
    createdDate: true,
    modifiedBy: true,
    modifiedDate: true,
  });
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  /**For filter column wise*/
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  /**City And Night Add*/
  const [cityAndNight, setCityAndNight] = useState([
    {
      id: 1,
      city: null,
      nights: undefined,
      city_id: null,
    },
  ]);
  /**This is for remove selected image */
  const removeSelectedImages = (item) => {
    let newArray = selectedFiles.filter((itemImage) => {
      return itemImage.name !== item.name;
    });
    setselectedFiles(newArray);
  };

  /**This is for delete saved database images */
  const onDeleteHandller = (item) => {
    setIsNullChild({ ...isNullChild, imagelistNullable: true });
    const removeSavedImages = getImageFileNames.filter((itemget, index) => {
      return itemget.package_image_id !== item.package_image_id;
    });
    setGetImageFileNames(removeSavedImages);
    setDeleteImageList([...deleteImageList, item]);
  };
  /**Inclusions*/
  const [inclusions, setInclusions] = useState([
    {
      id: `${1}-inclusions`,
      inclusions: null,
      is_include: true,
    },
  ]);
  /**Excusions*/
  const [exclusions, setExclusions] = useState([
    {
      id: `${1}-exclusions`,
      inclusions: null,
      is_include: false,
    },
  ]);
  // Tooltip Open state
  const [exportTableData, setExportableData] = useState([]);
  /**pacakge description handller */
  const onChangePackageHandller = (e) => {
    debugger;
    setPackageDescription(e.target.value);
  };

  /**This is for destination options*/
  if (destinationList?.length > 0) {
    destinationOptions = [];
    const filterDestinationList = destinationList.filter((item) => {
      return item.destination_type_id === 2 && item.is_active;
    });
    filterDestinationList.map((item, index) => {
      destinationOptions.push({
        label: item.destination_name,
        value: item.destination_id,
      });
    });
  }
  /**This is option for city */
  // if (cityList?.length > 0 && destination?.value) {
  //   cityOptions = [];
  //   cityList
  //     // .filter((item) => {
  //     //   return item.destination_id === destination?.value && item.is_active;
  //     // })
  //     .map((item, index) => {
  //       cityOptions.push({
  //         label: item.city_name,
  //         value: item.city_id,
  //       });
  //     });
  // }
  /**Over All table search in master */
  const searchInputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = packageList.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(packageList);
      setExportableData(JSON.parse(JSON.stringify(packageList)));
    }
  };

  /**This is for form wizard tabs*/
  const [activeArrowTab, setactiveArrowTab] = useState(4);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  function toggleArrowTab(tab) {
    if (activeArrowTab !== tab) {
      var modifiedSteps = [...passedarrowSteps, tab];
      if (tab >= 1 && tab <= 9) {
        setactiveArrowTab(tab);
        setPassedarrowSteps(modifiedSteps);
      }
    }
  }

  /**Edit Handller*/
  const editHandller = (record) => {
    debugger;
    setactiveArrowTab(1);
    setPassedarrowSteps([1]);
    setImagePreviewFileName(record.package_image);
    setPackageName(record.package_name);
    setPackageDescription(record.package_description);
    setselectedFiles([]);
    setDeleteImageList([]);
    setModalValue({
      ...modalValue,
      // destinationType: {
      //   label: record.destination_type_id === 1 ? "Domestic" : "International",
      //   value: record.destination_type_id,
      // },
      // destination: {
      //   label: record.destination_name,
      //   value: record.destination_id,
      // },
      destinationType:  defaultDestinationType,     
      destination: defaultDestination,
      transport: {
        label: record.transport_rate_name,
        value: record.transport_rate_id,
      },
      package_commision: record.package_commision,
      package_name: record.package_name,
      is_active: record.is_active,
     // nights: record?.packageCityList[0]?.packageCityHotelList[0]?.nights,
      packageGuideLines: record?.packageGuideLines,
    });
    setSpecialCheckBox({
      ...specialCheckBox,
      isbestselling: record.is_best_selling,
      isfamilypackage: record.is_family_package,
      ishoneymoonpackage: record.is_honeymoon_package,
    });
    set_package_id(record.package_id);
    setImagePreviewFileName(record.package_image);
    let cityAndNightsArray = [];
    record?.packageCityList?.map((item, index) => {
      cityAndNightsArray.push({
        city: {
          label: item.city_name,
          value: item.city_id,
        },
        nights: item?.packageCityHotelList[0]?.nights,
        id: index + 1,
        Package_city_id: item.package_city_id,
      });
    });
    setCityAndNight(cityAndNightsArray);
    let inclusionsListArray = [];
    let exclusionsListArray = [];
    let countInclusions = 0;
    let countExclusions = 0;
    record.inclusionsList?.map((item, index) => {
      if (item.is_include) {
        countInclusions = countInclusions + 1;
        inclusionsListArray.push({
          id: `${countInclusions}-inclusions`,
          inclusions: item.inclusions,
          Inclusions_id: item.inclusions_id,
          is_include: item.is_include,
        });
      } else {
        countExclusions = countExclusions + 1;
        exclusionsListArray.push({
          id: `${countExclusions}-exclusions`,
          inclusions: item.inclusions,
          Inclusions_id: item.inclusions_id,
          is_include: item.is_include,
        });
      }
    });
    setInclusions(inclusionsListArray);
    setExclusions(exclusionsListArray);
    /**package itinerary list */
    let packageItineraryArray = [];
    record?.package_itinerary_list?.map((item, index) => {
      packageItineraryArray.push({
        id: index + 1,
        Package_itinerary_id: item?.package_itinerary_id,
        day: item?.day,
        packageItineraryTitle: item?.itinerary_title,
        packageItineraryDescription: item?.itinerary_description,
      });
    });
    setPackageItinerary(packageItineraryArray);
    setGetImageFileNames(record.imageList);
    setReadOnly(true);
    tog_scroll();
    // console.log(record.package_id);
  };

  /**download to excel */
  const getAllList = async () => {
    try {
      if (userName) {
        let params = new URLSearchParams();
        params.append("destination_type_id", 2);
        params.append("UserId", userName);
        /**destination get api call */
        const destinationResult = await GetDestinationList();
        if (destinationResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            return {
              ...prevDataSource,
              destinationList: destinationResult.data.data,
            };
          });
        } else {
          throw destinationResult.message;
          // return;
        }

        /**city get api call */
        // const cityResult = await GetCityList();
        // if (cityResult?.data?.status === "SUCCESS") {
        //   setDataSource((prevDataSource) => {
        //     return {
        //       ...prevDataSource,
        //       cityList: cityResult.data.data,
        //     };
        //   });
        // } else {
        //   throw cityResult.message;
        //   // return;
        // }

        debugger;
         
        if (cityList.length > 0) {
          let tempCityOption = [];
          cityList
            // .filter((item) => {
            //   return (
            //     item.destination_id === selectedDestinationId && item.is_active
            //   );
            // })
            .map((item, index) => {
              tempCityOption.push({
                label: item.city_name,
                value: item.city_id,
              });
            });
            setCityOptions(tempCityOption);
        }
        
        /***Transport get api call*/
        const transportResult = await GetTransportRateList();
        if (transportResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            return {
              ...prevDataSource,
              transportList: transportResult.data.data,
            };
          });
        } else {
          throw transportResult.message;
          // return;
        }

        /***Package get api call*/
        const packageResult = await GetPackageList(params);
        if (packageResult?.data?.status === "SUCCESS") {
          setDataSource((prevDataSource) => {
            return {
              ...prevDataSource,
              packageList: packageResult.data.data?.filter((item, index) => {
                return item.destination_type_id === 2;
              }),
            };
          });
          /**This is for destination filter  */
          let uniqueDestination = filterUniqueDestination(
            packageResult.data.data
          );
          let filterDestination = [];
          uniqueDestination.map((item, index) => {
            filterDestination.push({
              text: item?.destination_name,
              value: item?.destination_id,
            });
          });
          setFilterColumnWiseDestination(filterDestination);
          /**This is for transport filter  */
          let uniqueTransport = filterUniqueTransport(packageResult.data.data);
          let filterTransport = [];
          uniqueTransport.map((item, index) => {
            filterTransport.push({
              text: item?.transport_rate_name,
              value: item?.transport_rate_id,
            });
          });
          setFilterColumnWiseTransport(filterTransport);
          // packageResult.data.data.map((item, index) => {
          //   filterColumnWiseDestination.push({
          //     text: item?.destination_name,
          //     value: item?.destination_id,
          //   });
          //   filterColumnWiseTransport.push({
          //     text: item?.transport_rate_name,
          //     value: item?.transport_rate_id,
          //   });
          // });
        } else {
          throw packageResult.message;
          // return;
        }
        const siteSeeingResult = await getCitySiteSeeingList();
        if (siteSeeingResult?.data?.status === "SUCCESS") {
          setDataSourceSiteSeeing(siteSeeingResult.data.data);
        } else {
          throw siteSeeingResult.message;
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This useEffect for calling get api*/
  useEffect(() => {
    getAllList();
  }, [userName]);
  useEffect(() => {
    debugger;
    if (destination) {
      let filterSiteSeeing = dataSourceSiteSeeing?.filter((item, index) => {
        return item.destination_id === destination.value;
      });
      setDataSourceSite(filterSiteSeeing[0]?.siteSeeingList);
    }
  }, [destination]);

  /**This is image on change handller*/
  // const onChangeImageHandller = (e) => {
  //   let images = [];
  //   for (let i = 0; i < e.target.files.length; i++) {
  //     images.push(URL.createObjectURL(e.target.files[i]));
  //   }
  //   setSelectedFiles(e.target.files);
  //   setImagePreviews(images);
  //   // setImagePreviewFileName(e.target.files[0].name);
  //   // setImagePreviewFile(URL.createObjectURL(e.target.files[0]));
  //   // setIamgeFile(e.target.files[0]);
  // };
  // const handleDragEnd = (e) => {
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   if (!e.destination) return;
  //   let tempData = Array.from(cityAndNight);
  //   let [source_data] = tempData.splice(e.source.index, 1);
  //   tempData.splice(e.destination.index, 0, source_data);
  //   setCityAndNight(tempData);
  // };
  /**This is for cancel handller */
  const cancelHandller = (e) => {
    e.preventDefault();
    setmodal_scroll(false);
    setIamgeFile(undefined);
    setImagePreviewFile(undefined);
    setImagePreviewFileName(undefined);
    setIsNullChild({
      ...isNullChild,
      cityAndNightsNullable: false,
      inclusionsNullable: false,
      imagelistNullable: false,
      itineraryNullable: false,
    });
    setReadOnly(false);
  };

  /**This is option for transport*/
  if (transportList?.length > 0 ) {  //&& defaultDestination?.value
    transportOptions = [];
    transportList
      // .filter((item) => {
      //   return item.is_active && item.destination_id === defaultDestination?.value;
      // })
      .map((item, index) => {
        transportOptions.push({
          label: item.transport_rate_name,
          value: item.transport_rate_id,
        });
      });
  }

  /**This function for delete city*/
  // const removeCityHandler = (id) => {
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   if (cityAndNight?.length > 1) {
  //     const filterCity = cityAndNight.filter((item) => {
  //       return item.id !== id;
  //     });
  //     setCityAndNight(filterCity);
  //   } else {
  //     errornotify("Please add atleast one city.");
  //   }
  // };

  /**This is function for remove itinerary */
  const removeItineraryHandller = (id) => {
    setIsNullChild({ ...isNullChild, itineraryNullable: true });

    const filterItinerary = packageItinerary?.filter((item) => {
      return item.id !== id;
    });
    setPackageItinerary(filterItinerary);
  };
  /**This functions for remove inclusions */
  const removeInclustionsHandller = (id) => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    const filterInclusion = inclusions.filter((item) => {
      return item.id !== id;
    });
    setInclusions(filterInclusion);
  };
  /**This functions for remove exclusions */
  const removeExclusionsHandller = (id) => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    const filterInclusion = exclusions.filter((item) => {
      return item.id !== id;
    });
    setExclusions(filterInclusion);
  };
  /**onchange editor handller */
  const onChangeEditorHandller = (event, editor) => {
    const data = editor.getData();
    setModalValue({ ...modalValue, packageGuideLines: data });
  };
  /**input change handller*/
  const onChangeHandller = (e) => {

    debugger;
    if (e.target.name === "is_active") {
      setModalValue({ ...modalValue, [e.target.name]: e.target.checked });
    } else if (
      e.target.name === "createdBy" ||
      e.target.name === "createdDate" ||
      e.target.name === "modifiedBy" ||
      e.target.name === "modifiedDate"
    ) {
      setSwitchCheck({ ...switchCheck, [e.target.name]: !e.target.checked });
    } else if (
      e.target.name === "isbestselling" ||
      e.target.name === "ishoneymoonpackage" ||
      e.target.name === "isfamilypackage"
    ) {
      setSpecialCheckBox({
        ...specialCheckBox,
        [e.target.name]: e.target.checked,
      });
    } else {
      if (e.target.name === "nights") {
        setModalValue({ ...modalValue, [e.target.name]: e.target.value });
        setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });

        /* This was creating array with same cities multiple time
         city 1 - Dubai - 3 nights
         city 2 - Dubai - 4 nights (actually City 2 is Abu dhabi not Dubai)
         To overcome the issue below code is commented
        */
        // const newArray = cityAndNight.map((item, i) => {
        //   if (parseInt(e.target.id) === parseInt(item.id)) {
        //     return {
        //       ...item,
        //       [e.target.name]: e.target.value,
        //       city: cityOptions[0]?.label,
        //       city_id: cityOptions[0]?.value,
        //     };
        //   } else {
        //     return item;
        //   }
        // });
        // setCityAndNight(newArray);

        setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
        const newArray = cityAndNight.map((item, i) => {
          if (parseInt(e.target.id) === parseInt(item.id)) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
        setCityAndNight(newArray);

      } else if (e.target.name === "inclusions") {
        setIsNullChild({ ...isNullChild, inclusionsNullable: true });
        const newArrayInclusions = inclusions?.map((item, i) => {
          if (e.target.id === item.id) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
        debugger;
        const newArrayExclusions = exclusions?.map((item, i) => {
          if (e.target.id === item.id) {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          } else {
            return item;
          }
        });
        setInclusions(newArrayInclusions);
        setExclusions(newArrayExclusions);
      } else if (
        e.target.name === "packageItineraryDescription" ||
        e.target.name === "packageItineraryTitle" ||
        e.target.name === "day"
      ) {
        setIsNullChild({ ...isNullChild, itineraryNullable: true });
        const updatedArray = packageItinerary.map((item, index) => {
          if (parseInt(e.target.id) === item.id) {
            return { ...item, [e.target.name]: e.target.value };
          } else {
            return item;
          }
        });
        setPackageItinerary(updatedArray);
      } else {
        setModalValue({ ...modalValue, [e.target.name]: e.target.value });
      }
    }
  };

  /**This function for select option handller*/
  const selectOptionHandller = (chooseOption, name, id) => {
    debugger;
    if (name === "city") {
      setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
      const newArray = cityAndNight.map((item, i) => {
        if (item.id === id) {
          return {
            ...item,
            [name]: chooseOption,
          };
        } else {
          return item;
        }
      });
      setCityAndNight(newArray);
    } else {
      if (name === "destinationType") {
        setModalValue((prevValue) => {
          return {
            ...prevValue,
            destination: null,
            city: null,
            transport: null,
          };
        });
        setCityAndNight([
          {
            id: 1,
            city: null,
            nights: undefined,
          },
        ]);
      } else if (name === "destination") {
        setModalValue((prevValue) => {
          return {
            ...prevValue,
            city: null,
            transport: null,
          };
        });
        setCityAndNight([
          {
            id: 1,
            city: null,
            nights: undefined,
          },
        ]);
      }
      setModalValue((prevValue) => {
        return {
          ...prevValue,
          [name]: chooseOption,
        };
      });
    }
  };
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(packageList)));
  }, [
    packageList,
    switchCheck.createdBy,
    switchCheck.createdDate,
    switchCheck.modifiedBy,
    switchCheck.modifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(packageList)));
    }
  }, [searchText]);
  /**This is for children data*/
  const expandedRowRender = (row) => {
    const transportcolumnDataSource = transportList.filter((item) => {
      return item.transport_rate_id === row.transport_rate_id;
    });
    const siteinclusionsSource = row.inclusionsList.filter((item, index) => {
      return item.is_include;
    });
    const siteExclusionsSource = row.inclusionsList.filter((item, index) => {
      return !item.is_include;
    });
    const columns = [
      {
        title: "City",
        dataIndex: "city_name",
      },
      {
        title: "Nights",
        dataIndex: "nights",
        render: (text, record) => {
          return <span>{record.nights} Nights</span>;
        },
      },
    ];
    const inclusionsColumns = [
      {
        title: "Inclusions",
        dataIndex: "inclusions",
      },
    ];
    const exclusionListColumns = [
      {
        title: "Exclusions",
        dataIndex: "inclusions",
      },
    ];
    const transportAndPriceColumns = [
      {
        title: "Transport",
        dataIndex: "vehicle_name",
      },
      {
        title: "Price",
        dataIndex: "vehicle_price",
      },
    ];
    return (
      <>
        <Table
          columns={columns}
          dataSource={row.packageCityList}
          pagination={false}
        />
        <Table
          columns={inclusionsColumns}
          dataSource={siteinclusionsSource}
          pagination={false}
        />
        <Table
          columns={exclusionListColumns}
          dataSource={siteExclusionsSource}
          pagination={false}
        />
        <Table
          columns={transportAndPriceColumns}
          dataSource={transportcolumnDataSource[0].transportRateList}
          pagination={false}
        />
      </>
    );
  };

  // /**This function for handle add city*/
  // const handleAddCity = () => {
  //   setReadOnly(false);
  //   setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
  //   setCityAndNight([
  //     ...cityAndNight,
  //     { id: cityAndNight?.length + 1, city: null, nights: undefined },
  //   ]);
  // };

  /**This function for handle add itinerary  */
  const handleAddItinerary = () => {
    setIsNullChild({ ...isNullChild, itineraryNullable: true });
    setPackageItinerary([
      ...packageItinerary,
      {
        id: packageItinerary?.length + 1,
        day: packageItinerary?.length + 1,
        packageItineraryTitle: undefined,
        packageItineraryDescription: undefined,
      },
    ]);
  };
  /**This function for handle add inclusions*/
  const handleAddInclusions = () => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    setInclusions([
      ...inclusions,
      {
        id: `${inclusions?.length + 1}-inclusions`,
        inclusions: null,
        is_include: true,
      },
    ]);
  };

  /**This function for handle add exclusions */
  const handleAddExclusions = () => {
    setIsNullChild({ ...isNullChild, inclusionsNullable: true });
    setExclusions([
      ...exclusions,
      {
        id: `${exclusions?.length + 1}-exclusions`,
        inclusions: null,
        is_include: false,
      },
    ]);
  };
  /**primary image radio box handller */
  const onImagePrimaryHandller = (e, item) => {
    setIsPrimaryImage({
      ...isPrimaryImage,
      is_primary: e.target.checked,
      package_image_id: item.package_image_id,
    });
  };

  /**Package Save*/
  const modalSaveHandller = async (event) => {
    //Preventing page refresh
    event.preventDefault();
    try {
      setShowInvalid(true);
      if (package_name?.length < 0) {
        throw "Package Name is required.";
      }
      if (!transport?.value) {
        throw "Please select Transport";
      }
      if (!package_commision) {
        throw "Package Commision is Required";
      }
      if (!packageDescription) {
        throw "Description is Required";
      }
      // if (!imagePreviewFileName || imagePreviewFileName === "") {
      //   throw "image is required";
      // }

      debugger;

      let savedCityAndNight = [];
      if (cityAndNight?.length > 0 && inclusions?.length > 0) {
        cityAndNight.map((item, index) => {
          debugger;
          if (!item.city) {
            throw "City is required.";
          }
          if (!item.nights) {
            throw "Nights is required.";
          }
          /**This is list for save cityandnights */
          savedCityAndNight.push({
            Package_city_id: item.Package_city_id | 0,
            Package_id: package_id,
            // Nights: parseInt(item.nights),
            PackageCityHotelList: [{ Nights: parseInt(item.nights) }],
            // City_id: item?.city_id,
            City_id: item?.city?.value,
            Order_no: index + 1,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        /**inclusions list */
        let inclusionsList = [];
        inclusions.map((item, index) => {
          if (!item.inclusions) {
            throw "Inclusions is required";
          }
          inclusionsList.push({
            Inclusions_id: item.Inclusions_id | 0,
            Inclusions: item.inclusions,
            Is_include: item.is_include,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        exclusions.map((item, index) => {
          if (!item.inclusions) {
            throw "Exclusions is required";
          }
          inclusionsList.push({
            Inclusions_id: item.Inclusions_id | 0,
            Inclusions: item.inclusions,
            Is_include: item.is_include,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        /**itinerary list**/
        let itineraryList = [];
        packageItinerary.map((item, index) => {
          if (!item.day) {
            throw "day is required";
          }
          if (!item.packageItineraryTitle) {
            throw "Package itinerary title is required !";
          }
          if (!item.packageItineraryDescription) {
            throw "Package itinerary Description is required !";
          }
          itineraryList.push({
            Package_itinerary_id: item.Package_itinerary_id | 0,
            Day: parseInt(item.day),
            Itinerary_title: item.packageItineraryTitle,
            Itinerary_description: item.packageItineraryDescription,
            Is_active: is_active,
            Row_created_by: userName,
            Row_created_date: new Date(),
            Row_altered_by: userName,
            Row_altered_date: new Date(),
          });
        });
        /**Image list */
        let imageList = [];
        /**new images add in list */
        if (selectedFiles?.length > 0 || getImageFileNames?.length > 0) {
          selectedFiles.map((item, index) => {
            imageList.push({
              Package_image_id: 0,
              Image_name: item.name,
              Is_active: true,
              Is_primary:
                getImageFileNames?.length > 0 || index > 0 ? false : true,
              Row_created_date: new Date(),
              Row_created_by: userName,
              Row_altered_date: new Date(),
              Row_altered_by: userName,
            });
          });
          /**already exists images add in list */
          getImageFileNames?.map((itemAlreadyImage, index) => {
            imageList.push({
              Package_image_id: itemAlreadyImage.package_image_id,
              Image_name: itemAlreadyImage.image_name,
              Is_active: true,
              Is_primary: itemAlreadyImage?.is_primary
                ? true
                : package_image_id === itemAlreadyImage.package_image_id
                ? is_primary
                : false,
              Row_created_date: new Date(),
              Row_created_by: userName,
              Row_altered_date: new Date(),
              Row_altered_by: userName,
            });
          });
        } else {
          throw "Images are required";
        }
        const packageList = {
          Package_id: package_id,
          Transport_rate_id: transport?.value,
          Destination_id: destination?.destination_id,
          Package_name: package_name,
          Package_description: packageDescription,
          Package_commision: parseInt(package_commision),
          PackageGuideLines: packageGuideLines,
          PackageCityList:
            readOnly && !cityAndNightsNullable ? null : savedCityAndNight,
          InclusionsList:
            readOnly && !inclusionsNullable ? null : inclusionsList,
          ImageList:
            readOnly &&
            !imagelistNullable &&
            getImageFileNames?.length > 0 &&
            selectedFiles?.length > 0
              ? null
              : imageList,
          Package_itinerary_list:
            readOnly && !itineraryNullable ? null : itineraryList,
          Is_best_selling: specialCheckBox.isbestselling,
          Is_honeymoon_package: specialCheckBox.ishoneymoonpackage,
          Is_family_package: specialCheckBox.isfamilypackage,
          Is_active: is_active,
          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
        };

        const response = await SavePackageList(packageList);

        if (response?.status === "SUCCESS") {
          try {
            if (deleteImageList.length > 0) {
              /**This is for delete images */
              const response = await DeletePackageImageList(deleteImageList);
              if (response.status === "ERROR") {
                throw response.message;
                // return;
              }
            }
            if (selectedFiles.length > 0) {
              /**This is for save images*/
              const formData = new FormData();
              formData.append("Package_id", response.data[1]);
              selectedFiles.map(async (item, index) => {
                formData.append("file", item);
                let response = await savePackageImageList(formData);
                if (response.status === "ERROR") {
                  throw response.message;
                  // return;
                }
              });
            }
          } catch (error) {
            errornotify(error);
            // return;
          }
          successNotify("Package saved successfully!");
          await getAllList();
          tog_scroll();
          setShowInvalid(false);
          setCityAndNight({
            id: 1,
            city: null,
            nights: undefined,
          });
          setInclusions([
            {
              id: `${1}-inclusions`,
              inclusions: null,
              is_include: true,
            },
          ]);
          setExclusions([
            {
              id: `${1}-exclusions`,
              inclusions: null,
              is_include: false,
            },
          ]);
          setModalValue({
            packageGuideLines: "",
            destination: null,
            destinationType: null,
            is_active: is_active,
            package_commision: null,
            package_name: undefined,
            transport: null,
          });
          setIsNullChild({
            ...isNullChild,
            cityAndNightsNullable: false,
            inclusionsNullable: false,
            imagelistNullable: false,
            itineraryNullable: false,
          });
          setReadOnly(false);
        } else {
          throw response?.message;
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };

  /**For column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  /**Table and column*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },
    // {
    //   title: "Destination Type",
    //   dataIndex: "destination_type_id",
    //   filters: destinationTypesFilter,
    //   // filterMode: "tree",
    //   onFilter: (value, record) => record.destination_type_id === value,
    //   render: (text, record) => {
    //     return record.destination_type_id === 1 ? "Domestic" : "International";
    //   },
    //   sorter: {
    //     compare: (a, b) => a.destination_type_id - b.destination_type_id,
    //     multiple: 13,
    //   },
    //   defaultSortOrder: "ascend",
    //   // sorter: (a, b) => a.destination_type_id - b.destination_type_id,
    // },
    {
      title: "Destination",
      dataIndex: "destination_name",
      width: 140,
      // ...getColumnSearchProps("destination_name"),
      filters: filterColumnWiseDestination,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination_id === value,
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 12,
      },
      defaultSortOrder: "ascend",
      hidden: !showDestination,
      // sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
    },
    {
      title: "Package",
      dataIndex: "package_name",
      width: 200,
      sorter: {
        compare: (a, b) => a.package_name.localeCompare(b.package_name),
        multiple: 11,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.package_name.localeCompare(b.package_name),
      ...getColumnSearchProps("package_name"),
    },
    {
      title: "Package Description",
      width: 200,
      dataIndex: "package_description",
      render: (text, record) => {
        return (
          <>
            <UncontrolledTooltip
              placement="top"
              target={`tooltipId${record.package_id}`}
            >
              {record.package_description}
            </UncontrolledTooltip>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
              id={`tooltipId${record.package_id}`}
            >
              {record.package_description}
            </p>
          </>
        );
      },
    },
    {
      title: "Transport",
      width: 250,
      dataIndex: "transport_rate_name",
      filters: filterColumnWiseTransport,
      sorter: {
        compare: (a, b) =>
          a.transport_rate_name.localeCompare(b.transport_rate_name),
        multiple: 10,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) =>
      //   a.transport_rate_name.localeCompare(b.transport_rate_name),
      filterSearch: true,
      onFilter: (value, record) => record.transport_rate_id === value,
    },
    {
      title: "Package Commision",
      width: 200,
      dataIndex: "package_commision",
      sorter: {
        compare: (a, b) => a.package_commision - b.package_commision,
        multiple: 9,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.package_commision - b.package_commision,
      render: (text, record) => {
        return <div>{record.package_commision}%</div>;
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => (a, b) => a.is_active - b.is_active,
        multiple: 8,
      },
      // sorter: (a, b) => a.is_active - b.is_active,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Is Best Selling",
      dataIndex: "is_best_selling",
      width: 170,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_best_selling === value,
      sorter: {
        compare: (a, b) => a.is_best_selling - b.is_best_selling,
        multiple: 7,
      },
      // sorter: (a, b) => a.is_best_selling - b.is_best_selling,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_best_selling = e.target.checked)}
              defaultChecked={record.is_best_selling}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Is Family Package",
      width: 170,
      dataIndex: "is_family_package",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_family_package === value,
      sorter: {
        compare: (a, b) => a.is_family_package - b.is_family_package,
        multiple: 6,
      },
      // sorter: (a, b) => a.is_family_package - b.is_family_package,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_family_package = e.target.checked)}
              defaultChecked={record.is_family_package}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Is Honeymoon Package",
      width: 170,
      dataIndex: "is_honeymoon_package",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_honeymoon_package === value,
      sorter: {
        compare: (a, b) => a.is_honeymoon_package - b.is_honeymoon_package,
        multiple: 5,
      },
      // sorter: (a, b) => a.is_honeymoon_package - b.is_honeymoon_package,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_honeymoon_package = e.target.checked)}
              defaultChecked={record.is_honeymoon_package}
              disabled
            />
          </div>
        );
      },
    },

    {
      title: "Created By",
      width: 250,
      dataIndex: "row_created_by",
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: switchCheck.createdBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: switchCheck.createdDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: switchCheck.modifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: switchCheck.modifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);

  /**This function for modal open and close*/
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }
  /**This is for add button handller*/
  const AddHandler = () => {

    debugger;

    setselectedFiles([]);
    setGetImageFileNames([]);
    setDeleteImageList([]);
    setactiveArrowTab(1);
    setPassedarrowSteps([1]);
    set_package_id(0);
    setPackageName(undefined);
    setPackageDescription(undefined);
    setSpecialCheckBox({
      isbestselling: false,
      ishoneymoonpackage: false,
      isfamilypackage: false,
    });
    setModalValue({
      // destinationType: null,
      // destination: null,
      destinationType:  defaultDestinationType,
     
      destination: defaultDestination,

      transport: null,
      package_name: "",
      package_commision: null,
      is_active: true,
    });
    setCityAndNight([
      {
        id: 1,
        city: null,
        nights: undefined,
      },
    ]);
    setPackageItinerary([
      {
        id: 1,
        day: 1,
        packageItineraryTitle: undefined,
        packageItineraryDescription: undefined,
      },
    ]);
    setInclusions([
      {
        id: `${1}-inclusions`,
        inclusions: null,
        is_include: true,
      },
    ]);
    setExclusions([
      {
        id: `${1}-exclusions`,
        inclusions: null,
        is_include: false,
      },
    ]);
    setReadOnly(false);

    // if (cityList?.length > 0 ) {  //&& destination?.value
    //   cityOptions = [];
    //   cityList
    //     // .filter((item) => {
    //     //   return item.destination_id === destination?.value && item.is_active;
    //     // })
    //     .map((item, index) => {
    //       cityOptions.push({
    //         label: item.city_name,
    //         value: item.city_id,
    //       });
    //     });
    // }

    
    tog_scroll();
  };
  /**set exported Data*/
  let exportArray = [];
  /** Export data*/
  useEffect(() => {
    setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        exportArray.push({
          Destination_type:
            elementParent.destination_type_id === 1
              ? "Domestic"
              : "International",
          Destination_name: elementParent.destination_name,
          Transport_rate_name: elementParent.transport_rate_name,
          Package_name: elementParent.package_name,
          Package_commision: elementParent.package_commision,
          Is_active: elementParent.is_active,
          Is_best_selling: elementParent.is_best_selling,
          Is_family_package: elementParent.is_family_package,
          Is_honeymoon_package: elementParent.is_honeymoon_package,
          ...(!switchCheck.createdBy && {
            Row_createdBy: elementParent.row_created_by,
          }),
          ...(!switchCheck.createdDate && {
            Row_createdDate: elementParent.row_created_date,
          }),
          ...(!switchCheck.modifiedBy && {
            Row_modifiedBy: elementParent.row_altered_by,
          }),
          ...(!switchCheck.modifiedDate && {
            Row_modifiedDate: elementParent.row_altered_date,
          }),
        });
        elementParent?.packageCityList?.forEach((element) =>
          exportArray.push({
            Destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination_name: elementParent.destination_name,
            Transport_rate_name: elementParent.transport_rate_name,
            Package_name: elementParent.package_name,
            Package_commision: elementParent.package_commision,
            Is_active: elementParent.is_active,
            ...(!switchCheck.createdBy && {
              Row_createdBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              Row_createdDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              Row_modifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              Row_modifiedDate: elementParent.row_altered_date,
            }),
            City: element.city_name,
            Nights: element.nights,
          })
        );
        elementParent?.inclusionsList?.forEach((elementInclusions) => {
          exportArray.push({
            Destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            Destination_name: elementParent.destination_name,
            Transport_rate_name: elementParent.transport_rate_name,
            Package_name: elementParent.package_name,
            Package_commision: elementParent.package_commision,
            Package_description: elementParent.package_description,
            Is_active: elementParent.is_active,
            ...(!switchCheck.createdBy && {
              Row_createdBy: elementParent.row_created_by,
            }),
            ...(!switchCheck.createdDate && {
              Row_createdDate: elementParent.row_created_date,
            }),
            ...(!switchCheck.modifiedBy && {
              Row_modifiedBy: elementParent.row_altered_by,
            }),
            ...(!switchCheck.modifiedDate && {
              Row_modifiedDate: elementParent.row_altered_date,
            }),
            Inclusions: elementInclusions.inclusions,
          });
        });
        transportList
          .filter((itemTransportList) => {
            return (
              itemTransportList.transport_rate_id ===
              elementParent.transport_rate_id
            );
          })[0]
          .transportRateList.forEach((vehicleItem) => {
            exportArray.push({
              Destination_type:
                elementParent.destination_type_id === 1
                  ? "Domestic"
                  : "International",
              Destination_name: elementParent.destination_name,
              Transport_rate_name: elementParent.transport_rate_name,
              Package_name: elementParent.package_name,
              Package_commision: elementParent.package_commision,
              Is_active: elementParent.is_active,
              ...(!switchCheck.createdBy && {
                Row_createdBy: elementParent.row_created_by,
              }),
              ...(!switchCheck.createdDate && {
                Row_createdDate: elementParent.row_created_date,
              }),
              ...(!switchCheck.modifiedBy && {
                Row_modifiedBy: elementParent.row_altered_by,
              }),
              ...(!switchCheck.modifiedDate && {
                Row_modifiedDate: elementParent.row_altered_date,
              }),
              Transport: vehicleItem.vehicle_name,
              Price: vehicleItem.vehicle_price,
            });
          });
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);
  //console.log(packageItinerary);

  
  const navigateImportPackage = () => {
    navigate('../importPackage', {replace: true});
  };

  
  /**This function for handle add city*/
  const handleAddCity = () => {
    setReadOnly(false);
    setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
    setCityAndNight([
      ...cityAndNight,
      { id: cityAndNight?.length + 1, city: null, nights: undefined },
    ]);
  };

    /**This function for delete city*/
    const removeCityHandler = (id) => {
      setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
      if (cityAndNight?.length > 1) {
        const filterCity = cityAndNight.filter((item) => {
          return item.id !== id;
        });
        setCityAndNight(filterCity);
      } else {
        errornotify("Please add atleast one city.");
      }
    };

  const handleDragEnd = (e) => {
    setIsNullChild({ ...isNullChild, cityAndNightsNullable: true });
    if (!e.destination) return;
    let tempData = Array.from(cityAndNight);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setCityAndNight(tempData);
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          {/* <BreadCrumb title="Package" pageTitle="Package" /> */}
          <BreadCrumb
            title="International Packages"
            isSubTitle={false}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          {/**Export data */}
                          <Export
                            downloadExcel={downloadExcel}
                            exportedData={exportedData}
                            name="PackageList"
                          />

                        <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              navigateImportPackage()
                            }                            
                            buttonText="Import"                            
                          />



                          {/**Hide and show column*/}
                          <ColumnVisibleAndHide
                            changeHandller={onChangeHandller}
                            switchCheck={switchCheck}
                          />
                        </div>
                      </Col>
                      {/**This is for search input field */}
                      <Mastersearch inputHandller={searchInputHandller} />
                    </Row>
                  </div>
                  <div>
                    {searchText?.length > 0 ? (
                      <Table
                        dataSource={filterData}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        expandedRowRender={expandedRowRender}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    ) : (
                      <Table
                        dataSource={packageList}
                        expandedRowRender={expandedRowRender}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/**This modal for form steps*/}
      <Modal
        isOpen={modal_scroll}
        toggle={() => {
          tog_scroll();
        }}
        centered
        size="xl"
        // fullscreen
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_scroll();
          }}
        >
          {!readOnly ? "Add" : "Edit"} Customize International Package
          {!packageName ? "" : `-${packageName}`}
        </ModalHeader>
        <Col xl={12}>
          <Card style={{ minHeight: "75vh" }}>
            <CardBody className="form-steps">
              <Form>
                <div className="step-arrow-nav mb-4">
                  <Nav
                    className="nav-pills custom-nav nav-justified"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 1,
                          //done: activeArrowTab <= 1,
                          done: 1 ,
                        })}
                        onClick={() => {
                          toggleArrowTab(1);
                        }}
                      >
                        Package
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 2,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 2 ,
                          done: 2 ,
                        })}
                        onClick={() => {
                          toggleArrowTab(2);
                        }}
                      >
                        City
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 3,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 3 ,
                          done: 3,
                        })}
                        onClick={() => {
                          toggleArrowTab(3);
                        }}
                      >
                        Site Seeings
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 4,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 4 ,
                          done: 4,
                        })}
                        onClick={() => {
                          toggleArrowTab(4);
                        }}
                      >
                        Transport
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 5,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 5 ,
                          done: 5,
                        })}
                        onClick={() => {
                          toggleArrowTab(5);
                        }}
                      >
                        Inclusions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 6,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 6,
                          done: 6,
                        })}
                        onClick={() => {
                          toggleArrowTab(6);
                        }}
                      >
                        Exclusions
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 7,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 7,
                          done:7,
                        })}
                        onClick={() => {
                          toggleArrowTab(7);
                        }}
                      >
                        Images
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 8,
                          //done: activeArrowTab >= 1 && activeArrowTab <= 8,
                           done: 8,
                        })}
                        onClick={() => {
                          toggleArrowTab(8);
                        }}
                      >
                        Itinerary
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        href="#"
                        id="steparrow-gen-info-tab"
                        className={classnames({
                          active: activeArrowTab === 9,
                          done: 9,
                          // done: activeArrowTab >= 1 && activeArrowTab <= 9,
                        })}
                        onClick={() => {
                          toggleArrowTab(9);
                        }}
                      >
                        Guidelines
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>

                <TabContent activeTab={activeArrowTab}>
                  <TabPane id="steparrow-destination-info" tabId={1}>
                    <DestinationAndPackage
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}
                      showDestinationType = {showDestinationType}
                      showDestination = {showDestination}
                      destination={destination}
                      destinationOptions={destinationOptions}
                      destinationType={destinationType}
                      destinationTypes={destinationTypes}
                      is_active={is_active}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      onChangePackageHandller={onChangePackageHandller}
                      packageDescription={packageDescription}
                      package_commision={package_commision}
                      package_name={package_name}
                      readOnly={readOnly}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      specialCheckBox={specialCheckBox}
                      fromDate={fromDate}
                      toDate={toDate}
                      isInternational={true}
                      nights={nights}          

                    />
                  </TabPane>

                  <TabPane id="steparrow-cityandnight-info" tabId={2}>
                    <PackageCity
                      activeArrowTab={activeArrowTab}
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}
                      cityAndNight={cityAndNight}
                      cityOptions={cityOptions}
                      handleAddCity={handleAddCity}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      removeCityHandler={removeCityHandler}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      handleDragEnd={handleDragEnd}
                    />
                  </TabPane>

                  {/* <TabPane id="steparrow-cityandnight-info" tabId={3}>
                    <PackageSiteSeeing
                      dataSource={dataSourceSite}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      activeArrowTab={activeArrowTab}
                      toggleArrowTab={toggleArrowTab}
                    />
                  </TabPane> */}
                  <TabPane id="steparrow-gen-info" tabId={4}>
                    <PackageTransportRate
                      activeArrowTab={activeArrowTab}
                      animatedComponents={animatedComponents}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      selectOptionHandller={selectOptionHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      transport={transport}
                      transportList={transportList}
                      transportOptions={transportOptions}
                    />
                  </TabPane>
                  <TabPane id="steparrow-inclusions-info" tabId={5}>
                    {/**Package inclusions*/}
                    <PackageInclusions
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddInclusions={handleAddInclusions}
                      inclusions={inclusions}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      removeInclustionsHandller={removeInclustionsHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      type={"inclusions"}
                    />
                  </TabPane>
                  <TabPane id="steparrow-exclusions-info" tabId={6}>
                    {/**Package inclusions*/}
                    <PackageExlusions
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddInclusions={handleAddExclusions}
                      inclusions={exclusions}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      removeInclustionsHandller={removeExclusionsHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                      type={"exclusions"}
                    />
                  </TabPane>
                  <TabPane id="steparrow-image-info" tabId={7}>
                    <PackageImages
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      getImageFileNames={getImageFileNames}
                      handleAcceptedFiles={handleAcceptedFiles}
                      modalSaveHandller={modalSaveHandller}
                      onDeleteHandller={onDeleteHandller}
                      onImagePrimaryHandller={onImagePrimaryHandller}
                      removeSelectedImages={removeSelectedImages}
                      selectedFiles={selectedFiles}
                      toggleArrowTab={toggleArrowTab}
                    />
                  </TabPane>
                  <TabPane id="steparrow- itinerary-info" tabId={8}>
                    <PackageItinerary
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      handleAddItinerary={handleAddItinerary}
                      modalSaveHandller={modalSaveHandller}
                      onChangeHandller={onChangeHandller}
                      packageItinerary={packageItinerary}
                      removeItineraryHandller={removeItineraryHandller}
                      showInvalid={showInvalid}
                      toggleArrowTab={toggleArrowTab}
                    />
                  </TabPane>
                  <TabPane id="steparrow- guidelines-info" tabId={9}>
                    <PackageGuideLines
                      onChange={onChangeEditorHandller}
                      activeArrowTab={activeArrowTab}
                      cancelHandller={cancelHandller}
                      modalSaveHandller={modalSaveHandller}
                      toggleArrowTab={toggleArrowTab}
                      packageGuideLines={packageGuideLines}
                    />
                  </TabPane>
                </TabContent>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Modal>
    </>
  );
};

export default InternationalPackage;
