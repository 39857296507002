import React from "react";
import { Col, Form, FormFeedback, Label, Row } from "reactstrap";
import InputProfie from "./InputProfie";
import {
  getSessionUsingSessionStorage,
  setLocalStorageItem,
} from "../../services/common/session";
import { useEffect } from "react";
import { useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";

import { updateCompany } from "../../services/User/userService";
import { ToastContainer } from "react-toastify";
import LoadingButton from "../../common/LoadingButton";
import makeAnimated from "react-select/animated";
import { currencyUnitsJson } from "../../json/currencyUntit";
const animatedComponents = makeAnimated();
const CompanyDetails = ({ companydetails }) => {
  const [userDetails, setUserDetails] = useState();
  const [loading, setLoading] = useState(false);
  const promise = getSessionUsingSessionStorage();
  const [currencyUnitOption, setCurrencyUnitOpton] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState({
    value: "₹",
    label: `INR (Indian Rupee) ₹`,
  });

  useEffect(() => {
    let tempCurrencyunit = [];
    if (currencyUnitsJson) {
      currencyUnitsJson.map((item, index) => {
        tempCurrencyunit.push({
          value: item.symbol,
          label: `${item.code} (${item.name}) ${item.symbol}`,
        });
      });
    }
    setCurrencyUnitOpton(tempCurrencyunit);
  }, []);

  useEffect(() => {
    if (companydetails && currencyUnitOption?.length > 0) {
      let currencyUnitFilter = currencyUnitOption.find(
        (itemCurreny) => itemCurreny.value === companydetails.currencyUnit
      );
      setSelectedCurrency(currencyUnitFilter);
    }
  }, [companydetails, currencyUnitOption]);

  const selectOptionHandller = (value) => {
    setSelectedCurrency(value);
  };
  useEffect(() => {
    /**check authentication */
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserDetails(value);
        return value;
      });
  }, []);
  const updateUserCompanyDetails = async (value) => {
    setLoading(true);
    try {

      debugger;

      const response = await updateCompany({
        Company_id: companydetails.company_id,
        UserCompanyId: companydetails.userCompanyId,
        AspNetUserId: companydetails.aspNetUserId,
        Company_name: value.companyname,
        Company_phone: value.companyContactNumber.toString(),
        Company_email: value.companyEmail,
        Company_gst_no: value.gstno,
        Company_website: value.companyWebsite,
        Company_city: value.companyCity,
        Company_state: value.companyState,
        Company_country: value.companyCountry,
        Company_zipcode: value.companyZipcode,
        CurrencyUnit: selectedCurrency?.value,
        Company_description: value.companyDescription,
        Row_created_by: userDetails.email,
        Row_altered_by: userDetails.email,
        Operation: "UPDATE",
      });
      if (response?.status === "SUCCESS") {
        setLocalStorageItem("currencySymbol", selectedCurrency?.value);
        successNotify("Company details updated successfully!");
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify("Something went error updation company details!");
    } finally {
      setLoading(false);
    }
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      companyname: companydetails?.company_name || "",
      companyContactNumber: companydetails?.company_phone || "",
      companyEmail: companydetails?.company_email || "",
      gstno: companydetails?.company_gst_no || "",
      companyWebsite: companydetails?.company_website || "",
      companyCity: companydetails?.company_city || "",
      companyState: companydetails?.company_state || "",
      companyCountry: companydetails?.company_country || "",
      companyZipcode: companydetails?.company_zipcode || 0,
      companyDescription: companydetails?.company_description || "",
    },
    validationSchema: Yup.object({
      companyname: Yup.string().required("Please Enter Your Company Name"),
      companyContactNumber: Yup.string().required("Please Enter Your Contact"),
      companyEmail: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      updateUserCompanyDetails(values);
    },
  });
  return (
    <>
      <ToastContainer />
      <Form
        action="#"
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <Row>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyname"}
                labelName={"Company Name"}
                placeholder={"Enter company name"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyname}
                defaultValue={validation.values.companyname}
                invalid={
                  validation.touched.companyname &&
                  validation.errors.companyname
                }
              />
              {validation.errors.companyname &&
              validation.touched.companyname ? (
                <FormFeedback type="invalid">
                  {validation.errors.companyname}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyContactNumber"}
                labelName={"Contact Number"}
                placeholder={"Enter contact number"}
                type={"number"}
                invalid={
                  validation.touched.companyContactNumber &&
                  validation.errors.companyContactNumber
                }
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyContactNumber}
                defaultValue={validation.values.companyContactNumber}
              />
              {validation.errors.companyContactNumber &&
              validation.touched.companyContactNumber ? (
                <FormFeedback type="invalid">
                  {validation.errors.companyContactNumber}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyEmail"}
                labelName={"Email"}
                placeholder={"Enter email id"}
                type={"email"}
                invalid={
                  validation.touched.companyEmail &&
                  validation.errors.companyEmail
                }
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyEmail}
                defaultValue={validation.values.companyEmail}
              />
              {validation.errors.companyEmail &&
              validation.touched.companyEmail ? (
                <FormFeedback type="invalid">
                  {validation.errors.companyEmail}
                </FormFeedback>
              ) : null}
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"gstno"}
                labelName={"GST Number"}
                placeholder={"Enter GST number"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.gstno}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyWebsite"}
                labelName={"Website"}
                placeholder={"Enter website url"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyWebsite}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyCity"}
                labelName={"City"}
                placeholder={"Enter your city"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyCity}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyState"}
                labelName={"State"}
                placeholder={"Enter your state"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyState}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyCountry"}
                labelName={"Country"}
                placeholder={"Enter your country"}
                type={"text"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyCountry}
              />
            </div>
          </Col>
          <Col lg={6}>
            <div className="mb-3">
              <InputProfie
                id={"companyZipcode"}
                labelName={"Zip Code"}
                placeholder={"Enter your zip code"}
                type={"number"}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                value={validation.values.companyZipcode}
              />
            </div>
          </Col>
          <Col lg={6} className="mb-3">
            <div>
              <label htmlFor="currencyUnit" className="form-label">
                Currency Unit
              </label>
              <Select
                value={selectedCurrency}
                id="currencyUnit"
                name="currencyUnit"
                onChange={(chooseOption) => {
                  selectOptionHandller(chooseOption);
                }}
                options={currencyUnitOption}
                components={animatedComponents}
              />
            </div>
          </Col>
          <Col lg={12}>
            <div className="mb-3 pb-2">
              <Label
                htmlFor="exampleFormControlTextarea"
                className="form-label"
              >
                Description
              </Label>
              <textarea
                className="form-control"
                id="companyDescription"
                rows="3"
                value={validation.values.companyDescription}
                onBlur={validation.handleBlur}
                onChange={validation.handleChange}
                defaultValue={validation.values.companyDescription}
              ></textarea>
            </div>
          </Col>
          <Col lg={12}>
            <div className="hstack gap-2 justify-content-end">
              {loading ? (
                <LoadingButton
                  btnText={"Update Company details"}
                  externalClass={"btn btn-primary"}
                  color={"primary"}
                />
              ) : (
                <button type="submit" className="btn btn-primary">
                  Update Company details
                </button>
              )}
              <button
                type="reset"
                className="btn btn-soft-success"
                onClick={() => validation.handleReset()}
              >
                Cancel
              </button>
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CompanyDetails;
