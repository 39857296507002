import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledTooltip,
  Button,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Space, Table } from "antd";
import Multiselect from "multiselect-react-dropdown";
import {
  getTravellingCompany,
  saveTravellingCompany,
} from "../../services/MarketingMenu";
import { appName } from "../../common/applicationName";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import EditPoupup from "./EditPoupup";
import InputControll from "./InputControll";
import { getSessionUsingSessionStorage } from "../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import { ToastContainer } from "react-toastify";
import TextareaControll from "./TextareaControll";
import RequiredError from "../../common/RequiredError";
import Highlighter from "react-highlight-words";
import { useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";
import UseAuth from "../../components/UseAuth";
const UpdateTravellingCompany = () => {
  UseAuth();
  document.title = `${appName}-Update Travelling Company`;
  const [dataSource, setDataSource] = useState([]);
  const [showInvalid, setShowInvalid] = useState(false);
  const [isOpenMoal, setIsOpenMoal] = useState(false);
  const [content, setContent] = useState(undefined);
  const [readOnly, setReadOnly] = useState(true);
  const [userName, setUserName] = useState();
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /**This is for selected value filter */
  const [selectedValueStatus, setSelectedValueStatus] = useState([
    { name: "WIP", id: "wip" },
  ]);
  const [selectedValueState, setSelectedValuesState] = useState([]);
  const [selectedValueCity, setSelectedValueCity] = useState([]);
  const searchInput = useRef(null);

  const getTravellingCompanyData = async (params) => {
    try {
      const response = await getTravellingCompany(params);
      if (response?.data?.status === "SUCCESS") {
        setDataSource(response.data.data);
      } else {
        throw response.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  const onChangeHandller = (e) => {
    setContent({
      ...content,
      [e.target.name]:
        e.target.name === "is_active" ? e.target.checked : e.target.value,
    });
  };
  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  /**This function for get local storage */
  const getTravellingCompanyFilterData = () => {
    const ls_status = localStorage.getItem("ls_status");
    const ls_city = localStorage.getItem("ls_city");
    const ls_state = localStorage.getItem("ls_state");
    let params = new URLSearchParams();
    params.append("status", ls_status);
    params.append("city", ls_city);
    params.append("state", ls_state);
    getTravellingCompanyData(params);
  };
  /**This is for get data */
  useEffect(() => {
    const ls_status = localStorage.getItem("ls_status");
    const ls_city = localStorage.getItem("ls_city");
    const ls_state = localStorage.getItem("ls_state");
    debugger;

    if (ls_status) {
      /**This is for status default selected */
      let ls_statusArray = ls_status?.split(",");
      let ls_localTemp_status = [];
      if (ls_statusArray[0] !== "") {
        for (let i = 0; i < ls_statusArray.length; i++) {
          ls_localTemp_status.push({
            name: ls_statusArray[i],
            id: ls_statusArray[i],
          });
          setSelectedValueStatus(ls_localTemp_status);
        }
      }
    }
    if (ls_city) {
      /**This is for city default selected */
      let ls_cityArray = ls_city?.split(",");
      let ls_localTemp_city = [];
      if (ls_cityArray[0] !== "") {
        for (let i = 0; i < ls_cityArray.length; i++) {
          ls_localTemp_city.push({
            name: ls_cityArray[i],
            id: ls_cityArray[i],
          });
          setSelectedValueCity(ls_localTemp_city);
        }
      }
    }

    if (ls_state) {
      /**This is for state default selected*/
      let ls_stateArray = ls_state?.split(",");
      let ls_localTemp_state = [];
      if (ls_stateArray[0] !== "") {
        for (let i = 0; i < ls_stateArray.length; i++) {
          ls_localTemp_state.push({
            name: ls_localTemp_state[i],
            id: ls_localTemp_state[i],
          });
          setSelectedValuesState(ls_localTemp_state);
        }
      }
    }

    localStorage.setItem("ls_status", ls_status || "wip");
    localStorage.setItem("ls_city", ls_city || "");
    localStorage.setItem("ls_state", ls_state || "");
    getTravellingCompanyFilterData();
  }, []);
  const tog_scroll = () => {
    setIsOpenMoal(!isOpenMoal);
  };

  /**This function for edithandller */
  const editHandller = (record) => {
    setShowInvalid(false);
    setContent(record);
    tog_scroll();
    setReadOnly(true);
  };

  /**This is for save handller */
  const modalSaveHandller = async () => {
    setShowInvalid(true);
    try {
      if (!content?.company_name) {
        throw "Company name is requried !";
      }
      if (!content?.state) {
        throw "State is requried !";
      }
      if (!content?.city) {
        throw "City is requried !";
      }
      if (
        !content?.email_id_1?.match(validRegex) &&
        content?.email_id_1?.length > 0
      ) {
        throw "Email id 1 is invalid !";
      }
      if (
        !content?.email_id_2?.match(validRegex) &&
        content?.email_id_2?.length > 0
      ) {
        throw "Email id 2 is invalid !";
      }
      if (!isUrlValid(content?.website) && content?.website?.length > 0) {
        throw "Url invalid !";
      }

      const updateData = {
        Travelling_company_id: content?.travelling_company_id || 0,
        Company_name: content?.company_name,
        Mobile_1: content?.mobile_1,
        Mobile_2: content?.mobile_2,
        Email_id_1: content?.email_id_1,
        Email_id_2: content?.email_id_2,
        Website: content?.website,
        Address: content?.address,
        City: content?.city,
        State: content?.state,
        Status: content?.status,
        Landline: content?.landline,
        Remarks: content?.remarks,
        Is_active: content.is_active,
        Row_created_by: userName,
        Row_created_date: new Date(),
        Row_altered_by: userName,
        Row_altered_date: new Date(),
      };
      const response = await saveTravellingCompany(updateData);
      if (response?.status === "SUCCESS") {
        successNotify(`${readOnly ? "Saved" : "Updated"} Successfully !`);
        setShowInvalid(false);
        getTravellingCompanyData();
        setContent();
        tog_scroll();
      } else {
        throw response.message;
      }
    } catch (error) {
      errornotify(error);
      // setContent();
    }
  };
  /**This is for cancel handller */
  const cancelHandller = () => {
    tog_scroll();
    setContent(content);
  };

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const statusOptions = [
    { text: "Pending", value: "pending" },
    { text: "WIP", value: "wip" },
    { text: "Email Sent", value: "Send" },
    { text: "Done", value: "Done" },
  ];

  /**This is for filters*/
  const globalFilterStatusOptions = [
    { name: "Pending", id: "pending" },
    { name: "WIP", id: "wip" },
    { name: "Email Sent", id: "Send" },
    { name: "Done", id: "Done" },
  ];
  /**This is for filter state */

  const globalFilterStateOptions = [
    { name: "Madhya Pradesh", id: "Madhya Pradesh" },
    { name: "Gujarat", id: "Gujarat" },
  ];
  /**This is filter for city */
  const globalFilterCityOptions = [
    { name: "Ahmedabad", id: "Ahmedabad" },
    { name: "Singrauli", id: "Singrauli" },
  ];

  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      fixed: "left",
      render: (text, record) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      fixed: "left",
      sorter: {
        compare: (a, b) => a.state.localeCompare(b.state),
        multiple: 11,
      },
      ...getColumnSearchProps("state"),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      fixed: "left",
      sorter: {
        compare: (a, b) => a.city.localeCompare(b.city),
        multiple: 10,
      },
      ...getColumnSearchProps("city"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "left",
      filters: statusOptions,
      filterMode: "tree",
      onFilter: (value, record) => record.status === value,
      sorter: {
        compare: (a, b) => a.city.localeCompare(b.city),
        multiple: 9,
      },
      // ...getColumnSearchProps("status"),
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
      sorter: {
        compare: (a, b) => a.city.localeCompare(b.city),
        multiple: 8,
      },
      ...getColumnSearchProps("company_name"),
    },
    {
      title: "Mobile 1",
      dataIndex: "mobile_1",
      key: "mobile_1",
      sorter: {
        compare: (a, b) => a.mobile_1.localeCompare(b.mobile_1),
        multiple: 7,
      },
      ...getColumnSearchProps("mobile_1"),
    },
    {
      title: "Mobile 2",
      dataIndex: "mobile_2",
      key: "mobile_2",
      sorter: {
        compare: (a, b) => a.mobile_2.localeCompare(b.mobile_2),
        multiple: 6,
      },
      ...getColumnSearchProps("mobile_2"),
    },
    {
      title: "Email Id 1",
      dataIndex: "email_id_1",
      key: "email_id_1",
      sorter: {
        compare: (a, b) => a.email_id_1.localeCompare(b.email_id_1),
        multiple: 5,
      },
      ...getColumnSearchProps("email_id_1"),
    },
    {
      title: "Email Id 2",
      dataIndex: "email_id_2",
      key: "email_id_2",
      sorter: {
        compare: (a, b) => a.email_id_2.localeCompare(b.email_id_8),
        multiple: 4,
      },
      ...getColumnSearchProps("email_id_2"),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      sorter: {
        compare: (a, b) => a.website.localeCompare(b.website),
        multiple: 3,
      },
      ...getColumnSearchProps("website"),
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      sorter: {
        compare: (a, b) => a.address.localeCompare(b.address),
        multiple: 2,
      },
      ...getColumnSearchProps("address"),
      render: (text, record) => {
        return (
          <>
            <UncontrolledTooltip
              placement="top"
              target={`tooltipId${record.package_id}`}
            >
              {record.address}
            </UncontrolledTooltip>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
              id={`tooltipId${record.package_id}`}
            >
              {record.address}
            </p>
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 1,
      },
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
  ];

  /**This function for check url valid or not */
  function isUrlValid(string) {
    try {
      new URL(string);
      return true;
    } catch (err) {
      return false;
    }
  }
  /**This function for add handller */
  const handleAdd = () => {
    setContent({
      company_name: undefined,
      remarks: undefined,
      landline: undefined,
      mobile_1: undefined,
      mobile_2: undefined,
      email_id_1: undefined,
      email_id_2: undefined,
      website: undefined,
      address: undefined,
      city: undefined,
      state: undefined,
      status: "pending",
      is_active: true,
    });
    tog_scroll();
    setShowInvalid(false);
    setReadOnly(false);
  };
  let validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  /**This is for input text handller */
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSource.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
    } else {
      setFilterData(dataSource);
    }
  };

  /**This is for selected status options */
  const onSelect = (name, selectedList, selectedItem) => {
    const valuesArray = selectedList.map((obj) => obj.id);
    const commaSeparatedString = valuesArray.join(",");
    if (name === "status") {
      localStorage.setItem("ls_status", commaSeparatedString);
    } else if (name === "city") {
      localStorage.setItem("ls_city", commaSeparatedString);
    } else {
      localStorage.setItem("ls_state", commaSeparatedString);
    }
    getTravellingCompanyFilterData();
  };

  /**This is for remove options */
  const onRemove = (name, selectedList, removedItem) => {
    const valuesArray = selectedList.map((obj) => obj.id);
    const commaSeparatedString = valuesArray.join(",");
    if (name === "status") {
      localStorage.setItem("ls_status", commaSeparatedString);
    } else if (name === "city") {
      localStorage.setItem("ls_city", commaSeparatedString);
    } else {
      localStorage.setItem("ls_state", commaSeparatedString);
    }
    getTravellingCompanyFilterData();
  };

  /**This is for show hide handller */
  const [showHide, setShowHide] = useState(true);
  const showHideHandller = () => {
    setShowHide(!showHide);
  };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Update Travelling Company"
            pageTitle="Marketing"
            isSubTitle={true}
          />
          <Card>
            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      <ButttonTravelNinjaz
                        backGroundColor="success"
                        className="add-btn me-1 my-1"
                        id="create-btn"
                        onCLickHancller={handleAdd}
                        buttonIcon={
                          <i className="ri-add-line align-bottom me-1"></i>
                        }
                        buttonText="Add"
                      />
                      <ButttonTravelNinjaz
                        backGroundColor="primary"
                        className="add-btn me-1 my-1"
                        id="create-btn"
                        onCLickHancller={showHideHandller}
                        buttonText={showHide ? "Hide Filters" : "Show Filters"}
                      />
                    </div>
                  </Col>
                  <Col className="col-sm">
                    <div className="d-flex justify-content-sm-end">
                      <div className="search-box ms-2 my-1">
                        <input
                          type="text"
                          className="form-control search"
                          placeholder="Search..."
                          onChange={(e) => inputHandller(e.target.value)}
                        />
                        <i className="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </Col>
                </Row>
                {showHide ? (
                  <Row className="g-4 mb-3">
                    <Col className="col-sm-auto">
                      <Multiselect
                        options={globalFilterStateOptions} // Options to display in the dropdown
                        onSelect={(selectedList, selectedItem) =>
                          onSelect("state", selectedList, selectedItem)
                        } // Function will trigger on select event
                        onRemove={(selectedList, selectedItem) =>
                          onRemove("state", selectedList, selectedItem)
                        } // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Select State"
                        className="my-1 me-1"
                        style={{ zIndex: 9999 }}
                        selectedValues={selectedValueState}
                      />
                    </Col>
                    <Col className="col-sm-auto">
                      <Multiselect
                        options={globalFilterCityOptions} // Options to display in the dropdown
                        onSelect={(selectedList, selectedItem) =>
                          onSelect("city", selectedList, selectedItem)
                        } // Function will trigger on select event
                        onRemove={(selectedList, selectedItem) =>
                          onRemove("city", selectedList, selectedItem)
                        } // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Select City"
                        className="my-1 me-1"
                        selectedValues={selectedValueCity}
                      />
                    </Col>
                    <Col className="col-sm-auto">
                      <Multiselect
                        options={globalFilterStatusOptions} // Options to display in the dropdown
                        onSelect={(selectedList, selectedItem) =>
                          onSelect("status", selectedList, selectedItem)
                        } // Function will trigger on select event
                        onRemove={(selectedList, selectedItem) =>
                          onRemove("status", selectedList, selectedItem)
                        } // Function will trigger on remove event
                        displayValue="name" // Property name to display in the dropdown options
                        placeholder="Select Status"
                        selectedValues={selectedValueStatus}
                        className="my-1 me-1"
                      />
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </div>
              <div>
                <Table
                  columns={columns}
                  dataSource={
                    searchText?.length > 0 ? [...filterData] : [...dataSource]
                  }
                  locale={{
                    triggerDesc: null,
                    triggerAsc: null,
                    cancelSort: null,
                  }}
                  scroll={{
                    // x: 1500,
                    y: 300,
                  }}
                  pagination={{
                    defaultPageSize: 5,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      5, 10, 20, 50, 100,
                      // dataSource?.length,
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items  `,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
      <Modal
        isOpen={isOpenMoal}
        toggle={() => {
          tog_scroll();
        }}
        centered
        size="xl"
        scrollable={true}
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_scroll();
          }}
        >
          {readOnly ? "Edit" : "Add"} Travelling Company
        </ModalHeader>
        <ModalBody style={{ minHeight: "65vh" }}>
          <form>
            <div className="row g-3">
              <Col xxl={6}>
                <InputControll
                  labelName={"State"}
                  readOnly={readOnly}
                  defaultValue={content?.state}
                  value={content?.state}
                  name={"state"}
                  onChangeHandller={onChangeHandller}
                  invalid={!content?.state && showInvalid}
                />
                {!content?.state && showInvalid ? (
                  <RequiredError errorMessage={"State is Required"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"City"}
                  readOnly={readOnly}
                  defaultValue={content?.city}
                  value={content?.city}
                  name={"city"}
                  onChangeHandller={onChangeHandller}
                  invalid={!content?.city && showInvalid}
                />
                {!content?.city && showInvalid ? (
                  <RequiredError errorMessage={"City is Required"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={12}>
                <InputControll
                  labelName={"Company Name"}
                  readOnly={readOnly}
                  defaultValue={content?.company_name}
                  value={content?.company_name}
                  name={"company_name"}
                  onChangeHandller={onChangeHandller}
                  invalid={!content?.company_name && showInvalid}
                />
                {!content?.company_name && showInvalid ? (
                  <RequiredError errorMessage={"Company Name is Required"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Mobile 1"}
                  defaultValue={content?.mobile_1}
                  value={content?.mobile_1}
                  name={"mobile_1"}
                  onChangeHandller={onChangeHandller}
                />
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Mobile 2"}
                  defaultValue={content?.mobile_2}
                  value={content?.mobile_2}
                  name={"mobile_2"}
                  onChangeHandller={onChangeHandller}
                />
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Email Id 1"}
                  defaultValue={content?.email_id_1}
                  value={content?.email_id_1}
                  name={"email_id_1"}
                  onChangeHandller={onChangeHandller}
                  invalid={
                    !content?.email_id_1?.match(validRegex) &&
                    showInvalid &&
                    content?.email_id_1?.length > 0
                  }
                />
                {!content?.email_id_1?.match(validRegex) &&
                showInvalid &&
                content?.email_id_1?.length > 0 ? (
                  <RequiredError errorMessage={"Invalid email id!"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Email Id 2"}
                  defaultValue={content?.email_id_2}
                  value={content?.email_id_2}
                  name={"email_id_2"}
                  onChangeHandller={onChangeHandller}
                  invalid={
                    !content?.email_id_2?.match(validRegex) &&
                    showInvalid &&
                    content?.email_id_2?.length > 0
                  }
                />
                {!content?.email_id_2?.match(validRegex) &&
                showInvalid &&
                content?.email_id_2?.length > 0 ? (
                  <RequiredError errorMessage={"Invalid email id!"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Landline"}
                  defaultValue={content?.landline}
                  value={content?.landline}
                  name={"landline"}
                  onChangeHandller={onChangeHandller}
                />
              </Col>
              <Col xxl={6}>
                <InputControll
                  labelName={"Website"}
                  defaultValue={content?.website}
                  value={content?.website}
                  name={"website"}
                  onChangeHandller={onChangeHandller}
                  invalid={
                    !isUrlValid(content?.website) &&
                    showInvalid &&
                    content?.website?.length > 0
                  }
                />
                {!isUrlValid(content?.website) &&
                showInvalid &&
                content?.website?.length > 0 ? (
                  <RequiredError errorMessage={"Invalid Url !"} />
                ) : (
                  ""
                )}
              </Col>
              <Col xxl={12}>
                <TextareaControll
                  defaultValue={content?.address}
                  value={content?.address}
                  name={"address"}
                  onChange={onChangeHandller}
                  id={"address"}
                  lableName={"Address"}
                />
              </Col>
              <Col xxl={6}>
                <div>
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-select "
                    aria-label="Default select example"
                    onChange={onChangeHandller}
                    name="status"
                    defaultValue={readOnly ? content?.status : "pending"}
                  >
                    <option value={""}>Select status</option>
                    {statusOptions?.map((item, index) => {
                      return (
                        <option
                          value={item.value}
                          selected={
                            readOnly
                              ? item.value === content?.status
                              : item.value === "pending"
                          }
                        >
                          {item.text}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col xxl={6}>
                <TextareaControll
                  defaultValue={content?.remarks}
                  value={content?.remarks}
                  name={"remarks"}
                  onChange={onChangeHandller}
                  id={"remarks"}
                  lableName={"Remarks"}
                />
              </Col>
              <Col xxl={6}>
                <div className="form-check form-switch form-switch-success my-3">
                  <InputControll
                    labelName={"Is active"}
                    checked={readOnly ? content?.is_active : true}
                    defaultChecked={readOnly ? content?.is_active : true}
                    name={"is_active"}
                    onChangeHandller={onChangeHandller}
                    role={"switch"}
                    type={"checkbox"}
                    // className={"form-check-input"}
                  />
                </div>
              </Col>
            </div>
          </form>
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div>
      </Modal>
      {/* {content && (
        <EditPoupup
          isOpenMoal={isOpenMoal}
          tog_scroll={tog_scroll}
          content={content}
          getTravellingCompanyData={getTravellingCompanyData}
        />
      )} */}
    </>
  );
};

export default UpdateTravellingCompany;
