import React from "react";
import QuillTextEditor  from "../../components/package/QuillTextEditor";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";
const PackageGuideLines = ({
  onChangeQuillEditorHandller,
  toggleArrowTab,
  activeArrowTab,
  modalSaveHandller,
  packageGuidelines,
  cancelHandller,
}) => {
  //console.log("packageGuideLines", packageGuideLines);
  return (
    <>

      <div className="cardItems">
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="align-items-center d-flex bg-light">
                <h4 className="card-title mb-0">Important Guidelines</h4>
              </CardHeader>
              <CardBody>
              <QuillTextEditor                  
                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}
                      editorId = {"packageGuidelines"}                    
                      editorName = {"packageGuidelines"}
                      editorHTML={packageGuidelines}
                    />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-xl-between my-3">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default PackageGuideLines;
