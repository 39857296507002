import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import React from "react";
import { useState } from "react";
import { Input, Label, Tooltip, Row, Col,  Card, CardBody, CardHeader} from "reactstrap";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";
import QuillTextEditor  from "./QuillTextEditor";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import dragIcon from "../../assets/images/drag.png";


const PackageFaqComponent = ({
  handleAddFaq,
  packageFAQ,
  onChangeHandller,
  showInvalid,
  removeFaqHandller,
  onChangeQuillEditorHandller,
  toggleArrowTab,
  activeArrowTab,
  modalSaveHandller,
  cancelHandller,
  handleDragEndFaq
}) => {
  const [tooltipOpenFaq, setTooltipOpenFaq] = useState(false);
  
  return (  
  
  <>

      <Row>
              <Col lg={12}>
                  <Card>
                    <CardHeader className="align-items-center d-flex bg-light">
                      <h4 className="card-title mb-0">Package FAQ</h4>
                    </CardHeader>
                    <CardBody>                
                        <Row>
                          <Col xxl={12} className="mb-3 cardItems">
                            <div className="my-1">
                              <label htmlFor="add_city" className="form-label">
                                Add FAQ{" "}
                                <i
                                  className="ri-add-line align-bottom mx-2"
                                  onClick={handleAddFaq}
                                  id="package_Faq"
                                  style={{
                                    padding: "3px",
                                    // marginTop: "10px",
                                    fontSize: "14px",
                                    borderRadius: "50%",
                                    backgroundColor: "#099885",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                ></i>
                                <Tooltip
                                  isOpen={tooltipOpenFaq}
                                  placement="right"
                                  target="package_Faq"
                                  toggle={() => {
                                    setTooltipOpenFaq(!tooltipOpenFaq);
                                  }}
                                >
                                  Add FAQ
                                </Tooltip>
                              </label>
                            </div>                         

                              <DragDropContext onDragEnd={handleDragEndFaq}>
                               
                                <table
                                  className="table align-top table-nowrap "
                                  id="customerTable"
                                >
                               
                                  <Droppable droppableId="droppable-1">
                                    {(provider) => (
                                         <tbody
                                          ref={provider.innerRef}
                                          {...provider.droppableProps}
                                            >      

                                          {packageFAQ.length > 0 &&
                                            packageFAQ.map((item, index) => (

                                              <Draggable
                                              key={`dragableId-${index}`}
                                              draggableId={`dragableId-${index}`}
                                              index={index}
                                              type="TASK"
                                            >
                                              {(provider) => (
                                                <tr
                                                  key={index}
                                                  {...provider.draggableProps}
                                                  {...provider.dragHandleProps}
                                                  ref={provider.innerRef}
                                                >
                                                  <td
                                                    {...provider.dragHandleProps}
                                                    width={20}
                                                  >
                                                    <img
                                                      src={dragIcon}
                                                      alt="dragIcon"
                                                      style={{
                                                        height: "30px",
                                                        width: "30px",
                                                        opacity: "0.7",
                                                      }}
                                                    />
                                                  </td>
                                                  <td width={10}> {index + 1}</td>
                                                  <td width={850}>
                                                            <Label className="form-label">Question</Label>
                                                              <Input
                                                                className="form-control"
                                                                id={item.id}
                                                                defaultValue={item.packageFaqTitle}
                                                                value={item.packageFaqTitle}
                                                                name="packageFaqTitle"
                                                                onChange={onChangeHandller}
                                                                invalid={!item.packageFaqTitle && showInvalid}

                                                                
                                                              ></Input>
                                                            {/* 
                                                              {'packageFaqTitle = ' + item.packageFaqTitle}
                                                              <br/>
                                                              {'packageFaqDescription = ' + item.packageFaqDescription} */}

                                                              {!item.packageFaqTitle && showInvalid ? (
                                                                <p
                                                                  style={{
                                                                    fontSize: "12px",
                                                                    color: "red",
                                                                  }}
                                                                >
                                                                  Question is Required
                                                                </p>
                                                              ) : (
                                                                ""
                                                              )}

                                                              <Label className="form-label">
                                                                  Answer
                                                                  </Label>

                                                                
                                                                  {/* 
                                                                  <QuillTextEditor                  
                                                                      onChangeQuillEditorHandller={onChangeQuillEditorHandller}   
                                                                      editorId = {item.id}
                                                                      editorName = {"packageFaqDescription"}                                                                              
                                                                      editorHTML={item.packageFaqDescription}
                                                                      className={
                                                                        !item.packageFaqDescription && showInvalid
                                                                          ? "form-control border border-danger"
                                                                          : "form-control"
                                                                      }
                                                                    /> */}

                                                                    <textarea
                                                                        className={
                                                                          !item.packageFaqDescription && showInvalid
                                                                            ? "border border-danger form-control descriptionPackage"
                                                                            : "form-control descriptionPackage"
                                                                        }
                                                                        id={item.id}
                                                                        name="packageFaqDescription"
                                                                        value={item.packageFaqDescription}
                                                                        defaultValue={item.packageFaqDescription}
                                                                        onChange={onChangeHandller}
                                                                        // rows="3"
                                                                      ></textarea>

                                                                  {!item.packageFaqDescription && showInvalid ? (
                                                                    <p
                                                                      style={{
                                                                        fontSize: "12px",
                                                                        color: "red",
                                                                      }}
                                                                    >
                                                                      Answer is Required
                                                                    </p>
                                                                  ) : (
                                                                    ""
                                                                  )}
                                    
                                                    </td>                                               
                                                   <td width={100}>
                                                    <i
                                                      className="ri-close-line"
                                                      onClick={() => removeFaqHandller(item.id)}
                                                      style={{
                                                        fontSize: "25px",
                                                        cursor: "pointer",
                                                      }}
                                                    ></i>
                                                  </td>
                                                </tr>
                                              )}
                                             </Draggable>

                                           ))}
                                          {provider.placeholder}
                                        </tbody>
                                      )}
                                    </Droppable>
                                   </table>
                            </DragDropContext>

                          </Col>
                        </Row>                     
                    </CardBody>
                  </Card>
              </Col>   
          
      </Row>        

    
      <div className="d-flex justify-content-xl-between my-3">
        <div>
          <button
            type="button"
            className="btn btn-light btn-label previestab"
            onClick={() => {
              toggleArrowTab(activeArrowTab - 1);
            }}
          >
            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
            Back
          </button>
        </div>
        <div>
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
            className="mx-1"
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
            className="mx-1"
          />
        </div>
      </div>
    </>
  );
};

export default PackageFaqComponent;
