import React, { useState } from "react";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import { Button,Card,CardBody,CardHeader,Col,Container,Row,Input,Modal,Label,ModalBody,UncontrolledDropdown,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,  Offcanvas, OffcanvasBody,  FormFeedback, 
  ModalHeader
} from "reactstrap";


import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Image, message, Space, Table } from "antd";
import "../../assets/scss/components/customantd.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { ReactSession } from "react-client-session";
import {MoreOutlined,SearchOutlined,WarningOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import {  GetInquiryList } from "../../services/Inquiry/inquiryService";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import ConfirmationWarning from "../../components/Common/GloablMaster/ConfirmationWarning";
import { downloadExcel } from "../../functions/downloadtoExcel";
import { authError, url } from "../../services/common/const";
import { appName } from "../../common/applicationName";
import CancelCofirmModal from "../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage, getSession } from "../../services/common/session";
import {errornotify,successNotify} from "../../components/Common/notification";
import { Link,useNavigate } from "react-router-dom";
import UseAuth from "../../components/UseAuth";

// Rating
import Rating from "react-rating";


import LoadingButton from "../../common/LoadingButton";

//import DeleteModal from "../../components/Common/DeleteModal";

//User Images
import avatar2 from '../../assets/images/users/avatar-2.jpg';

//Small Images
import smallImage9 from '../../assets/images/male1.png';
//redux
import { useSelector, useDispatch } from 'react-redux';

//import action
import {
  getTeamData as onGetTeamData
} from "../../store/actions";


/*** This is Inquiry functions main */
const Inquiry = () => {
  UseAuth();
  document.title = `${appName}-Inquiry`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  //Modal  
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  //OffCanvas  
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState([]);

   //Dropdown
   const [dropdownOpen, setDropdownOpen] = useState(false);

   const toggledropDown = () => {
       setDropdownOpen(!dropdownOpen);
   };

  //Inquiry List is for Inquiry <Select> options
  const [inquiryList, setInquiryList] = useState([]);
  const navigate = useNavigate();

  //Data Source is used to Bind Data table
  const [dataSource, setDataSource] = useState(inquiryList);
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  const [count, setCount] = useState(inquiryList?.length + 1 || 1);

  const [updatable, setUpdatable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [editablecolumn, setEditableColumn] = useState(false);
  const [sortBy] = useState(1);
  const [isActive] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  const [imageFormData, setImageFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  /**This function for get Inquiry list from database */
  const getInquiryFunc = async () => {
    try {

      let params = new URLSearchParams();
   
      debugger;       
      
      const result = await GetInquiryList();
      if (result?.data?.status === "SUCCESS") {
        let jsondata = result.data.data;
        setInquiryList(jsondata);
        setDataSource(jsondata);
        setCount(jsondata?.length + 1);
      } else {
        //throw result?.message;
        errornotify(result?.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };
  useEffect(() => {
    getInquiryFunc();
  }, []);

  useEffect(() => {
    const list = document.querySelectorAll(".team-list");
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
        buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
        if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
            document.getElementById("list-view-button").classList.add("active");
            document.getElementById("grid-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.add("list-view-filter");
                el.classList.remove("grid-view-filter");
            });

        } else {
            document.getElementById("grid-view-button").classList.add("active");
            document.getElementById("list-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.remove("list-view-filter");
                el.classList.add("grid-view-filter");
            });
        }
    }
}, []);


  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);

  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);

  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
 
  const tableRef = useRef(null);

  /**column*/
  const defaultColumns = [ 
    {
      title: "Inquiry_no",
      dataIndex: "Inquiry_no",
      width: 140,
      ...getColumnSearchProps("destination_name"),
      // sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      defaultSortOrder: "ascend",    
    }     
    
  ].filter((item) => !item.hidden);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,       
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["destination_id"];
      delete item["destination_type_id"];
      delete item["isEditing"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);


  const navigateImportDestinations = () => {
    navigate('../importDestinations', {replace: true});
  };


  // const downloadExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(exportTableData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "DestinationTable.xlsx");
  // };
  return (

    <React.Fragment>
    {/* <ToastContainer closeButton={false} />
    <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
    /> */}

<ToastContainer />
    <div className="page-content">
        <Container fluid>
           
        <BreadCrumb
            title="Inquiry"
            pageTitle="Inquiry"
            isSubTitle={true}           
          />

            <Card>
                <CardBody>
                    <Row className="g-2">
                        <Col sm={4}>
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search for name, tasks, projects or something..." />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                        <Col className="col-sm-auto ms-auto">
                            <div className="list-grid-nav hstack gap-1">

                                <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i className="ri-grid-fill"></i></Button>
                                <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"><i className="ri-list-unordered"></i></Button>
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggledropDown}>
                                    <DropdownToggle type="button" className="btn btn-soft-info btn-icon fs-14">
                                        <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <li><Link className="dropdown-item" to="#">All</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Week</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Month</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Year</Link></li>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color="success"
                                    onClick={openModal}>
                                    <i className="ri-add-fill me-1 align-bottom"></i> Add Members</Button>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Row>
                <Col lg={12}>
                    <div>
                    <Row className="team-list grid-view-filter">
                                    {(inquiryList || []).map((item, key) => (
                                        <Col key={key}>
                                            <Card className="inquiry-box">
                                                <div className="team-cover">
                                                    <img src={1} alt="" className="img-fluid" />
                                                </div>
                                                <CardBody className="p-4">
                                                    <Row className="align-items-center team-row">
                                                        <Col className="team-settings">
                                                            <Row>
                                                                <Col>
                                                                    <div className="bookmark-icon flex-shrink-0 me-2">
                                                                        <Rating
                                                                            stop={1}
                                                                            emptySymbol="mdi mdi-star-outline text-muted "
                                                                            fullSymbol="mdi mdi-star text-warning "
                                                                        />
                                                                        <Input type="checkbox" id="favourite1" className="bookmark-input bookmark-hide" />
                                                                        <Label htmlFor="favourite1" className="btn-star">
                                                                            <svg width="20" height="20">
                                                                                {/* <use xlink:href="#icon-star"/> */}
                                                                            </svg>
                                                                        </Label>

                                                                    </div>
                                                                </Col>
                                                                <UncontrolledDropdown direction='start' className="col text-end">
                                                                    <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                        <i className="ri-more-fill fs-17"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem><i className="ri-eye-line me-2 align-middle" />View</DropdownItem>
                                                                        <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                                        <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </Row>
                                                        </Col>
                                                        <Col lg={4} className="col">
                                                          <Row className="text-muted text-center">
                                                                <Col xs={6} className="border-end border-end-dashed">
                                                                    <Link to="#"> <h5 className="mb-1">{item.inquiry_no}</h5> </Link>                                                                    
                                                                    <p className="text-muted mb-0">{item.status_code}</p>
                                                                    {/* <p className="text-muted mb-0">{item.prospect_code}</p> */}
                                                                </Col>

                                                                <Col xs={6}>
                                                           
                                                                    <Link to="#"><h5 className="fs-16 mb-1">{item.contact.name}</h5></Link>
                                                                    <p className="text-muted mb-0">{item.contact.phone_country_code}{item.contact.phone}</p>
                                                                    {/* <p className="text-muted mb-0">{item.contact.email}</p> */}
                                                               
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        {/* <Col lg={4} className="col">
                                                            <Row className="text-muted text-center">
                                                                <Col xs={6} className="border-end border-end-dashed">
                                                                    <h5 className="mb-1">{4}</h5>
                                                                    <p className="text-muted mb-0">Projects</p>
                                                                </Col>
                                                                <Col xs={6}>
                                                                    <h5 className="mb-1">{5}</h5>
                                                                    <p className="text-muted mb-0">Tasks</p>
                                                                </Col>
                                                            </Row>
                                                        </Col> */}
                                                        <Col lg={2} className="col">
                                                            <div className="text-end">
                                                                <Link to="/pages-profile" className="btn btn-light view-btn">View Profile</Link>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}

                                    {/* <Col lg={12}>
                                        <div className="text-center mb-3">
                                            <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                                        </div>
                                    </Col> */}
                                </Row>



                     
                        <Offcanvas
                            isOpen={isOpen}
                            direction="end"
                            toggle={() => setIsOpen(!isOpen)}
                            className="offcanvas-end border-0"
                            tabIndex="-1"
                        >
                            <OffcanvasBody className="profile-offcanvas p-0">
                                <div className="team-cover">
                                    <img src={sideBar.backgroundImg || smallImage9} alt="" className="img-fluid" />
                                </div>
                                <div className="p-3">
                                    <div className="team-settings">
                                        <Row>
                                            <Col>
                                                <div className="bookmark-icon flex-shrink-0 me-2">
                                                    <Input type="checkbox" id="favourite13" className="bookmark-input bookmark-hide" />
                                                    <Label htmlFor="favourite13" className="btn-star">
                                                        <svg width="20" height="20">
                                                            {/* <use xlink:href="#icon-star"/> */}
                                                        </svg>
                                                    </Label>
                                                </div>
                                            </Col>
                                            <UncontrolledDropdown direction='start' className="col text-end">
                                                <DropdownToggle tag="a" id="dropdownMenuLink14" role="button">
                                                    <i className="ri-more-fill fs-17"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem><i className="ri-eye-line me-2 align-middle" />View</DropdownItem>
                                                    <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                    <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </Row>
                                    </div>
                                </div>
                                <div className="p-3 text-center">
                                    <img src={sideBar.userImage || avatar2} alt="" className="avatar-lg img-thumbnail rounded-circle mx-auto" />
                                    <div className="mt-3">
                                        <h5 className="fs-15"><Link to="#" className="link-primary">{sideBar.name || "Nancy Martino"}</Link></h5>
                                        <p className="text-muted">{sideBar.designation || "Team Leader & HR"}</p>
                                    </div>
                                    <div className="hstack gap-2 justify-content-center mt-4">
                                        <div className="avatar-xs">
                                            <Link to="#" className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                                                <i className="ri-facebook-fill"></i>
                                            </Link>
                                        </div>
                                        <div className="avatar-xs">
                                            <Link to="#" className="avatar-title bg-soft-success text-success rounded fs-16">
                                                <i className="ri-slack-fill"></i>
                                            </Link>
                                        </div>
                                        <div className="avatar-xs">
                                            <Link to="#" className="avatar-title bg-soft-info text-info rounded fs-16">
                                                <i className="ri-linkedin-fill"></i>
                                            </Link>
                                        </div>
                                        <div className="avatar-xs">
                                            <Link to="#" className="avatar-title bg-soft-danger text-danger rounded fs-16">
                                                <i className="ri-dribbble-fill"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <Row className="g-0 text-center">
                                    <Col xs={6}>
                                        <div className="p-3 border border-dashed border-start-0">
                                            <h5 className="mb-1">{sideBar.projectCount || "124"}</h5>
                                            <p className="text-muted mb-0">Projects</p>
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="p-3 border border-dashed border-start-0">
                                            <h5 className="mb-1">{sideBar.taskCount || "81"}</h5>
                                            <p className="text-muted mb-0">Tasks</p>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="p-3">
                                    <h5 className="fs-15 mb-3">Personal Details</h5>
                                    <div className="mb-3">
                                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Number</p>
                                        <h6>+(256) 2451 8974</h6>
                                    </div>
                                    <div className="mb-3">
                                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Email</p>
                                        <h6>nancymartino@email.com</h6>
                                    </div>
                                    <div>
                                        <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Location</p>
                                        <h6 className="mb-0">Carson City - USA</h6>
                                    </div>
                                </div>
                                <div className="p-3 border-top">
                                    <h5 className="fs-15 mb-4">File Manager</h5>
                                    <div className="d-flex mb-3">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-soft-danger text-danger rounded fs-16">
                                                <i className="ri-image-2-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="mb-1"><Link to="#">Images</Link></h6>
                                            <p className="text-muted mb-0">4469 Files</p>
                                        </div>
                                        <div className="text-muted">
                                            12 GB
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-soft-secondary text-secondary rounded fs-16">
                                                <i className="ri-file-zip-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="mb-1"><Link to="#">Documents</Link></h6>
                                            <p className="text-muted mb-0">46 Files</p>
                                        </div>
                                        <div className="text-muted">
                                            3.46 GB
                                        </div>
                                    </div>
                                    <div className="d-flex mb-3">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-soft-success text-success rounded fs-16">
                                                <i className="ri-live-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="mb-1"><Link to="#">Media</Link></h6>
                                            <p className="text-muted mb-0">124 Files</p>
                                        </div>
                                        <div className="text-muted">
                                            4.3 GB
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 avatar-xs">
                                            <div className="avatar-title bg-soft-primary text-primary rounded fs-16">
                                                <i className="ri-error-warning-line"></i>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                            <h6 className="mb-1"><Link to="#">Others</Link></h6>
                                            <p className="text-muted mb-0">18 Files</p>
                                        </div>
                                        <div className="text-muted">
                                            846 MB
                                        </div>
                                    </div>
                                </div>
                            </OffcanvasBody>
                            <div className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
                                <button className="btn btn-light w-100"><i className="ri-question-answer-fill align-bottom ms-1"></i> Send Message</button>
                                <Link to="/pages-profile" className="btn btn-primary w-100"><i className="ri-user-3-fill align-bottom ms-1"></i> View Profile</Link>
                            </div>
                        </Offcanvas>
                    </div>
                </Col>
            </Row>

           
          
            <svg className="bookmark-hide">
                <symbol viewBox="0 0 24 24" stroke="currentColor" fill="var(--color-svg)" id="icon-star"><path strokeWidth=".4" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></symbol>
            </svg>

        </Container>
    </div>
   
     
     
      
      </React.Fragment>
  );
};

export default Inquiry;
