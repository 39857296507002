import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Input } from "reactstrap";

//SimpleBar
import SimpleBar from "simplebar-react";

//import images
import image2 from "../../assets/images/users/avatar-2.jpg";
import image3 from "../../assets/images/users/avatar-3.jpg";
import image5 from "../../assets/images/users/avatar-5.jpg";

import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import Select from "react-select";

import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';

const SearchOption = ({onFilterUpdate }) => {

 const [showDestinationTypeSelectList, setShowDestinationTypeSelectList] = useState(localStorage.getItem("global_show_destination_type") =="true" ? true: false);
 const [showDestinationSelectList, setShowDestinationSelectList] = useState(localStorage.getItem("global_show_destination") =="true" ? true: false);

 const [destinationTypeList, setDestinationTypeList] = useState(JSON.parse(localStorage.getItem("global_destinations_type_list")));
 const [destinationList, setDestinationList] = useState( JSON.parse(localStorage.getItem("global_destinations_list")));
 const [cityList, setCityList] =  useState( JSON.parse(localStorage.getItem("global_city_list")));
 
const [destinationTypeOptions, setDestinationTypeOptions] = React.useState([]);
const [destinationOptions, setDestinationOptions] = React.useState([]);
const [cityOptions, setCityOptions] = React.useState([]);

const navigate = useNavigate();

/* Initialize Select Datasource List */
// const [dataSourceList, setDataSourceList] = useState({
//   destinationTypeList: [],
//   destinationList: [],
//   cityList: [],
// });
// let { destinationTypeList, destinationList, cityList} = dataSourceList;

// const [showSelectList, setShowSelectList] = useState({
//   showDestinationTypeSelectList: "",
//   showDestinationSelectList: "",
//   showCitySelectList: "",
// });
// let { showDestinationTypeSelectList, showDestinationSelectList, showCitySelectList} = showSelectList;

/**This is All Select options*/
// const [selectOptions, setSelectOptions] = useState({
//   destinationTypeOptions: [],
//   destinationOptions: [],
//   cityOptions: [],
// });

// let { destinationTypeOptions, destinationOptions, cityOptions} = selectOptions;





/**This is All Select Default Selected option*/
const [defaultSelectedOption, setDefaultSelectedOption] = useState({
  defaultDestinationTypeOption: [],
  defaultDestinationOption: [],
  defaultCityOption: [],
});

let { defaultDestinationTypeOption, defaultDestinationOption, defaultCityOption} = defaultSelectedOption;



/**This is All Select Default Selected Value*/
const [defaultSelectedValue, setDefaultSelectedValue] = useState({
  defaultDestinationTypeValue: null,
  defaultDestinationValue: null,
  defaultCityValue: null,
});

let { defaultDestinationTypeValue, defaultDestinationValue, defaultCityValue} = defaultSelectedValue;

const [selectedDestinationType, setSelectedDestinationType] = React.useState([]);
const [selectedDestination, setSelectedDestination] = React.useState([]);
const [selectedCity, setSelectedCity] = React.useState([]);

const handleDestinationTypeChange = (destination_type) => {

  if(destination_type !=null)
  {
   const global_default_destination_type_object= destinationTypeList.filter(item => item.destination_type_id ===destination_type.value);
   localStorage.setItem("global_default_destination_type_object", JSON.stringify(global_default_destination_type_object[0]));
   localStorage.setItem("global_search_destination_type_option", JSON.stringify(destination_type));

   setSelectedDestinationType(destination_type);
   setSelectedDestination(null);
   setSelectedCity(null);
  }
  else{
    localStorage.setItem("global_search_destination_type_option", null);
    setSelectedDestinationType(null);
    setSelectedDestination(null);
    setSelectedCity(null);
  }

   localStorage.setItem("global_search_destination_option", null);
   localStorage.setItem("global_search_city_option", null);
   window.location.reload();
   //GetDestinationListFunc();

  // const url = window.location.href;
  // const pathname = window.location.pathname;
  // const protocol = window.location.protocol;
  // const hostname = window.location.hostname;

  //  alert('url - ' + url );
  //  alert('pathname - ' + pathname );
  //  alert('protocol - ' + protocol );
  //  alert('hostname - ' + hostname );

   //navigate(pathname);
};



  const handleDestinationChange = (destination) => {

    if(destination !=null)
  {
    const selected_destination= destinationList.filter(item => item.destination_id ===destination.value);
    localStorage.setItem("global_default_destination_object", JSON.stringify(selected_destination[0]));
    localStorage.setItem("global_search_destination_option", JSON.stringify(destination));
    setSelectedDestination(destination);
    setSelectedCity(null);
  }
  else{
    localStorage.setItem("global_search_destination_option", null);
    setSelectedDestination(null);
    setSelectedCity(null);
  }

    localStorage.setItem("global_search_city_option", null);

    //GetCityListFunc();
    window.location.reload();
    //const pathname = window.location.pathname;
    //navigate(pathname);


    // localStorage.setItem(SELECT_GLOBAL_SEARCH_DESTINATION_VALUE_KEY, JSON.stringify(destination));
    // setSelectedDestination(destination);

    // localStorage.setItem(SELECT_GLOBAL_SEARCH_CITY_VALUE_KEY, null);
    // setSelectedCity(null);
   // window.location.reload();
  };


  const handleCityChange = (city) => {

    if(city !=null)
    {
      const selected_city= cityList.filter(item => item.city_id ===city.value);
      localStorage.setItem("global_default_city_object", JSON.stringify(selected_city[0]));
      localStorage.setItem("global_search_city_option", JSON.stringify(city));
      setSelectedCity(city);
    }
    else
    {      
      localStorage.setItem("global_default_city_object", []);
      localStorage.setItem("global_search_city_option", null);
      setSelectedCity(null);
    }
   
    window.location.reload();
   //const pathname = window.location.pathname;
   //navigate(pathname);
  };

  useEffect(() => {

   if(localStorage.getItem("global_search_destination_type_option") != null)
   {
    const selectedDestinationType = JSON.parse(localStorage.getItem("global_search_destination_type_option"));
    setSelectedDestinationType(selectedDestinationType);
   }

   if(localStorage.getItem("global_search_destination_option") != null)
   {
    const selectedDestination = JSON.parse(localStorage.getItem("global_search_destination_option") ?? "[]");
    setSelectedDestination(selectedDestination);
   }

   if(localStorage.getItem("global_search_city_option") != null)
   {
    const selectedCity= JSON.parse(localStorage.getItem("global_search_city_option") ?? "[]");
    setSelectedCity(selectedCity);    
   }
   else if(localStorage.getItem("global_default_city_object") != null)
   {
      const selectedCity= JSON.parse(localStorage.getItem("global_default_city_object") ?? "[]");
      setSelectedCity({ label: selectedCity.city_name, value: selectedCity.city_id });   
   }



    // const selectedDestination = JSON.parse(
    //   localStorage.getItem(SELECT_GLOBAL_SEARCH_DESTINATION_VALUE_KEY) ?? "[]"
    // );
    // const selectedCity = JSON.parse(
    //   localStorage.getItem(SELECT_GLOBAL_SEARCH_CITY_VALUE_KEY) ?? "[]"
    // );

    

    // setSelectedDestination(selectedDestination);

    // setSelectedCity(selectedCity);
 
  }, []);

  const GetDestinationTypeListFunc = () => {
    /* Destination Types*/
    if (destinationTypeList?.length > 0) {

      let destinationTypeOptionTemp = [];
    
      destinationTypeList.map((item, index) => {
        // destinationTypeOptions.push({
        //   label: item.param_name,
        //   value: item.param_value,
        // });
        destinationTypeOptionTemp.push({
          label: item.destination_type_name,
          value: item.destination_type_id,
        });
      });

      setDestinationTypeOptions(destinationTypeOptionTemp); 
    }
    /************** Destination Types */  
};

  const GetDestinationListFunc = () => {
  
    /* Destinations for types*/
    let global_default_destination_type_object;
    let filteredDestinationList ;
    if(localStorage.getItem("global_default_destination_type_object") != null && localStorage.getItem("global_default_destination_type_object") != "")
    {
      global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
      filteredDestinationList = destinationList.filter(item => 
                                                          item.destination_type_id ===global_default_destination_type_object.destination_type_id
                                                          && 
                                                          (item.is_active== true)   
                                                        );
    }
    else{
      global_default_destination_type_object = 0;
      filteredDestinationList = destinationList;
    }

    if (filteredDestinationList?.length > 0) {
        
      let destinationOptionTemp = [];

      filteredDestinationList.map((item, index) => {
        destinationOptionTemp.push({
          label: item.destination_name,
          value: item.destination_id,
        });
      }); 

      setDestinationOptions(destinationOptionTemp);
     }       

    
     /************** Destinations for types */

};

const GetCityListFunc = () => {
 
  /* Cities for Destination Types and Destinations*/

  let global_default_destination_object;
  let filteredCityList ;
  if(localStorage.getItem("global_default_destination_object") != null)
  {
    global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
    filteredCityList = cityList.filter(item => 
           (item.destination_type_id ===global_default_destination_object.destination_type_id)
           && 
           (item.destination_id ===global_default_destination_object.destination_id)   
           && 
           (item.is_active== true)                                                  
         );
 
  // else{
  //   filteredCityList = cityList;
 //}
  setCityOptions([]);
  if (filteredCityList?.length > 0) {
   
    let cityOptionsTemp = [];

    filteredCityList.map((item, index) => {
      cityOptionsTemp.push({
          label: item.city_name,
          value: item.city_id,
        });
      });

      setCityOptions(cityOptionsTemp);
    }
  }
 
   /************** Cities for Destination Types and Destinations */

};


const getAllList = async () => {
  try {
 
     /* Destination Types*/
    if (destinationTypeList?.length > 0) {
     
      destinationTypeList.map((item, index) => {
        destinationTypeOptions.push({
          label: item.destination_type_name,
          value: item.destination_type_id,
        });
      });
    }    

    setDestinationTypeOptions(destinationTypeOptions);
    /************** Destination Types */

    /* Destinations for types (OLD) */

    
    // if(localStorage.getItem("global_default_destination_type_object") != "")
    // {

    //   const global_search_destination_type = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
    //   const filteredList = destinationList.filter(item => item.destination_type_id ===global_search_destination_type.destination_type_id);

    //   //params.append("destination_type_id", global_search_destination_type.destination_type_id);

    //   if (filteredList?.length > 0) {
        
    //     filteredList.map((item, index) => {
    //       destinationOptions.push({
    //         label: item.destination_name,
    //         value: item.destination_id,
    //       });
    //     }); 
    //    }        
    // }
    // else{
    //   const filteredList = destinationList;
    //   if (filteredList?.length > 0) {
        
    //     filteredList.map((item, index) => {
    //       destinationOptions.push({
    //         label: item.destination_name,
    //         value: item.destination_id,
    //       });
    //     }); 
    //    } 
    // }

    /* Destinations for types*/
    let global_default_destination_type_object;
    let filteredDestinationList ;
    if(localStorage.getItem("global_default_destination_type_object") != "")
    {
      global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
      filteredDestinationList = destinationList.filter(item => 
                                                          item.destination_type_id ===global_default_destination_type_object.destination_type_id
                                                      );
    }
    else{
      global_default_destination_type_object = 0;
      filteredDestinationList = destinationList;
    }

    if (filteredDestinationList?.length > 0) {
        
      filteredDestinationList.map((item, index) => {
        destinationOptions.push({
          label: item.destination_name,
          value: item.destination_id,
        });
      }); 
     }       

    setDestinationOptions(destinationOptions);
     /************** Destinations for types */


     /* Cities for Destination Types and Destinations*/

    let global_default_destination_object;
    let filteredCityList ;
    if(localStorage.getItem("global_default_destination_object") != "")
    {
      global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
      filteredCityList = cityList.filter(item => 
                                                  (item.destination_type_id ===global_default_destination_object.destination_type_id)
                                                  && 
                                                  (item.destination_id ===global_default_destination_object.destination_id)   
                                                  && 
                                                  (item.is_active== true)                                                  
                                                );
    }
    else{
      filteredCityList = cityList;
    }

    if (filteredCityList?.length > 0) {
     
      filteredCityList.map((item, index) => {
         cityOptions.push({
            label: item.city_name,
            value: item.city_id,
          });
        });
      }
   
    setCityOptions(cityOptions);
     /************** Cities for Destination Types and Destinations */
 
  } catch (error) {
    errornotify(error);
  }
};

  /**This useEffect for calling get api*/
  useEffect(() => {
     //getAllList();
     GetDestinationTypeListFunc();   
     GetDestinationListFunc();  
     GetCityListFunc(); 
    //  setDestinationOptions(null); 
    //  setSelectedDestination(null);
    //  setSelectedCity(null);


  }, []);
  
  return (
    <>
      <form className="app-search d-none d-md-block col-lg-12">
        <div className="position-relative col-lg-12">

        <Row>
            
            {(showDestinationTypeSelectList == "true" || showDestinationTypeSelectList == true )? (

               <Col lg={4} >
                <>
                    <Select 
                    id={"destinationType"}
                    name={"destinationType"}
                    isClearable={true}
                    value={selectedDestinationType} 
                    onChange={handleDestinationTypeChange} 
                    options={destinationTypeOptions}
                    // style={{width : "800px"}} 
                    placeholder = {"Destination Type..."}
                />
              </>

               
                    </Col>
                  ) :  <>  </>
                 }
                  
          
                  { (showDestinationSelectList  == "true" || showDestinationSelectList == true) ? (

                      <Col lg={4} >
                      <Select 
                          id={"destination"}
                          name={"destination"}
                          isClearable={true}
                          value={selectedDestination} 
                          onChange={handleDestinationChange} 
                          options={destinationOptions}
                          styles={{width : "300px"}} 
                          placeholder = {"Destination..."}
                      />
                      </Col>

                      ) :  <>  </>
                   }
                  
                    <Col lg={4}>
                        <Select 
                        id={"city"}
                        name={"city"}
                        isClearable={true}
                        value={selectedCity} 
                        onChange={handleCityChange} 
                        options={cityOptions}
                        styles={{width : "300px"}} 
                        placeholder = {"City..."}
                        />
                    </Col>
            </Row> 
      
       

          {/* <Select
                id={"destination"}
                name={"destination"}
              
                //isMulti={true}
                isClearable={true}
                // onChange={(e) => {
                //   onFilterUpdate(e);
                // }}
                onChange={(chooseOption) => {
                  selectOptionOnChange(chooseOption, "destination");
                }}
                options={destinationOptions}
               // value={defaultDestinationValue}
               // defaultValue={defaultDestinationOption}  
               value={parseInt(localStorage.getItem("global_search_destination_value"))}  
              defaultValue={JSON.stringify(localStorage.getItem("global_search_destination_option"))}  
               
             />        */}


          {/* <span className="mdi mdi-magnify search-widget-icon"></span>
          <span
            className="mdi mdi-close-circle search-widget-icon search-widget-icon-close d-none"
            id="search-close-options"
          ></span> */}
        </div>
        <div className="dropdown-menu dropdown-menu-lg" id="search-dropdown">
          <SimpleBar style={{ height: "320px" }}>
            <div className="dropdown-header">
              <h6 className="text-overflow text-muted mb-0 text-uppercase">
                Recent Searches
              </h6>
            </div>

            <div className="dropdown-item bg-transparent text-wrap">
              <Link
                to="/"
                className="btn btn-soft-secondary btn-sm btn-rounded"
              >
                how to setup <i className="mdi mdi-magnify ms-1"></i>
              </Link>
              <Link
                to="/"
                className="btn btn-soft-secondary btn-sm btn-rounded"
              >
                buttons <i className="mdi mdi-magnify ms-1"></i>
              </Link>
            </div>

            <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-1 text-uppercase">
                Pages
              </h6>
            </div>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-bubble-chart-line align-middle fs-18 text-muted me-2"></i>
              <span>Analytics Dashboard</span>
            </Link>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
              <span>Help Center</span>
            </Link>

            <Link to="#" className="dropdown-item notify-item">
              <i className="ri-user-settings-line align-middle fs-18 text-muted me-2"></i>
              <span>My account settings</span>
            </Link>

            <div className="dropdown-header mt-2">
              <h6 className="text-overflow text-muted mb-2 text-uppercase">
                Members
              </h6>
            </div>

            <div className="notification-list">
              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image2}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-1">
                    <h6 className="m-0">Angela Bernier</h6>
                    <span className="fs-11 mb-0 text-muted">Manager</span>
                  </div>
                </div>
              </Link>

              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image3}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-1">
                    <h6 className="m-0">David Grasso</h6>
                    <span className="fs-11 mb-0 text-muted">Web Designer</span>
                  </div>
                </div>
              </Link>

              <Link to="#" className="dropdown-item notify-item py-2">
                <div className="d-flex">
                  <img
                    src={image5}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="flex-1">
                    <h6 className="m-0">Mike Bunch</h6>
                    <span className="fs-11 mb-0 text-muted">
                      React Developer
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </SimpleBar>

          <div className="text-center pt-3 pb-1">
            <Link to="/pages-search-results" className="btn btn-primary btn-sm">
              View All Results <i className="ri-arrow-right-line ms-1"></i>
            </Link>
          </div>
        </div>
      </form>
    </>
  );
};

export default SearchOption;
