import React, { useEffect, useRef, useState } from "react";
import {Link, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Input, Label, Row, UncontrolledTooltip,} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import {  importTransportRateFileData, getImportTransportRateList, publishImportedTransportRate, cancelImportedTransportRate } from "../../../services/GlobalMasters/transportRateService";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import { Table } from "antd";
import LoadingButton from "../../../common/LoadingButton";
import {  errornotify,  successNotify} from "../../../components/Common/notification";
import UseAuth from "../../../components/UseAuth";
import TransportRate_template from '../../../assets/ImportTemplates/TransportRate_template.xlsx'
import CancelCofirmModal from "../../../common/CancelCofirmModal";

const ImportTransportRate = () => {
  const userDetailsTemp = UseAuth();

  const [selectedFiles, setselectedFiles] = useState(undefined);
  const [selectedFileName, setSelectedFileName] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [PublishLoader, setPublishLoader] = useState(false);
  const [userDetails, setuserDetails] = useState(JSON.parse(userDetailsTemp));
  const [value, setValue] = useState("");
  const ref = useRef();

  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  const [searchParams] = useSearchParams();

  const getImportTransportRateData = async () => {
    try {
      const response = await getImportTransportRateList();
      debugger;

      if (response?.data?.status === "SUCCESS") {
        setDataSource(response.data.data);
      } else {
        throw response?.data?.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for get data */
  useEffect(() => {
    debugger;
    getImportTransportRateData();
  }, []);

  const columns = [
    {
      title: "Destination Type",
      dataIndex: "destination_type_name",
      key: "destination_type_name",
    },
    {
      title: "Destination",
      dataIndex: "destination_name",
      key: "destination_name",
    },
    {
      title: "City",
      dataIndex: "city_name",
      key: "city_name",
    },   
    {
      title: "TransportRate name",
      dataIndex: "transportRate_name",
      key: "transportRate_name",
    },     
    
    {
      title: "Vehicle Name",
      dataIndex: "vehicle_name",
      key: "vehicle_name",
    },  
    {
      title: "Vehicle Rate",
      dataIndex: "vehicle_price",
      key: "vehicle_price",
    },  
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "True",
          value: true,
        },
        {
          text: "False",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            {record.is_active? "True": "False"}
          </div>
        );
      },
      // sorter: (a, b) => a.is_active - b.is_active,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 6,
      },
    },
    ,
    {
      title: "File Name",
      dataIndex: "file_name",
      key: "file_name",
    },
    {
      title: "File Temp Name",
      dataIndex: "file_temp_name",
      key: "file_temp_name",
    },     
   
    {
      title: "Imported On",
      width: 160,
      dataIndex: "file_imported_date",
     // hidden: file_imported_date,
      sorter: {
        compare: (a, b) => a.file_imported_date.localeCompare(b.file_imported_date),
        multiple: 3,
      },
      render: (text, record) => {
        const date = new Date(record.file_imported_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Imported By",
      dataIndex: "file_imported_by",
      width: 250,
      // sorter: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
      sorter: {
        compare: (a, b) => a.file_imported_by.localeCompare(b.file_imported_by),
        multiple: 4,
      },
     // hidden: file_imported_by,
    },
  ];

  /**This is on change handller */
  const onChageHandller = (e) => {
    setSelectedFileName(e.target.files[0].name);
    setselectedFiles(e.target.files[0]);
    setValue(e.target.value);
  };
  /***This is submitButtonHandller  import file*/
  const submitHandller = async () => {
    setUploadLoader(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFiles, selectedFileName);

      debugger;

      formData.append("User_id", userDetails.userName);
      formData.append("Module_name", "TransportRate");


      const response = await importTransportRateFileData(formData);
      if (response?.status === "SUCCESS") {
        successNotify(response?.message);
        /**This is for getting data */
        getImportTransportRateData();
        /**This is selection clear */
        setSelectedFileName(null);
        setselectedFiles(null);
        ref.current.value = "";
        setValue("");
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    } finally {
      setUploadLoader(false);
    }
  };

    /***This is submitButtonHandller  import file*/
    const publishHandler = async () => {
      setPublishLoader(true);
      try {

        debugger;
        
        const formData = new FormData();     
        formData.append("User_id", userDetails.userName);
       
        const response = await publishImportedTransportRate(formData);
        if (response?.status === "SUCCESS") {
          successNotify(response?.message);
          /**This is for getting data */
          getImportTransportRateData();
          /**This is selection clear */
          setSelectedFileName(null);
          setselectedFiles(null);
          ref.current.value = "";
          setValue("");
        } else {
          throw response?.message;
        }
      } catch (error) {
        errornotify(error);
      } finally {
        setPublishLoader(false);
      }
    };

  /**This is cancel button handller */
  const cancelButtonHandller = async () => {
    setSelectedFileName(null);
    setselectedFiles(null);
    ref.current.value = "";
    setValue("");
  };

  /**This is for Import Cancel handller*/
  const cancelHandller = async () => {   
    tog_standard();

    try {
      const formData = new FormData();     
      formData.append("User_id", userDetails.userName);
     
      const response = await cancelImportedTransportRate(formData);
      if (response?.status === "SUCCESS") {
        successNotify(response?.message);
        /**This is for getting data */
        getImportTransportRateData();
        /**This is selection clear */
        setSelectedFileName(null);
        setselectedFiles(null);
        ref.current.value = "";
        setValue("");
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    } finally {
      setPublishLoader(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>          
          <BreadCrumb
            title={'Import Transport Rate'}
            pageTitle="Setup"
            isSubTitle={true}
            parentPageLink={"transportRate"}
            parentPageTitle={"Transport Rate List"}
          />
          <Card>
            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">                 
           
                  <Col sm={4}>
                    <Input
                      className="form-control"
                      id="formSizeDefault"
                      type="file"
                      accept=".xlsx,.csv"
                      onChange={onChageHandller}
                      ref={ref}
                      value={value}
                    />
                  </Col>
                  <Col sm={5}>
                    {uploadLoader ? (
                      <LoadingButton
                        color={"primary"}
                        btnText={"Submit"}
                        externalClass={"add-btn"}
                      />
                    ) : (
                      <ButttonTravelNinjaz
                        backGroundColor="primary"
                        className="add-btn"
                        id="create-btn"
                        buttonText="Submit"
                        onCLickHancller={submitHandller}
                      />
                    )}
                    <ButttonTravelNinjaz
                      // backGroundColor="primary"
                      style={{ backgroundColor: "#CC563D", border: "none" }}
                      className="add-btn mx-2"
                      id="create-btn"
                      buttonText="Cancel"
                      type={"cancel"}
                      onCLickHancller={cancelButtonHandller}
                    />

                    <a
                        href={TransportRate_template}
                        download="TransportRate_template"
                        target="_blank"
                        rel="noreferrer"

                      >
                      Download template
                      </a>
                  </Col>
                  <Col sm={3}>

                  
                  {PublishLoader ? (
                      <LoadingButton
                        color={"primary"}
                        btnText={"Publish Cities"}
                        externalClass={"add-btn"}
                      />
                    ) : (
                      <ButttonTravelNinjaz
                        backGroundColor="primary"
                        className="add-btn btn btn-primary mx-0 "
                        id="publish-btn"
                        buttonText="Publish Transport Rates"
                        type={"cancel"}
                        onCLickHancller={publishHandler}
                        spin
                      />
                    )}

                  <ButttonTravelNinjaz
                      backGroundColor="danger"
                      className="add-btn btn me-1 mx-1 my-1"
                      id="cancel-import-btn"
                      onCLickHancller={tog_standard}
                      buttonText="Cancel"
                    />



                   
                  </Col>
                  {/* <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h4>
                            Drop Xlsx or Csv file here or click to upload.
                          </h4>
                        </div>
                      </div>
                    )}
                  </Dropzone> */}
                </Row>
              </div>
              {/* <Row className="my-2">
                <Col lg={12}> */}
              <div className="tableCustom">
                <Table
                  // rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  scroll={{
                    x: 1500,
                    y: 350,                    
                  }}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      10, 20, 50, 100,
                      // dataSource?.length,
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items  `,
                  }}
                  // onChange={onChange}
                  locale={{
                    triggerDesc: null,
                    triggerAsc: null,
                    cancelSort: null,
                  }}
                />
              </div>
              {/* </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Container>
      </div>

      {/**popconfirm modal*/}
      <CancelCofirmModal
        cancelHandller={cancelHandller}
        modal_standard={modal_standard}
        tog_standard={tog_standard}
      />
    </>
  );
};

export default ImportTransportRate;
