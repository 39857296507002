import { url } from "../common/const";
import { get, post, post_common, get_json } from "../common/https";


/**This service for get Inquiry list*/
// export const GetInquiryProcessList = () => {
//   const getdata = get(`${url}/api/InquiryProcess/list`);
//   return getdata;
// };


// export const GetInquiryProcessList = (data) => {
//   const getdata = get(`${url}/api/InquiryProcess/list?data=` + data);
//   return getdata;
// };

export const GetInquiryProcessList = (params) => {
  const getdata = get(`${url}/api/InquiryProcess/list?` + params);
  return getdata;
};


export const GetInquiryStatusList = (data) => {
  const getdata = get(`${url}/api/InquiryProcess/list`);
  return getdata;
};

export const SaveInquiryProcess = (data) => post(`${url}/api/InquiryProcess/Save`, data);

export const StarUnstarInquiryByInquiryNo = (data) => post(`${url}/api/InquiryProcess/StarUnstarInquiryByInquiryNo`, data);

