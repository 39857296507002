import React, {useState, useEffect} from "react";
import { Button,Card,CardBody,CardHeader,Col,Container,Row,Input,Modal,Label,ModalBody,UncontrolledDropdown,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,  Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip,  FormFeedback, 
    ModalHeader,Collapse
  } from "reactstrap";

import SimpleBar from 'simplebar-react';
import { Link,useNavigate } from "react-router-dom";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "../../common/LoadingButton";
import Cleave, { displayName } from "cleave.js/react";
import {dateFormatLocal} from '../../functions/utility/common'

//Dropdown Binding 
import {errornotify,successNotify, dismissNotify} from "../../components/Common/notification";
import { url } from "../../services/common/const";
import countryList from './Country.json';
import { GetConfigurationList } from "../../services/GlobalMasters/configurationServices";

import { getUsersList } from "../../services/User/userService";
import { GetDestinationList } from "../../services/GlobalMasters/destinationService";
import { GetDestinationDatesList } from "../../services/GlobalMasters/destinationDatesService";

import {  SaveInquiryProcess } from "../../services/Inquiry/inquiryProcessService";

const InquiryAddEditOffCanvas = ({
    inquiryDetails,
    isInquiryAddEditOffCanvasOpen,
    toggleInquiryAddEditOffCanvasOpen,
    fetchData
}) => {


    //Login

    const [userLogin, setUserLogin] = useState([]);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [passwordShow, setPasswordShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");
  const [configurationList, setConfigurationList] = useState([]);
  const [inquiryStatusOption, setInquiryStatusOption] = useState([]);
  const [inquiryProspectOption, setInquiryProspectOption] = useState([]);
  const [inquirySourceOption, setInquirySourceOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  
  const [userList, setUserList] = useState([]);
  const [userOption, setUserOption] = useState([]);


  const [destinationList, setDestinationList] = useState([]);
  const [destinationOption, setDestinationOption] = useState([]);

  const [destinationDatesList, setDestinationDatesList] = useState([]);
  const [destinationDatesOption, setDestinationDatesOption] = useState([]);

  const [showInvalid, setShowInvalid] = useState(false);
  const [loading, setIsloading] = useState(false);
  
  const [status_code, setStatus_code] = useState([]);
  const [inquiry, setInquiry] = useState([]);


//   const [selected_phone_country_code, setSelected_phone_country_code] = useState({
//     value: "+91",
//     label: "(+91) India"
//   });

  
//   const [selected_phone2_country_code, setSelected_phone2_country_code] = useState({
//     value: "+91",
//     label: "(+91) India"
//   });


  /**This function for get Inquiry list from database */
  const getConfigurationsListFunc = async () => {
    try {
        const result = await GetConfigurationList();
        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setConfigurationList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /**This function for get User list from database */
  const getUsersListFunc = async () => {
    try {
        const result = await getUsersList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setUserList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /*This function for get Destinations list from database */
  const getDestinationListFunc = async () => {
    try {
        const result = await GetDestinationList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setDestinationList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /*This function for get Destinations list from database */
  const getDestinationDatesListFunc = async () => {
    try {
        const result = await GetDestinationDatesList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setDestinationDatesList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  const setDefaultModalValues = async () => {
    try {

        const countryOption = countryList.filter(item => item.dialCode === inquiryDetails?.contact.phone_country_code);

        // inquiryStatusList.map((item, index) => {      
        //     inquiryStatusOptionTemp.push({
        //       label: item.param_name,
        //       value: item.param_value,
        //     });
        //   });

        let inquiryStatusOptionTemp = [];

        setModalValue(
            { 
                ...modalValue, 
                 selected_inquiry_date:  new Date(inquiryDetails?.inquiry_date),       
                 selected_last_call_date: new Date(inquiryDetails?.last_call),
                 selected_follow_up_date: new Date(inquiryDetails?.follow_up),
                selected_phone_country_code : {
                     value: countryOption[0]?.dialCode,
                     label: '(' + countryOption[0]?.dialCode + ') ' + countryOption[0]?.country,
                     //label : "(+93) Afghanistan"
                    },
                selected_destination_code : {          
                    label: inquiryDetails?.destination_name,
                    value: inquiryDetails?.destination_code,
                },     
                selected_travel_date_range:{
                    label: inquiryDetails?.travel_date_range,
                    value: inquiryDetails?.travel_date_range,
                },            
                selected_status_code:{
                    label: inquiryDetails?.status_code,
                    value: inquiryDetails?.status_code,
                },  
                selected_prospect_code:{
                    label: inquiryDetails?.prospect_code,
                    value: inquiryDetails?.prospect_code,
                },
                selected_source_code:{
                    label: inquiryDetails?.source_code,
                    value: inquiryDetails?.source_code,
                },
                selected_allocated_to_user:{
                    label: inquiryDetails?.allocated_to_user_full_nme,
                    value: inquiryDetails?.allocated_to_user,
                }  
            }
            );
 
        

    } catch (error) {
      errornotify(error);
    }
  };



  useEffect(() => {
    
    //alert(inquiryDetails.inquiry_date);

    setInquiry(inquiryDetails);

    getDestinationDatesListFunc();


   //All Configuration List 
   if (destinationDatesList?.length > 0) {

    //const destinationDatesList = configurationList.filter(item => item.param_group === "InquiryStatus");

    let destinationDatesOptionTemp = [];
    
    destinationDatesList.map((item, index) => {      
        destinationDatesOptionTemp.push({
          label: dateFormatLocal(item.from_date) + ' to ' + dateFormatLocal(item.to_date),
          value: dateFormatLocal(item.from_date) + ' to ' + dateFormatLocal(item.to_date),
        });
      });

      setDestinationDatesOption(destinationDatesOptionTemp);
   }
        // name=== "selected_phone_country_code" ||
        // name === "selected_phone2_country_code" ||
        // name === "selected_destination_code"  ||
        // name === "selected_status_code"       ||
        // name === "selected_prospect_code"    ||
        // name === "selected_source_code"      ||
        // name === "selected_allocated_to_user" ||
        // name === "selected_travel_date_range"
         
    

  }, [inquiryDetails]);

  useEffect(() => {   

      /**city get api call */
      //setInquiryStatusOption([]);
      //setInquiryProspectOption([]);

      setInquiry(inquiryDetails);


     //alert(inquiryDetails?.contact?.contact_id);


      getConfigurationsListFunc();           

      //All Configuration List 
      if (configurationList?.length > 0) {

        //Inquiry Status
        const inquiryStatusList = configurationList.filter(item => item.param_group === "InquiryStatus");

        let inquiryStatusOptionTemp = [];
        
        inquiryStatusList.map((item, index) => {      
            inquiryStatusOptionTemp.push({
              label: item.param_name,
              value: item.param_value,
            });
          });

          setInquiryStatusOption(inquiryStatusOptionTemp);
      

        //Prospect Code
        const inquiryPropspectList = configurationList.filter(item => item.param_group === "InquiryProspect");

        let inquiryPropspectOptionTemp = [];

        inquiryPropspectList.map((item, index) => {      
            inquiryPropspectOptionTemp.push({
              label: item.param_name,
              value: item.param_value,
            });
          });
           
        setInquiryProspectOption(inquiryPropspectOptionTemp);

         //Source Code
         const inquirySourceList = configurationList.filter(item => item.param_group === "InquirySource");

         let inquirySourceOptionTemp = [];
 
         inquirySourceList.map((item, index) => {      
            inquirySourceOptionTemp.push({
               label: item.param_name,
               value: item.param_value,
             });
           });
            
           setInquirySourceOption(inquirySourceOptionTemp);


      }         
        
       //All Country List
       if (countryList?.length > 0) {
        const countryOptionTemp = [];


        countryList.map((item, index) => {
            countryOptionTemp.push({
                value: item.dialCode,
                label: `(${item.dialCode}) ${item.country}`,
                });
        });
       
        setCountryOption(countryOptionTemp);   
        }

       //All User List
       getUsersListFunc();   

       if (userList?.length > 0) {
            //Inquiry Status
            //const userSellerList = userList.filter(item => item.param_group === "SLR");
    
            let userOptionTemp = [];
            
            userList.map((item, index) => {      
                userOptionTemp.push({
                  label: item.fullName,
                  value: item.userName,
                });
              });
    
              setUserOption(userOptionTemp);
        }

        //All Destination List
       getDestinationListFunc();   

       if (destinationList?.length > 0) {
            //Inquiry Status
            //const userSellerList = userList.filter(item => item.param_group === "SLR");
    
            let destinationOptionTemp = [];
            
                    
            destinationList.map((item, index) => {      
                destinationOptionTemp.push({
                  label: item.destination_name,
                  value: item.destination_code,
                });
              });
    
              setDestinationOption(destinationOptionTemp);
        }


        setDefaultModalValues();
       

  }, [inquiryDetails]);

    const [modalValue, setModalValue] = useState({
    selected_inquiry_date: "",
    selected_follow_up_date: "",
    selected_last_call_date: "",
    selected_follow_up_date : "",
    //selected_travel_date_range : "",
    selected_phone_country_code: {        
        label: "(+91) India",
        value: "+91",
    },
    selected_phone2_country_code: {
        label: "(+91) India",
        value: "+91",
    },
    selected_destination_code:{
        label: "",
        value: "",
      },
    selected_travel_date_range: {
        label: "",
        value: "",
    },
    selected_status_code:{
        label: "",
        value: "",
    },
    selected_prospect_code:{
        label: "",
        value: "",
    },
    selected_source_code:{
        label: "",
        value: "",
    },
    selected_allocated_to_user:{
        label: "",
        value: "",
    }      
  });




  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {  
      operation:   inquiryDetails?.operation,
      inquiry_no: inquiry?.inquiry_no || "",
    //   inquiry_date: new Date(inquiryDetails?.inquiry_date),
    inquiry_date: inquiryDetails?.inquiry_date || "" ,
      contact_id: inquiryDetails?.contact?.contact_id || 0,
      name:  inquiryDetails?.contact?.name || "", 
      email: inquiryDetails?.contact?.email || "",  

        phone_country_code: inquiryDetails?.contact?.phone_country_code || "",
        //phone_country_code:  modalValue?.selected_phone_country_code || "",

        phone: inquiryDetails?.contact?.phone || "",
        phone2_country_code: inquiryDetails?.contact?.phone2_country_code || "",
       // phone2_country_code: modalValue?.selected_phone2_country_code || "",
        phone2: inquiryDetails?.contact?.phone2 || "",
        

      status_code: inquiryDetails?.status_code || "",
      prospect_code: inquiryDetails?.prospect_code || "",
      source_code: inquiryDetails?.source_code || "",

      destination_code: inquiryDetails?.destination_code | "",
      is_fixed_departure: Boolean(inquiryDetails?.is_fixed_departure) || false,

      pax: inquiryDetails?.pax || 0,
      city: inquiryDetails?.city || "",
      expected_budget: inquiryDetails?.expected_budget || 0,
      //allocated_to_user: modalValue?.selected_allocated_to_user|| "", (Danger - This is making all other fields blank)

      allocated_to_user: inquiryDetails?.allocated_to_user|| "",
    //   last_call: new Date(inquiryDetails?.last_call) || "",
    //   follow_up: new Date(inquiryDetails?.follow_up) || "",

    last_call: inquiryDetails?.last_call || "",
    follow_up: inquiryDetails?.follow_up || "",

      travel_date_range: inquiryDetails?.travel_date_range || "",
      allocated_to_user: inquiryDetails?.allocated_to_user|| "",
    },
    validationSchema: Yup.object({
        //inquiry_date: Yup.string().required("Please Select Inquiry Date"),     
        name: Yup.string().required("Please Enter Name"), 
        //email: Yup.string().email().required("Please Enter Email"),
        //phone: Yup.string().required("Please Enter Primary Contact"), 
        //phone_country_code: Yup.string().required("Please Select Country Code")        
    }),
    onSubmit: (values) => {
        //alert(values);
        SaveInquiryProcessData(values);      
          
    },
  });

    /*This function for get Destinations list from database */
    const SaveInquiryProcessData = async (values) => {
        try {         
     

                const addInquiry = values;        
                
       
              
                //addInquiry.status_code = modalValue.selected_status_code.value;
                addInquiry.prospect_code = modalValue.selected_prospect_code.value;
                addInquiry.source_code = modalValue.selected_source_code.value;
               // addInquiry.destination_code = modalValue.selected_destination_code.value;
                addInquiry.phone_country_code = modalValue.selected_phone_country_code.value;
                addInquiry.allocated_to_user = modalValue.selected_allocated_to_user.value;
                addInquiry.inquiry_date = modalValue.selected_inquiry_date;
                addInquiry.last_call = modalValue.selected_last_call_date;
                addInquiry.follow_up = modalValue.selected_follow_up_date;
    
         
                let currentSystemUser = "manisha@aahvan.com";
                let selected_travel_date_range = modalValue.selected_travel_date_range.value;
                let travel_from_date = "";
                let travel_to_date = "";

                if(selected_travel_date_range !=null)
                {
                let arrDates = selected_travel_date_range?.split(' to ');  
                if(arrDates.length == 1)
                {
                    travel_from_date = arrDates[1];
                    travel_to_date = arrDates[1];
                }
                else if(arrDates.length == 2)
                {
                    //alert(arrDates[0] + " - " + arrDates[1]);
                    travel_from_date = arrDates[0];
                    travel_to_date = arrDates[1];
                }
            }
    
                  const newInquiry =  {
                    "operation": values?.operation,
                    "inquiry_id": 0,
                    "inquiry_no": values?.inquiry_no,
                    "inquiry_date": modalValue.selected_inquiry_date,
                    "destination_code": modalValue.selected_destination_code.value,                  
                    "status_code":  modalValue.selected_status_code.value,
                    "prospect_code":modalValue.selected_prospect_code.value,
                    "source_code": modalValue.selected_source_code.value,
                    "allocated_to_user": modalValue.selected_allocated_to_user.value,         
                    "allocated_date": new Date(),
                    "allocated_by_user": currentSystemUser,   //Current System User  
                    "contact": {
                        "contact_id": values?.contact_id,
                        "name": values?.name,
                        "phone_country_code": modalValue.selected_phone_country_code.value,
                        "phone": values?.phone,
                        //"phone2_country_code": "",
                        //"phone2": "",
                        "email":  values?.email,
                    },
                    "last_call":  modalValue.selected_last_call_date,
                    "follow_up":modalValue.selected_follow_up_date,               
                    "pax": parseInt(values?.pax) || 0,
                    "duration": "5",
                    "is_fixed_departure": values?.is_fixed_departure,
                    "travel_from_date": travel_from_date,
                    "travel_to_date": travel_to_date,
                    "travel_date_range": modalValue.selected_travel_date_range.value,                            
                    "city": values?.city,
                    "expected_budget":  values?.expected_budget !== 0 ? parseFloat(values?.expected_budget.toString().replaceAll(",", "")) : 0,           
                    "row_created_date":  new Date(),
                    "row_created_by": currentSystemUser,
                    "row_altered_date":  new Date(),
                    "row_altered_by": currentSystemUser,               
                    "is_active": true,               
                };
    
    
              setInquiry(newInquiry);
    
              // alert('Add Inquiry' + JSON.stringify(newInquiry));
    
               const response = await SaveInquiryProcess(newInquiry);
    
               //alert('response?.status ' + response?.status);
            
               //alert('response.errors' + JSON.stringify(response.errors));

               //dismissNotify();
               if (response?.status === "SUCCESS") {
                        successNotify(response?.message);

                        //Hide Off Canvas
                        toggleInquiryAddEditOffCanvasOpen();

                        //Refresh Datagrid
                        fetchData();
                } else 
                {
                    if(response?.errors)                    
                        errornotify(JSON.stringify(response.errors));
                    else
                        errornotify(response?.message);
                }
            // } 
              
            //   else {
            //     const updateInquiry = values;    
                
            //   }

              
    
          
        } catch (error) {
          errornotify(error);
        }
      };     


  const handleStatus = (sts) => {
    setStatus_code(sts);
  }

  const [isAltContactCoppaseOpen, setIsAltContactCoppaseOpen] = useState(false);

  const toggleAltContactCoppaseOpen = () => {
    setIsAltContactCoppaseOpen(!isAltContactCoppaseOpen);
  };

  const onChangeHandller = (e, name, isDateRangePicker ) => {  

    //Dropdown controls will have e == Option as selected option
    if (
        name=== "selected_phone_country_code" ||
        name === "selected_phone2_country_code" ||
        name === "selected_destination_code"  ||
        name === "selected_status_code"       ||
        name === "selected_prospect_code"    ||
        name === "selected_source_code"      ||
        name === "selected_allocated_to_user"         
    ) 
     {        
        setModalValue({ ...modalValue, [name] : e});
      } 
      //Date pickers
      else if (name === "selected_inquiry_date" ||
               name === "selected_last_call_date" ||
               name === "selected_follow_up_date"
      ) 
      {  
        if(e.length > 0)
        setModalValue({ ...modalValue, [name]: dateFormatLocal(e[0])});
      }
       else if (name === "selected_travel_date_range"
      ) {     

        if(isDateRangePicker == true)
        {
            if(e.length > 0)
                setModalValue({ ...modalValue, [name]: dateFormatLocal(e[0]) + " to " + dateFormatLocal(e[1]) });
        }
        else{
            setModalValue({ ...modalValue, [name]: e });
        }
       
      }
      //Check boxes
      else if (name === "is_fixed_departure") {
        setModalValue({ ...modalValue, [name]: e.target.checked });
      }  
    
        return false;

        //General 
    // setModalValue({ ...modalValue, [e.target.name]: e.target.value });
  };


    
/**Package Save*/
const modalSaveHandller = async () => {
    try {
      setShowInvalid(true);
    //   if (name?.length < 0) {
    //     throw "Package Name is required.";
    //   }

    } 
    catch (error) {
        errornotify(error);
    }
};   
  return ( 
        <>     
        {/* Enable body scrolling */}
        <Offcanvas
                isOpen={isInquiryAddEditOffCanvasOpen}
                scrollable
                direction="end"
                backdrop={true}
                toggle={toggleInquiryAddEditOffCanvasOpen}
                id="offcanvasScrolling" 
                className="offcanvas-end border-0"
            >              

                {/* New Coding Starts */}
                 <Row>
                        <Col xl={12}>

                        <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              //alert("before validation");
                              validation.handleSubmit();
                              //alert("after validation");
                              return false;
                            }}
                            action="#"
                          >
                           
                           <Card>
                                <OffcanvasHeader className="d-flex align-items-center bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={toggleInquiryAddEditOffCanvasOpen}>
                                     <span className="m-0 me-2 text-white"> {validation?.values?.operation == "ADD" ? "Add" : "Edit"} Inquiry</span>
                                </OffcanvasHeader> 

                                <CardBody>
                                    <div className="mx-n3">

                                        <SimpleBar style={{ maxHeight: "80vh" }}  className="px-3">
                                           
                                           
                                        <Row>
                                                <Col lg={8}>                      
                                                    <Card>
                                                        <CardHeader className="bg-light" >
                                                            <h5 className="card-title mb-0">Inquiry Details</h5>
                                                        </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="inquiry_no">Inquiry #</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id={"inquiry_no"}
                                                                        name={"inquiry_no"}     
                                                                        placeholder="Auto Generated"                                     
                                                                        value={validation?.values?.inquiry_no}                                            
                                                                        disabled
                                                                    />

                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="inquiry_date" >Inquiry Date</Label>
                                                                    <Input
                                                                        type="hidden"
                                                                        id="inquiry_date"     
                                                                        name="inquiry_date"
                                                                        className="form-control"                                                                                                                                               
                                                                        value={modalValue?.selected_inquiry_date}                                                                           
                                                                    />

                                                                    <Flatpickr                                                                        
                                                                        id="inquiry_date"
                                                                        name="inquiry_date"
                                                                        className="form-control"  
                                                                        placeholder="Select Inquiry Date"                                                                    
                                                                        onChange={(e) => onChangeHandller(e, "selected_inquiry_date")}     
                                                                        //onChange={onChangeDatePickerHandller}                                                          
                                                                        options={{
                                                                            //mode: "range",
                                                                            dateFormat: "d/M/Y",
                                                                            value: validation.values.inquiry_date,
                                                                            defaultDate: validation.values.inquiry_date  ,
                                                                        }}
                                                                         value={validation.values.inquiry_date}                                                                    
                                                                        /> 


                                                                    {validation.errors.inquiry_date && validation.touched.inquiry_date ? (
                                                                        <FormFeedback type="invalid">
                                                                        {validation.errors.inquiry_date}
                                                                        </FormFeedback>
                                                                    ) : null}                              
                                                                </div>
                                                            </Col>
                                                        </Row>                                                      

                                                        <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label  className="form-label" htmlFor="name">Name</Label>
                                                                
                                                                    <Input
                                                                    type="text"
                                                                    name="name"
                                                                    className="form-control"
                                                                    id="name"
                                                                    placeholder="Enter Name"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.name || ""}
                                                                    invalid={
                                                                    validation.touched.name &&
                                                                    validation.errors.name
                                                                        ? true
                                                                        : false
                                                                    }
                                                                />
                                                                {validation.touched.name &&
                                                                validation.errors.name ? (
                                                                    <FormFeedback type="invalid">
                                                                    {validation.errors.name}
                                                                    </FormFeedback>
                                                                ) : null}

                                                                    {/* <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        name={"name"}
                                                                        id={"name"}
                                                                        value={validation?.values?.name}    
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        // invalid={
                                                                        //     validation.touched.name
                                                                        // }
                                                                        invalid={validation?.values?.name?.length < 1 && showInvalid}
                                                                        placeholder="Enter Name" 
                                                                        //required
                                                                        autoComplete="off"
                                                                    />

                                                                        {validation?.values?.name?.length < 1? (
                                                                           <p
                                                                           style={{
                                                                             fontSize: "12px",
                                                                             color: "red",
                                                                           }}
                                                                         >
                                                                            {validation.errors.name}
                                                                         </p>                                                                        
                                                                    ) : null} */}

                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>

                                                            <div className="mb-3">
                                                                <Label htmlFor="email" className="form-label">
                                                                    Email
                                                                </Label>
                                                                <Input
                                                                    type="email"
                                                                    name="email"
                                                                    className="form-control"
                                                                    id="email"
                                                                    placeholder="Enter Email"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.email || ""}
                                                                    invalid={
                                                                    validation.touched.email &&
                                                                    validation.errors.email
                                                                        ? true
                                                                        : false
                                                                    }
                                                                />
                                                                {validation.touched.email &&
                                                                validation.errors.email ? (
                                                                    <FormFeedback type="invalid">
                                                                    {validation.errors.email}
                                                                    </FormFeedback>
                                                                ) : null}
                                                                </div>

                                                                {/*
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="email">Email</Label>
                                                                    <Input
                                                                            type="email"
                                                                            className="form-control"
                                                                            id="email"
                                                                            name="email"                                            
                                                                            placeholder="Enter Email"
                                                                            //value={validation?.values?.email || ""}
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}                                           
                                                                            // invalid={
                                                                            //     validation.touched.email &&
                                                                            //     validation.errors.email
                                                                            //         ? true
                                                                            //         : false
                                                                            // }
                                                                            invalid={validation?.values?.email?.length < 1 && showInvalid}
                                                                           // required
                                                                            autoComplete="off"
                                                                        />

                                                                        {validation?.values?.email?.length < 1? (
                                                                           <p
                                                                           style={{
                                                                             fontSize: "12px",
                                                                             color: "red",
                                                                           }}
                                                                         >
                                                                            {validation.errors.email}
                                                                         </p>                                                                        
                                                                    ) : null}


                                                                      {/* {validation.errors.email ? (
                                                                        <FormFeedback type="invalid">
                                                                        {validation.errors.email}
                                                                        </FormFeedback>
                                                                        ) : null} */}

                                                                {/* </div> */}

                                                               
                                                            </Col>
                                                        </Row>



                                                       

                                                        <Row className="row g-3 align-items-center mb-3">                             
                                                            
                                                                <Col>         
                                                              

                                                                    <div className="mb-3 position-relative">
                                                                        <div className="hstack gap-3">
                                                                            <div className="px-2">
                                                                                <Label className="form-label" htmlFor="phone_country_code">Primary Contact</Label>
                                                                            </div>                                
                                                                            <div className="ms-auto">                                          
                                                                                <i className="ri-more-fill me-1 align-bottom" onClick={toggleAltContactCoppaseOpen} color="primary" style={{ cursor: "pointer" }}></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="input-group">
                                                                            <div className="w-50 w-sm-100" style={{display: "inline"}}> 
                                                                            <Select
                                                                                          className="js-example-templating mb-0 border-end-0 rounded-start w-100"
                                                                                        id="phone_country_code"
                                                                                        name="phone_country_code"
                                                                                        placeholder="Select Country"  
                                                                                        options={countryOption}               
                                                                                        onBlur={validation.handleBlur} 
                                                                                        value={modalValue.selected_phone_country_code}  
                                                                                        onChange={(e) => onChangeHandller(e, "selected_phone_country_code")}   
                                                                                        style={{radius: "0"}} 
                                                                                    />                                                                                                                                                
                                                                                    
                                                                                    {modalValue.selected_phone_country_code &&
                                                                                        modalValue.selected_phone_country_code.length < 1 ? (
                                                                                            <FormFeedback type="invalid">
                                                                                            {validation.errors.phone_country_code}
                                                                                            </FormFeedback>
                                                                                        ) : null}                                                                        
                                                                            </div>
                                                                      
                                  
                                                                            <div className="" style={{display: "inline", width: "50%"}}> 

                                                                                <div className="input-group">
                                                                                    <Input
                                                                                        type="text"
                                                                                        id="phone"
                                                                                        name="phone"
                                                                                        className="form-control rounded-0"                                                                   
                                                                                        placeholder="Enter Primary Contact"
                                                                                        onChange={validation.handleChange}
                                                                                        onBlur={validation.handleBlur}
                                                                                        value={validation.values.phone || ""}
                                                                                        invalid={
                                                                                        validation.touched.phone &&
                                                                                        validation.errors.phone
                                                                                            ? true
                                                                                            : false
                                                                                        }
                                                                                    />   
                                                                                      <span className="input-group-text" id="basic-addon1">  <i className="ri-whatsapp-fill text-success fs-6"></i>
                                                                                      </span>                                                 

                                                                                        {validation.touched.phone &&
                                                                                            validation.errors.phone ? (
                                                                                                <FormFeedback type="invalid">
                                                                                                    {validation.errors.phone}
                                                                                                </FormFeedback>
                                                                                            ) : null}

                                                                            
                                                                                  
                                                                            
                                                                                </div>
                                                                                    
                                                                        </div>
                                                                        </div>
                                                                    </div>

                                                                </Col>                            
                                                        
                                                        </Row>

                                                        <Row className="row g-3 align-items-center mb-3">
                                                        <Collapse className="w-100" isOpen={isAltContactCoppaseOpen} id="collapseWidthExample" horizontal>
                                                        
                                                            <Col>                                 

                                                                <div className="hstack gap-3">
                                                                    <div className="px-2">
                                                                            <Label className="form-label" htmlFor="phone2">Alt Contact</Label>

                                                                    </div>                                
                                                                    <div className="ms-auto">
                                                                    </div>
                                                                </div>

                                                                <div className="input-group">
                                                                    <div className="w-50 w-sm-100"> 
                                                                    <Select
                                                                        className="js-example-templating mb-0 border-end-0 rounded-start w-100"
                                                                        id="phone2_country_code"
                                                                        name="phone2_country_code"
                                                                        placeholder="Select Country" 
                                                                        onBlur={validation.handleBlur}                                          
                                                                        options={countryOption}                                                 
                                                                        value={modalValue?.selected_phone2_country_code}       
                                                                        // onChange={(selectedOption) => {
                                                                        //     selectOptionHandller(selectedOption);
                                                                        //   }}     
                                                                        onChange={(e) => onChangeHandller(e, "selected_phone2_country_code")}                              
                                                                        // formatOptionLabel={option => (
                                                                        //     <div> 
                                                                        //         <span>({option?.dialCode}) {option?.country}</span>
                                                                        //     </div>
                                                                        // )}
                                                                    />
                                                                    </div>
                                                                    <Input
                                                                            type="text"
                                                                            className="form-control"
                                                                            id={"phone2"}
                                                                            name={"phone2"}
                                                                            placeholder="Enter Alt Contact" 
                                                                            value={validation?.values?.phone2}    
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}                                                                                         
                                                                        />

                                                                    <span className="input-group-text" id="basic-addon1">  <i className="ri-whatsapp-fill text-success fs-6"></i>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                    
                                                    </Collapse>
                                                    </Row>                                                                            
                                                    </CardBody>
                                                    </Card>
                                                {/* </div> */}

                                            
                                                <Card>
                                                    <CardHeader className="bg-light" >
                                                        <h5 className="card-title mb-0">Destination Details</h5>
                                                    </CardHeader>
                                                    <CardBody>
                                                        
                                                    <Row>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="destination_code">Destination</Label>    
                                                                        <Select                                           
                                                                            className="js-example-basic-single mb-0"
                                                                            id="destination_code"
                                                                            name="destination_code"
                                                                            placeholder="Select Destination"
                                                                            value={modalValue?.selected_destination_code?.value}  
                                                                            onChange={(e) => onChangeHandller(e, "selected_destination_code")}
                                                                            //onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            options={destinationOption}                                            
                                                                            data-choices 
                                                                            data-choices-search-false
                                                                        

                                                                    /> 
                                                                </div>
                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="expected_budget">Expected Budget</Label>
                                                                        <Cleave
                                                                            className="form-control"
                                                                            id="expected_budget"
                                                                            name="expected_budget"
                                                                            placeholder="Enter Expected Budget"
                                                                            value={validation?.values?.expected_budget}  
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            options={{
                                                                                numeral: true,
                                                                                numeralThousandsGroupStyle: 'thousand'
                                                                            }}
                                                                        />                        
                                                                </div>                                    
                                                            </Col>
                                                        </Row>    

                                                        <Row>
                                                            <Col lg={6}>
                                                                    {/* <div className="mb-3">
                                                                        <Label className="form-check-label" htmlFor="formCheck6">
                                                                            Fixed Departure?
                                                                        </Label>
                                                                        <Input className="form-check-input" type="checkbox" id="formCheck6" defaultChecked />

                                                                    </div> */}

                                                                    {/* <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                                                                        <Label className="form-check-label" for="customSwitchsizemd">Medium Size Switch</Label>
                                                                        <Input type="checkbox" className="form-check-input" id="customSwitchsizemd"/>
                                                                    
                                                                    </div> */}

                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="is_fixed_departure">Fixed Departure?                                                                   
                                                                    </Label>
                                                                    <div className="form-check form-switch form-switch-md mb-3" dir="ltr">
                                                                        <Input 
                                                                            type="checkbox" 
                                                                            className="form-check-input" 
                                                                            id="is_fixed_departure"
                                                                            name="is_fixed_departure"
                                                                            value={validation?.values?.is_fixed_departure}  
                                                                            checked = {validation?.values?.is_fixed_departure}  
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                        />                 
                                                                    </div>
                                                                </div>

                                                            </Col>
                                                            <Col lg={6}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="travel_from_date">Travel Dates</Label>

                                                                    {
                                                                        validation.values.is_fixed_departure ? (
                                                                            <Select
                                                                                className="js-example-basic-single mb-0"
                                                                                id="travel_date_range"    
                                                                                name="travel_date_range"
                                                                                placeholder="Select Travel Dates"                                                                               
                                                                                options={destinationDatesOption}  
                                                                                //value={validation?.values?.travel_date_range}    
                                                                                //onChange={validation.handleChange}
                                                                                value={modalValue?.selected_travel_date_range}                         
                                                                                onChange={(e) => onChangeHandller(e, "selected_travel_date_range", false)} 
                                                                                onBlur={validation.handleBlur}                                                 
                                                                            /> 
                                                                        ) :     
                                                                        
                                                                        <>
                                                                        {/* <Input
                                                                            type="text"
                                                                            id="travel_date_range"           
                                                                            name="travel_date_range"
                                                                            className="form-control"                                                                                                                                          
                                                                            value={modalValue?.selected_travel_date_range}                                                                           
                                                                        />

                                                                        <Flatpickr                                                                          
                                                                            id="travel_date_range"    
                                                                            name="travel_date_range"
                                                                            className="form-control"                                                                          placeholder="Select Travel Dates" 
                                                                            options={{
                                                                                mode: "range",
                                                                                dateFormat: "d/M/Y",
                                                                                defaultDate: validation.values.travel_date_range  ,
                                                                                }}                                               
                                                                            //value={validation?.values?.travel_date_range}   
                                                                            //onClick={(e) => onChangeHandller(e, "selected_travel_date_range")} 
                                                                            onChange={(e) => onChangeHandller(e, "selected_travel_date_range")} 
                                                                            onBlur={validation.handleBlur}           
                                                                        />   */}

                                                                        <Input
                                                                            type="hidden"
                                                                            id="travel_date_range"     
                                                                            name="travel_date_range"
                                                                            className="form-control"                                                                                                                                               
                                                                            value={modalValue?.selected_travel_date_range}                                                                           
                                                                        />

                                                                    <Flatpickr                                                                        
                                                                        id="travel_date_range"
                                                                        name="travel_date_range"
                                                                        className="form-control"  
                                                                        placeholder="Select Travel Dates"                                                                    
                                                                        onChange={(e) => onChangeHandller(e, "selected_travel_date_range", true)}     
                                                                        //onChange={onChangeDatePickerHandller}                                                          
                                                                        options={{
                                                                            mode: "range",
                                                                            dateFormat: "d/M/Y",
                                                                            defaultDate: validation?.values?.travel_date_range  ,
                                                                        }}
                                                                        //value={modalValue?.selected_inquiry_date}                                                                    
                                                                        /> 


                                                                       
                                                                        </>

                                                                    //     <Input
                                                                    //     type="text"
                                                                    //      id="travel_date_range"
                                                                    //     name="travel_date_range"
                                                                    //     className="form-control"                                                                       
                                                                    //     placeholder="Enter travel_date_range"
                                                                    //     onChange={validation.handleChange}
                                                                    //     onBlur={validation.handleBlur}
                                                                    //     value={validation.values.travel_date_range || ""}
                                                                    //     invalid={
                                                                    //     validation.touched.travel_date_range &&
                                                                    //     validation.errors.travel_date_range
                                                                    //         ? true
                                                                    //         : false
                                                                    //     }
                                                                    // />

                                                                        
                                                                        }


                                                                                            
                                                                </div>
                                                            </Col>
                                                        </Row>                     
                                                        <Row>
                                                            <Col lg={6}>
                                                            <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="pax">Pax</Label>
                                                                    <Cleave
                                                                            className="form-control"
                                                                            id="pax" 
                                                                            name="pax" 
                                                                            placeholder="Enter Pax"
                                                                            max={1000}
                                                                            maxLength={3}
                                                                            options={{
                                                                                numeral: true,
                                                                            }}
                                                                            value={validation?.values?.pax}  
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}  
                                                                        /> 
                                                                </div>
                                                                
                                                            </Col>
                                                            <Col lg={6}>                                   
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="city">City</Label>
                                                                    <Input 
                                                                        type="text"
                                                                        className="form-control"  
                                                                        id="city" 
                                                                        name="city" 
                                                                        placeholder="Enter city"
                                                                        value={validation?.values?.city}   
                                                                        onChange={validation.handleChange} 
                                                                        onBlur={validation.handleBlur}
                                                                    />  
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>                  
                                            </Col>

                                            <Col lg={4}>
                                                    <Card>
                                                        <CardHeader className="bg-light" >
                                                            <h5 className="card-title mb-0">Status Details</h5>
                                                        </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="status_code">Status</Label>                                       
                                                                
                                                                    <Select
                                                                            className="js-example-basic-single mb-0"
                                                                            id="status_code"
                                                                            name="status_code"
                                                                            placeholder="Select Status"
                                                                            options={inquiryStatusOption}   
                                                                            value={modalValue?.selected_status_code}                                                                            
                                                                            onChange={(e) => onChangeHandller(e, "selected_status_code")} 
                                                                            onBlur={validation.handleBlur}                                               
                                                                        /> 


                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="prospect_code">Prospect</Label>
                                                                    <Select
                                                                            className="js-example-basic-single mb-0"
                                                                            id="prospect_code"
                                                                            name="prospect_code"
                                                                            placeholder="Select Prospect"                                                   
                                                                            options={inquiryProspectOption} 
                                                                            value={modalValue?.selected_prospect_code}   
                                                                            onChange={(e) => onChangeHandller(e, "selected_prospect_code")} 
                                                                            onBlur={validation.handleBlur}
                                                                        /> 
                                                
                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="source_code">Source</Label>
                                                                    
                                                                        <Select
                                                                            className="js-example-basic-single mb-0"
                                                                            id="source_code"   
                                                                            name="source_code" 
                                                                            placeholder="Select Source"                                                   
                                                                            options={inquirySourceOption}
                                                                            value={modalValue?.selected_source_code}   
                                                                            onChange={(e) => onChangeHandller(e, "selected_source_code")} 
                                                                            onBlur={validation.handleBlur}  
                                                                        />                    
                                                                </div>
                                                            </Col>
                                                        </Row>                                                                                                      
                                                        </CardBody>
                                                    </Card>

                                                    <Card>
                                                        <CardHeader className="bg-light" >
                                                            <h5 className="card-title mb-0">Follow Up</h5>
                                                        </CardHeader>
                                                    <CardBody>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                <Label htmlFor="last_call" className="form-label">Last Call</Label>
                                                                    {/* <Flatpickr
                                                                        className="form-control"
                                                                        id="last_call"
                                                                        name="last_call"
                                                                        placeholder="Select Last Call"
                                                                        options={{
                                                                            dateFormat: "d/M/Y"
                                                                        }}
                                                                        value={validation?.values?.last_call}  
                                                                        onClick={validation.handleChange} 
                                                                        onBlur={validation.handleBlur}  
                                                                    
                                                                        />     */}

                                                                           {/* <Input
                                                                        type="text"
                                                                         id="last_call"
                                                                        name="last_call"
                                                                        className="form-control"                                                                       
                                                                        placeholder="Enter last_call"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.last_call || ""}                                                                      
                                                                    /> */}

                                                                        <Input
                                                                            type="hidden"
                                                                            id="last_call"     
                                                                            name="last_call"
                                                                            className="form-control"                                                                                                                                            
                                                                            value={modalValue?.selected_last_call_date}                                                                           
                                                                        />                                                                   

                                                                    <Flatpickr
                                                                        className="form-control"  
                                                                        id="last_call"
                                                                        name="last_call"
                                                                        placeholder="Select Last Call"                                                                    
                                                                        onChange={(e) => onChangeHandller(e, "selected_last_call_date")}  
                                                                        options={{
                                                                            //mode: "range",
                                                                            dateFormat: "d/M/Y",
                                                                            defaultDate: validation.values.last_call  ,
                                                                            value: validation.values.last_call,
                                                                            defaultDate: validation.values.last_call  ,
                                                                        }}
                                                                        value={validation.values.last_call}  
                                                                        /> 
                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="follow_up" className="form-label">Follow Up</Label>
                                                                    {/* <Flatpickr
                                                                        className="form-control"
                                                                        id="follow_up"
                                                                        name="follow_up"
                                                                        placeholder="Select Follow Up"
                                                                        options={{
                                                                            dateFormat: "d/M/Y"
                                                                        }}
                                                                        value={validation?.values?.follow_up}  
                                                                        onClick={validation.handleChange} 
                                                                        onBlur={validation.handleBlur}  
                                                                    />      */}
{/* 
                                                                    <Input
                                                                        type="text"
                                                                         id="follow_up"
                                                                        name="follow_up"
                                                                        className="form-control"                                                                       
                                                                        placeholder="Enter follow_up"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.follow_up || ""}                                                                      
                                                                    /> */}

                                                                        <Input
                                                                            type="hidden"
                                                                            id="follow_up"     
                                                                            name="follow_up"
                                                                            className="form-control"                                                                                                                                            
                                                                            value={modalValue?.selected_follow_up_date}                                                                           
                                                                        />                                  

                                                                    <Flatpickr
                                                                        className="form-control"  
                                                                        id="follow_up"
                                                                        name="follow_up"
                                                                        placeholder="Select Last Call"                                                                    
                                                                        onChange={(e) => onChangeHandller(e, "selected_follow_up_date")}  
                                                                        options={{
                                                                            //mode: "range",
                                                                            dateFormat: "d/M/Y",
                                                                            value: validation.values.follow_up,
                                                                            defaultDate: validation.values.follow_up  ,
                                                                        }}
                                                                        value={validation.values.follow_up}    
                                                                        /> 
                                                                        

                                                
                                                                </div>
                                                            </Col>    
                                                            <Col lg={12}>
                                                                <div className="mb-3">

                                                                    <Label htmlFor="allocated_to_user" className="form-label">Assign to Seller</Label>                                       

                                                                    {/* <Select
                                                                            className="js-example-basic-single mb-0"
                                                                            id="status_code"
                                                                            name="status_code"
                                                                            placeholder="Select Status"
                                                                            options={inquiryStatusOption}   
                                                                            value={modalValue?.selected_status_code}                                                                            
                                                                            onChange={(e) => onChangeHandller(e, "selected_status_code")} 
                                                                            onBlur={validation.handleBlur}                                               
                                                                        /> 
 */}

                                                                    <Select
                                                                        className="js-example-basic-single mb-0"
                                                                        id="allocated_to_user"
                                                                        name="allocated_to_user"
                                                                        placeholder="Select Assign to Seller"
                                                                        options={userOption} 
                                                                        value={modalValue?.selected_allocated_to_user}   
                                                                        onChange={(e) => onChangeHandller(e, "selected_allocated_to_user")} 
                                                                        onBlur={validation.handleBlur}       
                                                                    /> 

                                            

                                                                </div>
                                                            </Col>                           
                                                        </Row>                                                                                                      
                                                        </CardBody>
                                                    </Card>               
                                            </Col>
                                        </Row>   

                                        </SimpleBar>
                                    </div>
                                </CardBody>

                                <OffcanvasHeader className="mb-0  justify-content-center text-center bg-light bg-gradient p-2 offcanvas-header-dark border-top">
                                
                                        <div className="hstack m-auto gap-3"> 
                                
                                                {/* <Link to="#" className="link-success">View All Acitivity <i className="ri-arrow-right-s-line align-middle ms-1"></i></Link> */}

                                                {loading ? (
                                                    <LoadingButton
                                                    btnText={"Update"}
                                                    externalClass={"btn btn-primary"}
                                                    color={"primary"}
                                                    />
                                                ) : (
                                                    <button type="submit" className="btn btn-success">
                                                    Save
                                                    </button>
                                                )}

                                                <button
                                                    type="reset"
                                                    onClick={toggleInquiryAddEditOffCanvasOpen}
                                                    className="btn btn-soft-light text-dark border"
                                                >
                                                    Cancel
                                                </button>
                            
                            
                                        </div>
                                 
                                </OffcanvasHeader>                                
                            </Card>

                          </form>
                        </Col>

                 </Row> 
                 {/* New Coding Ends */}


        </Offcanvas>
        </>            
  );
};

export default InquiryAddEditOffCanvas;
