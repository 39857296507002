import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';
import './pagination.scss';


import { Col,Row} from "reactstrap";
import { Link } from "react-router-dom";


const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,   
    pageSize,
    siblingCount,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  const firstPageIndex = (currentPage - 1) * pageSize;
  const lastPageIndex = firstPageIndex + pageSize;

  return (
    <>  

    {/* <h1>  {'totalCount-' + totalCount} </h1>  
    <h1>  {'siblingCount-' +  siblingCount} </h1>  
    <h1>  {'currentPage-' +  currentPage} </h1>  
    <h1>   {'pageSize-' +  pageSize} </h1>  
     */}
    {/* <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li key={-1}
        className={classnames('pagination-item', {
          disabled: currentPage === 1
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>
      {paginationRange.map(pageNumber => {
        if (pageNumber === DOTS) {
          return <li className="pagination-item dots">&#8230;</li>;
        }

        return (
          <li
             key={pageNumber}
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage
            })}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </li>
        );
      })}
      <li
        key={1000}
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage
        })}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>    */}
    <Row className="g-0 text-center text-sm-start align-items-center mb-4">
        <Col sm={6}>
            <div>
                <p className="mb-sm-0 text-muted">Showing <span className="fw-semibold">{firstPageIndex + 1}</span> to <span className="fw-semibold">{lastPageIndex}</span> of <span className="fw-semibold text-decoration-underline">{totalCount}</span> entries</p>
            </div>
        </Col>

        <Col sm={6}>
            <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                <li key={-1} className="page-item disabled">
                    <Link to="#" className="page-link"  onClick={onPrevious}>Previous</Link>
                </li>

                {paginationRange.map(pageNumber => {
                    if (pageNumber === DOTS) {
                      return <li className="pagination-item dots">&#8230;</li>;
                    }

                    return (
                      <li   key={pageNumber}                       
                        className={classnames('page-item', {
                          active: pageNumber === currentPage
                        })}
                      >
                      <Link to="#" className="page-link"
                          onClick={() => onPageChange(pageNumber)}
                          >{pageNumber}</Link>                       
                      </li>
                    );
                  })}            
           
              
                <li key={1000} 
                    className={classnames('page-item', {
                    disabled: currentPage === lastPage
                  })}>
                    <Link to="#" className="page-link" 
                      onClick={onNext}>Next</Link>
                </li>
            </ul>
        </Col>
    </Row>
  </>
  );
};

export default Pagination;