import React, { useState } from "react";
import { appName } from "../../common/applicationName";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import progileBg from "../../assets/images/profile-bg.jpg";
import avatar1 from "../../assets/images/male1.png";
import classnames from "classnames";
import PersonalDetails from "./PersonalDetails";
import CompanyDetails from "./CompanyDetails";
import { useEffect } from "react";
import {
  getUserAndCompany,
  saveIamgeProfile,
  updateUser,
} from "../../services/User/userService";
import { getSessionUsingSessionStorage, setSession } from "../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import { ToastContainer } from "react-toastify";
import { url } from "../../services/common/const";
import UseAuth from "../../components/UseAuth";
const ProfileSettings = () => {
  UseAuth();
  document.title = `${appName}-Profile`;
  const [activeTab, setActiveTab] = useState("1");
  const [userAllDetails, setUserAlldetails] = useState(undefined);
  const [userDetails, setUserDetails] = useState(undefined);
  const promise = getSessionUsingSessionStorage();
  useEffect(() => {
    /**check authentication */
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserDetails(value);
        return value;
      });
  }, []);
  const tabChange = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const getuserAndCompanyDetails = async () => {
    try {
      if (userDetails) {
        debugger;
        let params = new URLSearchParams();
        params.append("AspNetUserId", userDetails.userKey);
        const response = await getUserAndCompany(params);
        if (response?.data?.status === "SUCCESS") {
          //debugger;
          setUserAlldetails(response.data.data);
        } else {
          throw response.message;
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  useEffect(() => {
    getuserAndCompanyDetails();
  }, [userDetails]);

  /**onchane handller */
  const onChangeHandller = async (e) => {
    try {
      e.preventDefault();
      const formData = new FormData();
      let profileImageName = e.target.files[0].name;
      formData.append("User_id", userAllDetails?.userId);
      formData.append("file", e.target.files[0], profileImageName);
      const response = await saveIamgeProfile(formData);
      if (response?.status === "SUCCESS") {
        const response = await updateUser({
          FirstName: userAllDetails.firstName,
          LastName: userAllDetails.lastName,
          Phone: userAllDetails.phone,
          Email: userDetails.email,
          Operation: "UPDATE",
          Profession: userAllDetails.profession,
          City: userAllDetails.city,
          State: userAllDetails.state,
          Country: userAllDetails.country,
          Zipcode: userAllDetails.zipcode,
          ProfileImage: profileImageName,
          UserId: userAllDetails.userId,
          AspNetUserId: userAllDetails.aspNetUserId,
        });
        if (response?.status === "SUCCESS") {

          setSession("userDetails", {
            ...userDetails,   
            profileImage: profileImageName  
          });

          successNotify("Profile Images uploaded successfully!");
          getuserAndCompanyDetails();
          window.location.reload();
        } else {
          throw response.message;
        }
      } else {
        throw response.message;
      }
    } catch (error) {
      errornotify("Something went error profile image upload!");
    }
  };
  return (
    <>
      <ToastContainer />
      {/* <div className="page-content customStyleProfilePage"> */}
      <div className="page-content">
        <Container fluid>
          <div className="position-relative">
            <div className="profile-wid-bg profile-setting-img">
              <img src={progileBg} className="profile-wid-img" alt="" />
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <img
                        src={
                          userAllDetails?.profileImage
                            ? `${url}/Images/ClientProfileImage/${userAllDetails.userId}/${userAllDetails?.profileImage}`
                            : avatar1
                        }
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                      <div className="avatar-xs p-0 rounded-circle profile-photo-edit">
                        <Input
                          id="profile-img-file-input"
                          type="file"
                          accept="image/*"
                          className="profile-img-file-input"
                          onChange={onChangeHandller}
                        />
                        <Label
                          htmlFor="profile-img-file-input"
                          className="profile-photo-edit avatar-xs"
                        >
                          <span className="avatar-title rounded-circle bg-light text-body">
                            <i className="ri-camera-fill"></i>
                          </span>
                        </Label>
                      </div>
                    </div>
                    <h5 className="fs-16 mb-1">
                      {userAllDetails?.firstName} {userAllDetails?.lastName}
                    </h5>
                    {userAllDetails?.profession ? (
                      <p className="text-muted mb-0">
                        {userAllDetails?.profession}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xxl={9}>
              <Card className="mt-xxl-n5">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          tabChange("1");
                        }}
                      >
                        <i className="fas fa-home"></i>
                        Personal Details
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        to="#"
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          tabChange("2");
                        }}
                        type="button"
                      >
                        <i className="far fa-user"></i>
                        Company Details
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <PersonalDetails
                        userAlldetails={userAllDetails}
                        getuserAndCompanyDetails={getuserAndCompanyDetails}
                      />
                    </TabPane>
                    <TabPane tabId="2">
                      <CompanyDetails
                        companydetails={userAllDetails?.usercompany}
                      />
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProfileSettings;
