import React, { useState, useCallback, useMemo, useEffect , useRef} from "react";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import { Button,Card,CardBody,CardHeader,Col,Container,Row,Input,Modal,Label,ModalBody,UncontrolledDropdown,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,  Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip,  FormFeedback, 
  ModalHeader
  ,Nav, NavItem, NavLink
  ,TabContent, Table, TabPane
} from "reactstrap";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Image, message, Space } from "antd";
import "../../assets/scss/components/customantd.scss";
// import { useEffect } from "react";
// import { useRef } from "react";
import { ReactSession } from "react-client-session";
import {MoreOutlined,SearchOutlined,WarningOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import {  GetInquiryProcessList, StarUnstarInquiryByInquiryNo } from "../../services/Inquiry/inquiryProcessService";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import ConfirmationWarning from "../../components/Common/GloablMaster/ConfirmationWarning";
import { downloadExcel } from "../../functions/downloadtoExcel";
import { authError, url } from "../../services/common/const";
import { appName } from "../../common/applicationName";
import CancelCofirmModal from "../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage, getSession } from "../../services/common/session";
import {errornotify,successNotify} from "../../components/Common/notification";
import { Link,useNavigate, useLocation  } from "react-router-dom";
import UseAuth from "../../components/UseAuth";
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
// Rating
import Rating from "react-rating";
//import images

import LoadingButton from "../../common/LoadingButton";

//import DeleteModal from "../../components/Common/DeleteModal";


//redux
import { useSelector, useDispatch } from 'react-redux';

import ProspectBadge from "../../components/Inquiry/ProspectBadge";
import StatusBadge from "../../components/Inquiry/StatusBadge";

import InquiryAddEditOffCanvas from '../Inquiry/InquiryAddEditOffCanvas';

import {dateFormatLocal} from '../../functions/utility/common';
import Pagination from '../../common/Pagination';

import InquiryProcessFiltersOffCanvas from '../Inquiry/InquiryProcessFiltersOffCanvas';

  
/*** This is Inquiry functions main */
const InquiryProcess = (
) => {

  //debugger;
  //const location = useLocation();
  //const fromDashboard = location.state?.fromDashboard;
  
  //const currentActiveStatusTab = location.state?.currentActiveStatusTab;



  //alert(currentActiveStatusTab);
  //alert(currentActiveStatus);

  UseAuth();
  document.title = `${appName}-Inquiry`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  //Modal  
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  //OffCanvas  
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState([]);

  const [inquiryDetails, setInquiryDetails] = useState([]);


   //Dropdown
   const [dropdownOpen, setDropdownOpen] = useState(false);

   const toggledropDown = () => {
       setDropdownOpen(!dropdownOpen);
   };




  //Inquiry Process List is for Inquiry <Select> options
  const [inquiryProcessList, setInquiryProcessList] = useState([]);
  const [inquiryProcessPagingList, setInquiryProcessPagingList] = useState([]);
  const navigate = useNavigate();

  //Data Source is used to Bind Data table
  const [dataSource, setDataSource] = useState(inquiryProcessList);
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  const [count, setCount] = useState(inquiryProcessList?.length + 1 || 1);

  const [updatable, setUpdatable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [editablecolumn, setEditableColumn] = useState(false);
  const [sortBy] = useState(1);
  const [isActive] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  const [imageFormData, setImageFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [activeStatusTab, setActiveStatusTab] = useState(localStorage?.currentActiveStatusTab?.toString() || "0");
  const [activeStatus, setActiveStatus] = useState(localStorage?.currentActiveStatus?.toString() || "");
  const [activeInquiryProspect, setActiveInquiryProspect] = useState(localStorage.getItem("DashboardFilter-Prospect_code") || "");

  const date = new Date();
  let PageSize = 5;
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {


    debugger;
    var cu = localStorage?.currentActiveStatusTab?.toString()

    //Working
    //const currentActiveStatusTab = location?.state?.currentActiveStatusTab;



    const list = document.querySelectorAll(".inquiry-list");
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
        buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
        if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
            document.getElementById("list-view-button").classList.add("active");
            document.getElementById("grid-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.add("list-view-filter");
                el.classList.remove("grid-view-filter");
            });

        } else {
            document.getElementById("grid-view-button").classList.add("active");
            document.getElementById("list-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.remove("list-view-filter");
                el.classList.add("grid-view-filter");
            });
        }
    }
}, []);


  /** get session details*/
  // useEffect(() => {
  //   let promise = getSessionUsingSessionStorage();

  //   promise
  //     .then(function (value) {
  //       return value;
  //     })
  //     .then((value) => {
  //       setUserName(value.userName);
  //     });
  // }, [userName]);


  const [data, setData] = useState(null);

  const fetchData = async () => {
     
    try {

      let params = new URLSearchParams();
    
      let userDetails = localStorage.getItem("userDetails");
      let sessionValue = JSON.parse(userDetails);

      params.append("system_user_name", sessionValue.userName);
        
      const filters =  {
        "Status_code": activeStatus || "",
        "Prospect_code": activeInquiryProspect || ""        
      }

      params.append("filters_json", JSON.stringify(filters));

     const result = await GetInquiryProcessList(params);

      if (result?.data?.status === "SUCCESS") {
        let jsondata = result.data.data;
        setInquiryProcessList(jsondata);       
       
        setData(jsondata);

        setDataSource(jsondata);
        setCount(jsondata?.length + 1);
      } else {
        //throw result?.message;
        errornotify(result?.data?.message || result?.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };


  useEffect(() => {
   
    //alert('fetchData');
    fetchData();
  }, [activeStatus, activeStatusTab]);

  // Memoize the data value
  //const memoizedData = useMemo(() => data, [data]);

  const memoizedData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, data, activeStatus, activeStatusTab]);


  //alert('memoizedData' + memoizedData);



  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);

  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
 
  const tableRef = useRef(null);

  /**column*/
  const defaultColumns = [ 
    {
      title: "Inquiry_no",
      dataIndex: "Inquiry_no",
      width: 140,
      ...getColumnSearchProps("destination_name"),
      // sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      defaultSortOrder: "ascend",    
    }     
    
  ].filter((item) => !item.hidden);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,       
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["destination_id"];
      delete item["destination_type_id"];
      delete item["isEditing"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);


  const navigateImportDestinations = () => {
    navigate('../importDestinations', {replace: true});
  };


  
  const [isRight, setIsRight] = useState(false);
  const [isScrollBackDrop, setIsScrollBackDrop] = useState(false);

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };

  const toggleScrollBackDrop = () => {

    document.querySelector('body').style.overflow = 'scroll';
    setIsScrollBackDrop(!isScrollBackDrop);
};



  // const downloadExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(exportTableData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "DestinationTable.xlsx");
  // };


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [isInquiryAddEditOffCanvasOpen, setIsInquiryAddEditOffCanvasOpen] = useState(false);

  const toggleInquiryAddEditOffCanvasOpen = () => {
      setIsInquiryAddEditOffCanvasOpen(!isInquiryAddEditOffCanvasOpen);
  };

  const [isInquiryFiltersOffCanvasOpen, setIsInquiryFiltersOffCanvasOpen] = useState(false);

  const toggleInquiryFiltersOffCanvasOpen= () => {
    setIsInquiryFiltersOffCanvasOpen(!isInquiryFiltersOffCanvasOpen);
  };


    // const handleEditInquiry = useCallback((arg) => {
    //   const itemInquiry = arg;
    //   // setOrder({
    //   //   _id: order._id,
    //   //   orderId: order.orderId,
    //   //   customer: order.customer,
    //   //   product: order.product,
    //   //   orderDate: order.orderDate,
    //   //   ordertime: order.ordertime,
    //   //   amount: order.amount,
    //   //   payment: order.payment,
    //   //   status: order.status
    //   // });
    //   setInquiryDetails(itemInquiry);
    //   // setIsInquiryAddEditOffCanvasOpen(false);
    //   // toggleInquiryAddEditOffCanvasOpen();
    // }, [toggleInquiryAddEditOffCanvasOpen]);

    const toggleStatusTab = (tab, status) => {

        if (activeStatusTab !== tab) {
            setActiveStatusTab(tab);
            setActiveStatus(status);

            localStorage.setItem("currentActiveStatusTab", tab);
            localStorage.setItem("currentActiveStatus",status);

            //fetchData();
       }  
    };
  
      /**Add new inquiry*/
    const handleAddInquiry = () => {

      let inquiry = null;

      setInquiryDetails({...inquiry, operation : "ADD"});

      
      // let inquiry = {
      //   operation: "ADD" 
      // };

      // setInquiryDetails(inquiry);

      toggleInquiryAddEditOffCanvasOpen();
    };


    /***Edit existing inquiry*/
  const editHandller = (inquiry) => {
    setIsInquiryAddEditOffCanvasOpen(true);   
    // setModalValue({
    //   ...modalValue,
    //   pickup_and_drop_location_id: record.pickup_and_drop_location_id,
    //   destinationType: record.destination_type_id,
    //   destination: record.destination_id,  
    //   city_id: defaultCity.city_id,
    //   city_name: defaultCity.city_name,
    //   // city_id: record.city_id,
    //   // city_name: record.city_name,
    //   is_active: record.is_active,
    //   location_name: record.location_name,
    //   private_rate: record.private_rate,
    //   sic_rate: record.sic_rate,
    //   operation:"UPDATE"
    // });  
    setInquiryDetails({...inquiry, operation: "EDIT" });
    toggleInquiryAddEditOffCanvasOpen();
  };

  
  const handleFilterInquiry= () => {

    let inquiry = null;

    setInquiryDetails({...inquiry, operation : "ADD"});
    
    toggleInquiryFiltersOffCanvasOpen();
  };

  const [isStarred, setIsStarred] = useState(false);
  const toggleIsStarred= () => {
    setIsStarred(!isStarred);
  };

  const [rating, setRating] = useState(0)

  const handleRating = () => {
    toggleIsStarred();
    alert(isStarred);
    // other logic
  }


  return (

    <React.Fragment>

      
    {/* <ToastContainer closeButton={false} />
    <DeleteModal
        show={deleteModal}
        onDeleteClick={() => handleDeleteTeamData()}
        onCloseClick={() => setDeleteModal(false)}
    /> */}

<ToastContainer />
    <div className="page-content">
        <Container fluid>
           
        <BreadCrumb
            title="Inquiry Process"
            pageTitle="Inquiry"
            isSubTitle={true}           
          /> 

            <Card>
                <CardBody>
                    <Row className="g-1">
                        <Col sm={4}>
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search for #Inquiry, Name, Phone, Email, Destination etc.." />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                        <Col className="col-sm-auto ms-auto">
                            <div className="list-grid-nav hstack gap-1">

                                <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 filter-button"><i className="ri-grid-fill"></i></Button>
                                <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 active filter-button"><i className="ri-list-unordered"></i></Button>
                                
                                <Button color="info" id="list-view-button" 
                                        className="btn btn-soft-info nav-link  btn-icon fs-14 filter-button"
                                        onClick={handleFilterInquiry}>
                                          <i className="ri-filter-2-line"></i>
                                </Button>

                                {/* <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggledropDown}>                                
                                    <DropdownToggle type="button" className="btn btn-soft-info btn-icon fs-14">
                                        <i className="ri-filter-2-line align-bottom"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <li><Link className="dropdown-item" to="#">All</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Week</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Month</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Year</Link></li>
                                    </DropdownMenu>
                                </Dropdown> */}
                                {/* <Button className="btn btn-success" onClick={toggleInquiryAddEditOffCanvasOpen}>Add</Button> */}
                                <Button className="btn btn-success" onClick={handleAddInquiry}>Add</Button>

                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

         
            <Card>
                <CardHeader>
                    <div>
                    <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  >
                    <NavItem>
                      <NavLink
                        style={{ cursor: "cursor" }}
                        className={classnames(
                          { active: activeStatusTab === "1" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("1", "");
                        }}
                        href="#"
                      >
                        <i className="ri-store-2-fill me-1 align-bottom"></i>{" "}
                        All Inquiries
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                       style={{ cursor: "cursor" }}
                        className={classnames(
                          { active: activeStatusTab === "2" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("2", "OPEN");
                        }}
                        href="#"
                      >
                        <i className="ri-shopping-bag-fill me-1 align-bottom"></i>{" "}
                        Open
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "cursor" }}
                        className={classnames(
                          { active: activeStatusTab === "3" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("3", "WIP");
                        }}
                        href="#"
                      >
                        {/* <i className="bx bx-bar-chart me-1 fw-semibold align-bottom"></i>{" "} */}
                        <i className="ri-bar-chart-2-line me-1 align-bottom"></i>{" "}
                        WIP{" "}
                        {/* <span className="badge bg-danger align-middle ms-1">
                          2
                        </span> */}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "cursor" }}
                        className={classnames(
                          { active: activeStatusTab === "4" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("4", "REINQUIRED");
                        }}
                        href="#"
                      >
                        <i className="ri-arrow-left-right-fill me-1 align-bottom"></i>{" "}
                        Reinquired
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames(
                          { active: activeStatusTab === "5" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("5", "BOOKED");
                        }}
                        href="#"
                      >
                        {/* <i className="ri-exchange-dollar-fill me-1 align-bottom"></i>{" "}  */}
                        <i className="ri-star-smile-fill me-1 align-bottom"></i>{" "} 
                        
                        Booked
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames(
                          { active: activeStatusTab === "6" },
                          "fw-semibold"
                        )}
                        onClick={() => {
                          toggleStatusTab("6", "CLOSED");
                        }}
                        href="#"
                      >
                        <i className="ri-close-circle-line me-1 align-bottom"></i>{" "}
                        Closed
                      </NavLink>
                    </NavItem>
                  </Nav>
                    </div>
                </CardHeader>
                <CardBody>
                   <Row>
                      <Col lg={12}>
                          {/* <div style={{height: "70vh", width: "100%", overflowY: "scroll", overflowX: "hidden" }}> */}
                      
                          <SimpleBar style={{ height: "50vh" }} className="px-3 mx-n3 mb-2">
                          <Row className="inquiry-list list-view-filter">
                                         

                    {memoizedData && memoizedData.length > 0 ? (
                     (memoizedData || []).map((item, key) => (
                      <Col key={key}>
                          <Card className="inquiry-box">
                              <div className="inquiry-cover">
                                  {/* <img src={smallImage9} alt="" className="img-fluid" /> */}
                              </div>
                              <CardBody className="p-2">
                                  <Row xl={12} className="align-items-center inquiry-row">
                                      {/* <Col xl={1} s={1}  className="inquiry-settings">
                                          <Row>
                                              <Col xl={6}>                                                                   
                                                  <div className="bookmark-icon flex-shrink-0 me-2">
                                                      <Rating
                                                          stop={1}
                                                          emptySymbol="mdi mdi-star-outline text-muted "
                                                          fullSymbol="mdi mdi-star text-warning "
                                                      />
                                                      <Input type="checkbox" id="favourite1" className="bookmark-input bookmark-hide" />
                                                      <Label htmlFor="favourite1" className="btn-star">
                                                          <svg width="20" height="20">
                                                          </svg>
                                                      </Label>

                                                  </div>
                                                  </Col>
                                                  </Row>
                                                  <Row>
                                                  <Col xl={6}>
                                              <UncontrolledDropdown direction='start' className="col text-end">
                                                  <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                      <i className="ri-more-fill fs-17"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                      <DropdownItem><i className="ri-eye-line me-2 align-middle" />View</DropdownItem>
                                                      <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                      <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                  </DropdownMenu>
                                              </UncontrolledDropdown>
                                            
                                              </Col>
                                          </Row>
                                      </Col> */}
                                          <Col className="inquiry-settings">
                                          <Row>
                                              <Col>
                                                  <div className="bookmark-icon flex-shrink-0 me-2">
                                                      <Rating
                                                        style={{ fontSize: "20px" }}
                                                        stop={1}
                                                        emptySymbol="mdi mdi-star-outline text-muted "
                                                        fullSymbol="mdi mdi-star text-warning "
                                                        initialRating={item.is_starred || 0}
                                                        //onClick={handleRating}  
                                                        
                                                        onChange={(customize) => {
                                                          item.is_starred = customize;                                                         
                                                        }}
                                                                                  
                                                      />

                                                  </div>
                                              </Col>
                                              <UncontrolledDropdown direction='start' className="col text-end">
                                                  <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                      <i className="ri-more-fill fs-17"></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>    
                                                
                                                      <DropdownItem  onClick={() => editHandller(item)}> <i className="ri-pencil-line me-2 align-middle" />Edit</DropdownItem>
                                                      <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                      <DropdownItem><i className="ri-eye-line me-2 align-middle" />View</DropdownItem>
                                                    
                                                  </DropdownMenu>
                                              </UncontrolledDropdown>
                                          </Row>
                                      </Col>
                                      <Col className="col">
                                          <div className="inquiry-main">

                                              <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">  
                                                      { 
                                                          item?.destination_image === null || item?.destination_image === ''  ?
                                                          <img src={`${url}/Images/NoImageFound_round.jpg`} alt="" className="img-fluid d-block rounded-circle" />
                                                          :
                                                          <img src={`${url}/Images/Destination/${item.destination_image}`} alt="" className="img-fluid d-block rounded-circle" />

                                                      }                                                               
                                                    
                                              </div>
                                              <div className="inquiry-content">
                                                  <Row className="text-muted">
                                                      <Col xs={6} className="border-end border-end-dashed">
                                                            <Link to="#" onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><h6 className="fs-14 mb-1 text-decoration-underline">#{item.inquiry_no}</h6></Link>
                                                            <p className="text-muted mb-0"> {dateFormatLocal(item.inquiry_date)}</p>
                                                            {/* <p className="text-muted mb-0"> {item.inquiry_date}</p> */}
                                                            <p>{item.source_code} </p>
                                                      </Col>
                                                      <Col xs={6}>
                                                            <h6 className="mb-1">{item.contact.name}</h6>
                                                            <p className="text-muted mb-0">{item.contact.phone_country_code}{item.contact.phone}</p>
                                                            <p className="text-muted mb-0">{item.contact.email}</p>
                                                      </Col>
                                                </Row>

                                              
                                              </div>
                                          </div>
                                      </Col>                                                       

                                      <Col xl={2} className="col">
                                          <Row className="text-muted text-center">
                                              <Col xs={6} className="border-end border-end-dashed">
                                                  <h6 className="mb-1">Prospect</h6>
                                                  <ProspectBadge prospectCode = {item.prospect_code} />
                                                  <h6 className="mb-1">Status</h6>
                                                  <StatusBadge statusCode = {item.status_code} />
                                              </Col>
                                              <Col xs={6}>
                                                  <h6 className="mb-1">Follow up</h6>
                                                  <p className="text-muted mb-0"> {"N/A"} </p>
                                                  <h6 className="mb-1">Last Call</h6>                                                                   
                                                  <p className="text-muted mb-0"> {dateFormatLocal(item.last_call) }</p>
                                                  
                                              </Col>
                                          </Row>
                                      </Col>

                                      <Col xl={3} className="col inquiry-content">
                                          <Row className="text-muted">
                                              <Col xs={6} className="border-end border-end-dashed">
                                                  <h6 className="mb-1">Destination </h6>
                                                  <p className="text-muted mb-0">{item.destination_name}</p>
                                                  <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date} </p>
                                              </Col>
                                              <Col xs={6}>
                                                <h6 className="mb-1">Travellers</h6>                                                                
                                                  <p className="text-muted mb-0">{item.pax}</p>
                                                  <h6 className="mb-1">City </h6>
                                                  <p className="text-muted mb-0">{item.city}</p>
                                              </Col>
                                          </Row>
                                      </Col>
                                      <Col xl={3} className="col inquiry-content">
                                          <Row className="text-muted">
                                              <Col xs={6} className="">
                                                  <h6 className="mb-1">Assign To</h6>                                                                
                                                  <p className="text-muted mb-0">{item.allocated_to_user_full_nme}</p>
                                                  <h6 className="mb-1">Expected Budget </h6>
                                                  <p className="text-muted mb-0">{item.expected_budget}</p>
                                              </Col>
                                              <Col xs={6}>
                                                <h6 className="mb-1">Created On</h6>                                                                
                                                  <p className="text-muted mb-0">{dateFormatLocal(item.row_created_date)}</p>
                                                  <h6 className="mb-1">Last updated</h6>
                                                  <p className="text-muted mb-0">{dateFormatLocal(item.row_altered_date)}</p>
                                              </Col>
                                          </Row>
                                      </Col>                                                                                                         
                                  </Row>
                              </CardBody>
                          </Card>
                      </Col>
                  ))

                  ) : (
                    <div className="py-4 text-center">
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#0ab39c"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                      </div>

                      <div className="mt-4">
                        <h5>Sorry! No Result Found</h5>
                      </div>
                    </div>
                  )}


                                  {/* <Col lg={12}>
                                      <div className="text-center mb-3">
                                          <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Load More </Link>
                                      </div>
                                  </Col> */}
                            </Row>                     
                            </SimpleBar>

                            {/* {memoizedData == null */}
                              <Pagination
                                onPageChange={page => setCurrentPage(page)}
                                totalCount={data !== null ? data.length : 0}  
                                currentPage={currentPage}
                                pageSize={PageSize}                            
                                className="pagination-bar"
                              />
                            {/* } */}

                            

                          {/* </div> */}
                      </Col>
                  </Row>                    
                </CardBody>
            </Card>
       

           
            <svg className="bookmark-hide">
                <symbol viewBox="0 0 24 24" stroke="currentColor" fill="var(--color-svg)" id="icon-star"><path strokeWidth=".4" d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"></path></symbol>
            </svg>

        </Container>
    </div>
     
 
    <InquiryAddEditOffCanvas 
      inquiryDetails = {inquiryDetails}
      isInquiryAddEditOffCanvasOpen={isInquiryAddEditOffCanvasOpen} 
      toggleInquiryAddEditOffCanvasOpen = {toggleInquiryAddEditOffCanvasOpen}  
      fetchData   = { fetchData} 
    />


  <InquiryProcessFiltersOffCanvas 
      inquiryDetails = {inquiryDetails}
      isInquiryFiltersOffCanvasOpen={isInquiryFiltersOffCanvasOpen} 
      toggleInquiryFiltersOffCanvasOpen = {toggleInquiryFiltersOffCanvasOpen}  
      fetchData   = { fetchData} 
    />


 
      </React.Fragment>
  );
};

export default InquiryProcess;
