import React from "react";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import { Table } from "antd";
import { useState } from "react";
import {
  getTravellingCompany,
  sentEmailService,
} from "../../services/MarketingMenu";
import { useEffect } from "react";
import LoadingButton from "../../common/LoadingButton";
import { successNotify } from "../../components/Common/notification";
import { errornotify } from "../../components/Common/notification";
import UseAuth from "../../components/UseAuth";

const SendEmail = () => {
  UseAuth();
  const [dataSource, setDataSource] = useState([]);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [companyEmail, setCompanyEmail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  /**send email handller */
  const sendEmailHandller = async (companyEmail) => {
    setIsLoading(true);
    try {
      const response = await sentEmailService(companyEmail);
      if (response?.status === "SUCCESS") {
        successNotify(response?.message);
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let selectedEmail = [];
    if (selectedTableRows?.length > 0) {
      selectedTableRows?.map((item, index) => {
        selectedEmail.push({
          Email_id_1: item.email_id_1,
          Email_id_2: item.email_id_2,
        });
      });
    }
    setCompanyEmail(selectedEmail);
  }, [selectedTableRows]);

  /**This is row selection table */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableRows(selectedRows);
    },
  };
  const getTravellingCompanyData = async () => {
    try {
      const response = await getTravellingCompany();
      if (response?.data?.status === "SUCCESS") {
        setDataSource(response.data.data);
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for get data */
  useEffect(() => {
    getTravellingCompanyData();
  }, []);
  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Mobile 1",
      dataIndex: "mobile_1",
      key: "mobile_1",
    },
    {
      title: "Mobile 2",
      dataIndex: "mobile_2",
      key: "mobile_2",
    },
    {
      title: "Email Id 1",
      dataIndex: "email_id_1",
      key: "email_id_1",
    },
    {
      title: "Email Id 2",
      dataIndex: "email_id_2",
      key: "email_id_2",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Adress",
      dataIndex: "address",
      key: "address",
      render: (text, record) => {
        return (
          <>
            <UncontrolledTooltip
              placement="top"
              target={`tooltipId${record.package_id}`}
            >
              {record.address}
            </UncontrolledTooltip>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
              id={`tooltipId${record.package_id}`}
            >
              {record.address}
            </p>
          </>
        );
      },
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Send Email"
            pageTitle="Marketing"
            isSubTitle={true}
          />
          <Card>
            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">
                  <Col className="col-sm-auto">
                    <div>
                      {isLoading ? (
                        <LoadingButton btnText={"Send Email"} />
                      ) : (
                        <ButttonTravelNinjaz
                          backGroundColor="primary"
                          className="add-btn me-1 my-1"
                          id="create-btn"
                          onCLickHancller={() =>
                            sendEmailHandller(companyEmail)
                          }
                          // buttonIcon={
                          //   <i className="ri-add-line align-bottom me-1"></i>
                          // }
                          buttonText="Send Email"
                        />
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="tableCustom">
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                />
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SendEmail;
