import React, { useEffect , useState} from "react";
import HomeRoutes from "./Routes";
/** import scss */
import "./assets/scss/themes.scss";
import { ToastContainer } from "react-toastify";

import { MyContext } from "./MyContext";

const App = () => {

  const [text, setText] = useState("I am Manisha 1");
  const [text2, setText2] = useState("I am Manisha 2");

  /* Show Globals Variables that will be used if Respective Controls will be visible to the user or not */
   const [globalShowDestinationType, setGlobalShowDestinationType] = useState("");
   const [globalShowDestination, setGlobalShowDestination] = useState("");
   const [globalShowCity, setGlobalShowCity] = useState("");

  // /* List Globals Variables that will be used to fill the Select List / Dropdown list */
  const [globalDestinationTypeList, setGlobalDestinationTypeList] = useState();
  const [globalDestinationList, setGlobalDestinationList] = useState();
  const [globalCityList, setGlobalCityList] = useState();

  // /* Default Globals Variables that will be used to select default values of the Select List / Dropdown list */
  const [globalDefaultDestinationType, setGlobalDefaultDestinationType] = useState("");
  const [globalDefaultDestination, setGlobalDefaultDestination] = useState("");
  const [globalDefaultCity, setGlobalDefaultCity] = useState("");



// const [showDestinationType, setShowDestinationType] = useState(localStorage.getItem("global_show_destinationType") == "true"? true : false);
// const [showDestination, setShowDestination] = useState(localStorage.getItem("global_show_destinationType")  == "true"? true : false);



  return (
    <>
      <MyContext.Provider 
      // value={{  text, setText   }}
        // value={{ 
        //      globalShowDestinationType, setGlobalShowDestinationType
        //     ,globalShowDestination, setGlobalShowDestination
        //     ,globalShowCity, setGlobalShowCity

        //     ,globalDestinationTypeList, setGlobalDestinationTypeList
        //     ,globalDestinationList, setGlobalDestinationList
        //     ,globalCityList, setGlobalCityList

        //     ,globalDefaultDestinationType, setGlobalDefaultDestinationType
        //     ,globalDefaultDestination, setGlobalDefaultDestination
        //     ,globalDefaultCity, setGlobalDefaultCity

        //   }}

          value={{             
            globalShowDestinationType, setGlobalShowDestinationType
            ,globalShowDestination, setGlobalShowDestination
            ,globalShowCity, setGlobalShowCity

            ,globalDestinationTypeList, setGlobalDestinationTypeList
            ,globalDestinationList, setGlobalDestinationList
            ,globalCityList, setGlobalCityList

            ,globalDefaultDestinationType, setGlobalDefaultDestinationType
            ,globalDefaultDestination, setGlobalDefaultDestination
            ,globalDefaultCity, setGlobalDefaultCity
          }}
        //value={{  text, setText   }}

        >
        <ToastContainer />
        <HomeRoutes />
      </MyContext.Provider>
    </>
  );
};

export default App;
