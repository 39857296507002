import React from "react";

const CurrencySymbol = ({ currencySymbol, currency }) => {
  return (
    <span>
    {currencySymbol
      ? `${currencySymbol}`
      :""}
  </span>
  );
};

export default CurrencySymbol;
