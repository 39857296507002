import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CitySelectList = ({
  id,
  name,
  key,
  value,
  onChange,
  className
  }) => {

const animatedComponents = makeAnimated();

const [cityList, setCityList] =  useState( JSON.parse(localStorage.getItem("global_city_list")));
const [cityOptionSelected, setCityOptionSelected] = useState(value);
const [cityOptions, setCityOptions] = React.useState([]);

const handleCityChange = (city) => {

  if(city !=null)
  {
    const selected_city= cityList.filter(item => item.city_id ===city.value);
    localStorage.setItem("global_default_city_object", JSON.stringify(selected_city[0]));
    localStorage.setItem("global_search_city_option", JSON.stringify(city));
    setCityOptionSelected(city);
  }
  else
  {      
    localStorage.setItem("global_default_city_object", []);
    localStorage.setItem("global_search_city_option", null);
    setCityOptionSelected(null);
  }

};

const GetCityListFunc = () => {
 
  /* Cities for Destination Types and Destinations*/

  let global_default_destination_object;
  let filteredCityList ;
  if(localStorage.getItem("global_default_destination_object") != null)
  {
    global_default_destination_object = JSON.parse(localStorage.getItem("global_default_destination_object") ?? "");
    filteredCityList = cityList.filter(item => 
           (item.destination_type_id ===global_default_destination_object.destination_type_id)
           && 
           (item.destination_id ===global_default_destination_object.destination_id)   
           && 
           (item.is_active== true)                                                  
         );
 
  // else{
  //   filteredCityList = cityList;
 //}
  setCityOptions([]);
  if (filteredCityList?.length > 0) {
   
    let cityOptionsTemp = [];

    filteredCityList.map((item, index) => {
      cityOptionsTemp.push({
          label: item.city_name,
          value: item.city_id,
        });
      });

      setCityOptions(cityOptionsTemp);
    }
  }
 
   /************** Cities for Destination Types and Destinations */

};

  useEffect(() => {
    GetCityListFunc();     
  }, []);
  
  return (
  
          // <Select 
          // id={"city"}
          // name={"city"}
          // isClearable={true}
          // value={cityOptionSelected} 
          // onChange={onChange} 
          // options={cityOptions}
          // styles={{width : "300px"}} 
          // placeholder = {"City..."}
          // />

          <Select
          id={id}
          name={name}
          key={key}
          value={value}                                      
          onChange={onChange} 
          className={className}        
          options={cityOptions}
          components={animatedComponents}
         // menuPortalTarget={document.body} 
          styles={{ zIndex: 9999, position: "absolute"}}

          //menuPortalTarget={document.body}
          menuPosition='fixed'

        /> 

  )  
};

export default CitySelectList;
