import React from "react";
import { Input, Label } from "reactstrap";

const InputProfie = ({
  labelName,
  id,
  value,
  defaultValue,
  onChange,
  type,
  placeholder,
  onBlur,
  invalid,
  disabled,
}) => {
  return (
    <>
      <Label htmlFor={id} className="form-label">
        {labelName}
      </Label>
      <Input
        type={type}
        className="form-control"
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        invalid={invalid}
        disabled={disabled}
      />
    </>
  );
};

export default InputProfie;
