import React from "react";
import { ToastContainer } from "react-toastify";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import ReactWhatsapp from "react-whatsapp";
const SendWhatsapp = () => {
  const numbers = ["+91 9399030661", "+91 9174732051"];
  return (
    <>
      {" "}
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Send Whatsapp"
            pageTitle="Marketing"
            isSubTitle={true}
          />
          <Card>
            <CardBody>
              <Row className="g-4 mb-3">
                <Col lg={12}>
                  {numbers.map((item, index) => {
                    return (
                      <ReactWhatsapp number={item} message="Hello World!!!" />
                    );
                  })}
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default SendWhatsapp;
