import React, { useState } from "react";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Input,
  Modal,
  Label,
  ModalBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import { message, Space, Table } from "antd";
import "../../../assets/scss/components/customantd.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { ReactSession } from "react-client-session";
import {
  MoreOutlined,
  SearchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import {
  GetVehicleList,
  AddVehicleList,
  UpdateVehicleList,
} from "../../../services/GlobalMasters/vehicleService";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import ConfirmationWarning from "../../../components/Common/GloablMaster/ConfirmationWarning";
import { downloadExcel } from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import CancelCofirmModal from "../../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage } from "../../../services/common/session";
import {
  errornotify,
  successNotify,
} from "../../../components/Common/notification";
import { useNavigate } from "react-router-dom";
import { authError } from "../../../services/common/const";
import UseAuth from "../../../components/UseAuth";

/*** This is vehicle functions main */
const Transport = () => {
  UseAuth();
  // document.title = "Tranport | TravelNinjaz B2B";
  document.title = `${appName}-Tranport`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };
  const [vehicleList, setVehicleList] = useState([]);
  const [dataSource, setDataSource] = useState(vehicleList);
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const [count, setCount] = useState(vehicleList?.length + 1 || 1);
  const [updatable, setUpdatable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [editablecolumn, setEditableColumn] = useState(false);
  const [sortBy] = useState(1);
  const [isActive] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  const navigate = useNavigate();
  /**This is useeffect for set export table data */

  // useEffect(() => {
  //   const exportData=dataSourc
  // }, [dataSource])

  const date = new Date();

  /**This function for get vehicle list from database */
  const getVehicleFunc = async () => {
    try {
      const result = await GetVehicleList();
      //debugger;
      if (result?.data?.status === "SUCCESS") {
        let jsondata = result?.data?.data;
        setVehicleList(jsondata);
        setDataSource(jsondata);
        setCount(jsondata?.length + 1);
      } else {
        throw result.message;
        // return;
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };
  useEffect(() => {
    getVehicleFunc();
  }, []);

  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);

  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /**This is for edit cell for edit handller */
  const editHandller = () => {
    setEditableColumn(true);
    setUpdatable(true);
    setShowAdd(false);
  };
  const [isSaveNew, setIsSaveNew] = useState(true);

  /***This is for Add/Update handller */
  const saveHandller = async () => {
    try {
      let newDataSource = dataSource.filter((item) => {
        return !vehicleList.find((itemB) => {
          return item.key === itemB.key;
        });
      });
      if (updatable) {
        /**This map function for check validation */
        dataSource.map((item, index) => {
          if (item.vehicle_name?.length < 1) {
            setIsSaveNew(false);
            throw "Vehicle Name is required.";
          }
          if (item.vehicle_type?.length < 1) {
            setIsSaveNew(false);
            throw "Vehicle Type is required.";
          }
          if (!item.no_of_seats) {
            setIsSaveNew(false);
            throw "No. Of seats is required.";
          }
        });
        if (isSaveNew) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
          });

          //UPdate method call
          const result = await UpdateVehicleList(dataSource);
          if (result.status === "SUCCESS") {
            successNotify(result.message);
            getVehicleFunc();
            setUpdatable(false);
            setEditableColumn(false);
            setShowAdd(true);
            setShowEdit(true);
          } else {
            errornotify(result.message);
          }
        }
      } else {
        /**This map function for check validation */
        newDataSource.map((item, index) => {
          if (item.vehicle_name?.length < 1) {
            setIsSaveNew(false);
            throw "Vehicle Name is required.";
          }
          if (item.vehicle_type?.length < 1) {
            setIsSaveNew(false);
            throw "Vehicle Type is required.";
          }
          if (!item.no_of_seats) {
            setIsSaveNew(false);
            throw "No of seats is required.";
          }
        });

        if (isSaveNew) {
          // API code does not accept date so manually removing Date from the source
          newDataSource.map((item, index) => {
            item.row_created_date = new Date(item.row_created_date);
            item.row_altered_date = new Date(item.row_altered_date);
          });

          //Add method call
          let result = await AddVehicleList(newDataSource);
          if (result.status === "SUCCESS") {
            successNotify(result.message);
            getVehicleFunc();
            setShowAdd(true);
            setShowEdit(true);
            setEditableColumn(false);
          } else {
            errornotify(result.message);
          }
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for cancel handller */
  const cancelHandller = () => {
    setEditableColumn(false);
    getVehicleFunc();
    setDataSource(vehicleList);
    setUpdatable(false);
    setShowAdd(true);
    setShowEdit(true);
    tog_standard();
  };
  /**This is for input text handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSource.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(dataSource);
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  };

  const tableRef = useRef(null);

  /**column*/
  const defaultColumns = [
    {
      title: "Vehicle Name",
      width: 200,
      dataIndex: "vehicle_name",
      ...getColumnSearchProps("vehicle_name"),
      sorter: {
        compare: (a, b) => a.vehicle_name.localeCompare(b.vehicle_name),
        multiple: 8,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.vehicle_name.localeCompare(b.vehicle_name),
      render: (text, record) => {
        const inputHandller = (e) => {
          record.vehicle_name = e.target.value;
          if (record.vehicle_name?.length > 0) {
            setIsSaveNew(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.vehicle_name}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.vehicle_name}
              onChange={(e) => inputHandller(e)}
              invalid={record?.vehicle_name?.length < 1 && !isSaveNew}
              required
            />
            {record?.vehicle_name?.length < 1 && !isSaveNew && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Vehicle Name is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Vehicle Type",
      dataIndex: "vehicle_type",
      width: 200,
      ...getColumnSearchProps("vehicle_type"),
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) => a.vehicle_type.localeCompare(b.vehicle_type),
        multiple: 7,
      },
      // sorter: (a, b) => a.vehicle_type.localeCompare(b.vehicle_type),
      render: (text, record) => {
        const inputHandller = (e) => {
          record.vehicle_type = e.target.value;
          if (record.vehicle_type?.length > 0) {
            setIsSaveNew(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="text"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.vehicle_type}
          />
        ) : (
          <>
            <Input
              type="text"
              className="form-control"
              defaultValue={record?.vehicle_type}
              onChange={(e) => inputHandller(e)}
              invalid={record?.vehicle_type?.length < 1 && !isSaveNew}
              required
            />
            {record?.vehicle_type?.length < 1 && !isSaveNew && (
              <p style={{ fontSize: "12px", color: "red" }}>
                Vehicle Type is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "No of Seats",
      dataIndex: "no_of_seats",
      width: 160,
      ...getColumnSearchProps("no_of_seats"),
      sorter: {
        compare: (a, b) => a.no_of_seats - b.no_of_seats,
        multiple: 6,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.no_of_seats - b.no_of_seats,
      render: (text, record) => {
        const inputHandller = (e) => {
          record.no_of_seats = parseInt(e.target.value);
          if (record.no_of_seats) {
            setIsSaveNew(true);
          }
        };
        return !editablecolumn && !record?.isEditing ? (
          <Input
            type="number"
            className="form-control"
            disabled={!editablecolumn && !record?.isEditing}
            value={record.no_of_seats}
          />
        ) : (
          <>
            <Input
              type="number"
              className="form-control"
              defaultValue={record?.no_of_seats}
              onChange={(e) => inputHandller(e)}
              invalid={!record?.no_of_seats && !isSaveNew}
              required
            />
            {!record?.no_of_seats && !isSaveNew && (
              <p style={{ fontSize: "12px", color: "red" }}>
                No Of Seats is Required
              </p>
            )}
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled={!editablecolumn && !record?.isEditing}
            />
          </div>
        );
      },
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
    },
    {
      title: "Created By",
      dataIndex: "row_created_by",
      width: 250,
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      hidden: rowCreatedBy,
    },
    {
      title: "Created On",
      width: 160,
      dataIndex: "row_created_date",
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      hidden: rowCreatedDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      hidden: rowModifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      hidden: rowModifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);
  /**This function for add new row */
  const handleAdd = () => {
    let addData = [...dataSource];
    const newData = {
      key: count,
      transport_id: count,
      vehicle_name: "",
      vehicle_type: ``,
      no_of_seats: null,
      is_active: isActive,
      row_created_by: userName,
      row_created_date: `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`,
      row_altered_by: userName,
      row_altered_date: `${
        date.getMonth() + 1
      }/${date.getDate()}/${date.getFullYear()}`,
      isEditing: true,
    };
    addData.splice(0, 0, newData);
    setCount(count + 1);
    setDataSource(addData);
    setShowEdit(false);
    // setEditableColumn(true);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["transport_id"];
      delete item["isEditing"];
      delete item["vehicle_price"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Transport" pageTitle="Setup" isSubTitle={true} />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          {showAdd && (
                            <>
                              <ButttonTravelNinjaz
                                backGroundColor="success"
                                className="add-btn me-1 my-1"
                                id="create-btn"
                                onCLickHancller={handleAdd}
                                buttonIcon={
                                  <i className="ri-add-line align-bottom me-1"></i>
                                }
                                buttonText="Add"
                              />
                            </>
                          )}
                          {!editablecolumn && showEdit ? (
                            <>
                              <ButttonTravelNinjaz
                                backGroundColor="info"
                                className="add-btn me-1 my-1"
                                id="edit-btn"
                                onCLickHancller={editHandller}
                                buttonIcon={
                                  <i className="ri-edit-line align-bottom me-1"></i>
                                }
                                buttonText="Edit"
                              />
                            </>
                          ) : (
                            <>
                              <ButttonTravelNinjaz
                                backGroundColor="primary"
                                className="add-btn me-1 my-1"
                                id="save-btn"
                                onCLickHancller={saveHandller}
                                buttonText="Save"
                              />
                              <ButttonTravelNinjaz
                                backGroundColor="danger"
                                className="add-btn me-1 mx-1 my-1"
                                id="cancel-btn"
                                onCLickHancller={tog_standard}
                                buttonText="Cancel"
                              />
                            </>
                          )}
                          <UncontrolledDropdown
                            style={{ display: "inline-block" }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Export
                            </DropdownToggle>
                            <DropdownMenu>
                              <CSVLink
                                filename={"TravelNinjazB2BVehicle.csv"}
                                data={exportTableData}
                                className="btn btn-secondary m-1 mx-2"
                                onClick={() => {
                                  message.success("The file is downloading");
                                }}
                              >
                                Export to CSV
                              </CSVLink>
                              <ButttonTravelNinjaz
                                backGroundColor="secondary"
                                className="add-btn m-1 mx-1"
                                id="cancel-btn"
                                onCLickHancller={() =>
                                  downloadExcel(
                                    exportTableData,
                                    "TransportTable"
                                  )
                                }
                                buttonText="Export to Excel"
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>
                          <UncontrolledDropdown
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-light mx-1"
                              type="button"
                            >
                              <MoreOutlined style={{ fontSize: "21px" }} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck1"
                                  defaultChecked={!rowCreatedBy}
                                  onChange={(e) =>
                                    setRowCreatedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck1"
                                >
                                  Created By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck2"
                                  defaultChecked={!rowCreatedDate}
                                  onChange={(e) =>
                                    setRowCreatedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck2"
                                >
                                  Created On
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  defaultChecked={!rowModifiedBy}
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck3"
                                  onChange={(e) =>
                                    setRowModifiedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck3"
                                >
                                  Modified By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck4"
                                  defaultChecked={!rowModifiedDate}
                                  onChange={(e) =>
                                    setRowModifiedDate(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck4"
                                >
                                  Modified On
                                </Label>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end my-1">
                          <div className="search-box ms-2 ">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => inputHandller(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {searchText?.length > 0 ? (
                      <>
                        <Table
                          // components={components}
                          rowClassName={() => "editable-row"}
                          bordered
                          dataSource={filterData}
                          columns={columns}
                          scroll={{
                            y: 320,
                          }}
                          ref={tableRef}
                          id="table-to-xls"
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items  `,
                          }}
                          onChange={onChange}
                          locale={{
                            triggerDesc: null,
                            triggerAsc: null,
                            cancelSort: null,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          // components={components}
                          rowClassName={() => "editable-row"}
                          bordered
                          dataSource={dataSource}
                          columns={columns}
                          scroll={{
                            y: 320,
                          }}
                          pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            pageSizeOptions: [10, 20, 50, 100],
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items  `,
                          }}
                          onChange={onChange}
                          locale={{
                            triggerDesc: null,
                            triggerAsc: null,
                            cancelSort: null,
                          }}
                        />
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CancelCofirmModal
        cancelHandller={cancelHandller}
        modal_standard={modal_standard}
        tog_standard={tog_standard}
      />
      {/**popconfirm modal*/}
      {/* <Modal
        id="myModal"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        centered
        style={{ width: "280px" }}
      >
        <ModalBody>
          <ConfirmationWarning />
        </ModalBody>
        <div className="modal-footer">
          <ButttonTravelNinjaz
            backGroundColor="light"
            onCLickHancller={() => {
              tog_standard();
            }}
            buttonText="NO"
          />
          <ButttonTravelNinjaz
            backGroundColor="danger"
            onCLickHancller={cancelHandller}
            buttonText="Yes"
          />
        </div>
      </Modal> */}
    </>
  );
};

export default Transport;
