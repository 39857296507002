import React, {useState, useEffect} from 'react';
import { Col, Row } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import {errornotify} from "../../components/Common/notification";
import {dateFormatLocal} from '../../functions/utility/common';

const WelcomeSection = () => {

    const [userFullName, setUserFullName] = useState();

    const [dashbaordFromDate, setDashbaordFromDate] = useState(localStorage.getItem("dashbaordFromDate") || dateFormatLocal(new Date()));
    const [dashbaordToDate, setDashbaordToDate] = useState(localStorage.getItem("dashbaordToDate") || dateFormatLocal(new Date()));

    const sidebar = () => {
        const element = document.getElementById("layout-rightside-coll");
        element.classList.toggle("d-none");
    };

    const onChangeHandller = (e) => {  

        if(e.length == 1)                
        {       
            const selectedDate = dateFormatLocal(dateFormatLocal(e[0]));    
            setDashbaordFromDate(selectedDate);
            setDashbaordToDate(selectedDate);

            localStorage.setItem("dashbaordFromDate", selectedDate);
            localStorage.setItem("dashbaordToDate", selectedDate);            
        }
        if(e.length == 2)                
            {           
                const selectedFromDate = dateFormatLocal(dateFormatLocal(e[0]));
                const selectedToDate = dateFormatLocal(dateFormatLocal(e[1]));        
                setDashbaordFromDate(selectedFromDate);
                setDashbaordToDate(selectedToDate);

                localStorage.setItem("dashbaordFromDate", selectedFromDate);
                localStorage.setItem("dashbaordToDate", selectedToDate);  
            }
      
      };

      

    useEffect(() => {
        try {

            //alert('Today Date' + dateFormatLocal(new Date()));

            let userDetails = localStorage.getItem("userDetails");
            let sessionValue = JSON.parse(userDetails);                         

            setUserFullName(sessionValue.fullName)

            // if(localStorage.getItem("dashbaordFromDate") == null)
            // {
            //     setDashbaordFromDate(dateFormatLocal(new Date()));
            // }
            // else
            // {
            //     setDashbaordFromDate(localStorage.getItem("dashbaordFromDate"));
            // }

            // if(localStorage.getItem("dashbaordToDate") == null)
            // {
            //     setDashbaordToDate(dateFormatLocal(new Date()));
            // }
            // else
            // {
            //     setDashbaordToDate(localStorage.getItem("dashbaordToDate"));
            // }

            
           // alert(dateFormatLocal(new Date()));
      } catch (error) {
        errornotify(error);
      }    
       
      }, []);


   
    return (
        <React.Fragment>
            <Row className="mb-3 pb-1">
                <Col xs={12}>
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                        <div className="flex-grow-1">
                            <h4 className="fs-16 mb-1">Hello, {userFullName}!</h4>
                            <p className="text-muted mb-0">Welcome to the Admin Panel.</p>
                        </div>
                        <div className="mt-3 mt-lg-0">
                          
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default WelcomeSection;