
import moment from 'moment';

export  const dateFormatLocal = (row_created_date) => {

    let newDate;

    if (row_created_date !== null && row_created_date !== undefined)
      newDate = moment(row_created_date).format('DD/MMM/YYYY');
    
    return  newDate;

  };

  export  const timeFormatLocal = (row_created_date) => {

    let newDate;

    if (row_created_date !== null && row_created_date !== undefined)
      newDate = moment(row_created_date).format('hh:mm:ss');
    
    return  newDate;

  };