import { url } from "../common/const";
import { get, post, post_common } from "../common/https";
/**This api for get Hotel Room list*/
export const GetTransportRateList = (params) => {
  const getdata = get(`${url}/api/TransportRate/list?` + params);
  return getdata;
};
/**This api for save Hotel Room list*/
export const SaveTransportRateList = (data) =>
  post(`${url}/api/TransportRate/save`, data);



  export const importTransportRateFileData = (data) =>
  post_common(`${url}/api/TransportRate/ImportTransportRateFileData`, data);

export const getImportTransportRateList = (params) => {
  const getdata = get(`${url}/api/TransportRate/GetImportTransportRateList?` + params);
  return getdata;
};

export const publishImportedTransportRate = (data) =>
post_common(`${url}/api/TransportRate/PublishImportedTransportRate`, data);

export const cancelImportedTransportRate = (data) =>
post_common(`${url}/api/TransportRate/CancelImportedTransportRate`, data);



