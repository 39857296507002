import React from "react";

const StatusBadge = 
({
    statusCode     
}) => {

  return (     
      <>
        {(() => {
          switch (statusCode) {
            case "OPEN":   return <span className="fs-10 badge badge-soft-danger"> {statusCode} </span>;
            case "WIP":   return <span className="fs-10 badge badge-soft-warning"> {statusCode} </span>;
            case "CLOSED":   return <span className="fs-10 badge badge-soft-info"> {statusCode} </span>;
            case "REINQUIRED":   return <span className="fs-10 badge badge-soft-primary"> {statusCode} </span>;
            case "BOOKED":   return <span className="fs-10 badge badge-soft-success"> {statusCode} </span>;
            default:      return "";
          }
        })()}  
      </>   
     );
   
}    

export default StatusBadge;