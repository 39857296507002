import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumb = ({ title, pageTitle, isSubTitle,  parentPageLink = "", parentPageTitle = "" }) => {
  return (
    <React.Fragment>
      <Row>
        <Col xxl={12}>
          <div className="page-title-box d-flex align-items-center justify-content-between">
         
          <div className="page-title-left1 d-flex"> 
            {parentPageLink && (
              
                <div className="m-0 d-flex px-2">
                    <a href={parentPageLink} >         
                      <h4 className="mb-sm-0 flex">{parentPageTitle + ' >  '} </h4>
                    </a>  
                </div>
                
              )}   
    
            <div className="flex-grow-1 ">        
              <h4 className="mb-sm-0">{title}</h4>                
            </div>
            </div>              

              {isSubTitle && (
              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item">
                    <Link to="#">{pageTitle}</Link>
                  </li>
                  <li className="breadcrumb-item active">{title}</li>
                </ol>
              </div>
            )}
     
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
