import React, { useState } from "react";
import "antd/dist/antd.css";
import { CSVLink } from "react-csv";
import { Button,Card,CardBody,CardHeader,Col,Container,Row,Input,Modal,Label,ModalBody,UncontrolledDropdown,
  Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,  Offcanvas, OffcanvasBody,  FormFeedback, 
  ModalHeader
} from "reactstrap";


import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BreadCrumb from "../../components/Common/BreadCrumb";
import { Image, message, Space, Table } from "antd";
import "../../assets/scss/components/customantd.scss";
import { useEffect } from "react";
import { useRef } from "react";
import { ReactSession } from "react-client-session";
import {MoreOutlined,SearchOutlined,WarningOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import * as XLSX from "xlsx";
import {  GetInquiryProcessList } from "../../services/Inquiry/inquiryProcessService";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import ConfirmationWarning from "../../components/Common/GloablMaster/ConfirmationWarning";
import { downloadExcel } from "../../functions/downloadtoExcel";
import { authError, url } from "../../services/common/const";
import { appName } from "../../common/applicationName";
import CancelCofirmModal from "../../common/CancelCofirmModal";
import { getSessionUsingSessionStorage, getSession } from "../../services/common/session";
import {errornotify,successNotify} from "../../components/Common/notification";
import { Link,useNavigate } from "react-router-dom";
import UseAuth from "../../components/UseAuth";

// Rating
import Rating from "react-rating";


import LoadingButton from "../../common/LoadingButton";

//import DeleteModal from "../../components/Common/DeleteModal";

//User Images
import avatar2 from '../../assets/images/users/avatar-2.jpg';

//Small Images
import smallImage9 from '../../assets/images/male1.png';
//redux
import { useSelector, useDispatch } from 'react-redux';

import ProspectBadge from "../../components/Inquiry/ProspectBadge";
import StatusBadge from "../../components/Inquiry/StatusBadge";

import moment from 'moment';

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  Collapse
} from 'reactstrap';

import classnames from 'classnames';
// import InquiryAddEditPopupModal from "../Inquiry/InquiryAddEditPopupModal";

/*** This is Inquiry functions main */
const InquiryProcess3 = () => {
  UseAuth();
  document.title = `${appName}-Inquiry`;
  /*This is for cancel/confirmation*/
  const [modal_standard, setmodal_standard] = useState(false);
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
  };

  //Modal  
  const [modal, setModal] = useState(false);
  const openModal = () => setModal(!modal);

  //OffCanvas  
  const [isOpen, setIsOpen] = useState(false);
  const [sideBar, setSideBar] = useState([]);

   //Dropdown
   const [dropdownOpen, setDropdownOpen] = useState(false);

   const toggledropDown = () => {
       setDropdownOpen(!dropdownOpen);
   };

  //Inquiry Process List is for Inquiry <Select> options
  const [inquiryProcessList, setInquiryProcessList] = useState([]);
  const navigate = useNavigate();

  //Data Source is used to Bind Data table
  const [dataSource, setDataSource] = useState(inquiryProcessList);
  ReactSession.setStoreType("sessionStorage");
  const [userName, setUserName] = useState();
  const [count, setCount] = useState(inquiryProcessList?.length + 1 || 1);

  const [updatable, setUpdatable] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const searchInput = useRef(null);
  const [editablecolumn, setEditableColumn] = useState(false);
  const [sortBy] = useState(1);
  const [isActive] = useState(true);
  const [exportTableData, setExportableData] = useState([]);
  const [imageFormData, setImageFormData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const date = new Date();

  const dateFormatLocal = (row_created_date) => {

    let newDate;

    if (row_created_date !== null && row_created_date !== undefined)
      newDate = moment(new Date()).format('DD/MMM/YYYY');
    
    return  newDate;

  };


  /**This function for get Inquiry list from database */
  const getInquiryFunc = async () => {
    try {

      let params = new URLSearchParams();
   
     const result = await GetInquiryProcessList();
      if (result?.data?.status === "SUCCESS") {
        let jsondata = result.data.data;
        setInquiryProcessList(jsondata);
        setDataSource(jsondata);
        setCount(jsondata?.length + 1);
      } else {
        //throw result?.message;
        errornotify(result?.data?.message);
      }
    } catch (error) {
      errornotify(error);
    }
  };
  useEffect(() => {
    getInquiryFunc();
  }, []);

  useEffect(() => {
    const list = document.querySelectorAll(".inquiry-list");
    const buttonGroups = document.querySelectorAll('.filter-button');
    for (let i = 0; i < buttonGroups.length; i++) {
        buttonGroups[i].addEventListener('click', onButtonGroupClick);
    }

    function onButtonGroupClick(event) {
        if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
            document.getElementById("list-view-button").classList.add("active");
            document.getElementById("grid-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.add("list-view-filter");
                el.classList.remove("grid-view-filter");
            });

        } else {
            document.getElementById("grid-view-button").classList.add("active");
            document.getElementById("list-view-button").classList.remove("active");
            list.forEach(function (el) {
                el.classList.remove("list-view-filter");
                el.classList.add("grid-view-filter");
            });
        }
    }
}, []);


  /** get session details*/

  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);

  /**This is for search columns */
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);

  /**This usestate for show hide and edit*/
  const [showAdd, setShowAdd] = useState(true);
  const [showEdit, setShowEdit] = useState(true);

  /**This function for column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  /**This is for reset filter*/
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };

  /**This function for result column search */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
 
  const tableRef = useRef(null);

  /**column*/
  const defaultColumns = [ 
    {
      title: "Inquiry_no",
      dataIndex: "Inquiry_no",
      width: 140,
      ...getColumnSearchProps("destination_name"),
      // sorter: (a, b) => a.destination_name.localeCompare(b.destination_name),
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      defaultSortOrder: "ascend",    
    }     
    
  ].filter((item) => !item.hidden);

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,       
      }),
    };
  });
  const onChange = (pagination, filters, sorter, extra) => {};
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);
  const manageColumnExcel = () => {
    exportTableData.map((item, index) => {
      delete item["key"];
      delete item["operation"];
      delete item["destination_id"];
      delete item["destination_type_id"];
      delete item["isEditing"];
      {
        rowCreatedBy && delete item["row_created_by"];
      }
      {
        rowCreatedDate && delete item["row_created_date"];
      }
      {
        rowModifiedBy && delete item["row_altered_by"];
      }
      {
        rowModifiedDate && delete item["row_altered_date"];
      }
    });
  };
  useEffect(() => {
    if (exportTableData.length > 0) {
      manageColumnExcel();
    }
  }, [exportTableData]);


  const navigateImportDestinations = () => {
    navigate('../importDestinations', {replace: true});
  };

  
     //buttonClicked:
     const handleStarClick = (e) => {
      e.stopPropagation();
      // your code here...
  }


  // const downloadExcel = () => {
  //   const worksheet = XLSX.utils.json_to_sheet(exportTableData);
  //   const workbook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  //   XLSX.writeFile(workbook, "DestinationTable.xlsx");
  // };
  return (

  <React.Fragment>

  <div className="page-content">
      <Container fluid>
        
         <BreadCrumb
            title="Inquiry Process"
            pageTitle="Inquiry"
            isSubTitle={true}           
          />
          

          <Card>
                <CardBody>
                    <Row className="g-2">
                        <Col sm={4}>
                            <div className="search-box">
                                <Input type="text" className="form-control" placeholder="Search for name, tasks, projects or something..." />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </Col>
                        <Col className="col-sm-auto ms-auto">
                            <div className="list-grid-nav hstack gap-1">

                                <Button color="info" id="grid-view-button" className="btn btn-soft-info nav-link btn-icon fs-14 filter-button"><i className="ri-grid-fill"></i></Button>
                                <Button color="info" id="list-view-button" className="btn btn-soft-info nav-link  btn-icon fs-14 active filter-button"><i className="ri-list-unordered"></i></Button>
                                <Dropdown
                                    isOpen={dropdownOpen}
                                    toggle={toggledropDown}>
                                    <DropdownToggle type="button" className="btn btn-soft-info btn-icon fs-14">
                                        <i className="ri-more-2-fill"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <li><Link className="dropdown-item" to="#">All</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Week</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Month</Link></li>
                                        <li><Link className="dropdown-item" to="#">Last Year</Link></li>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color="success"
                                    onClick={openModal}>
                                    <i className="ri-add-fill me-1 align-bottom"></i> Add Members
                                </Button>
                        
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <UncontrolledAccordion
                defaultOpen={[
                  '1'                  
                ]}
                stayOpen
                className="custom-accordionwithicon custom-accordion-border accordion-border-box accordion-primary accordion-icon-none "
              >
                  {(inquiryProcessList || []).map((item, key) => (
                              <AccordionItem className="custom-accordionwithicon accordion-fill-light">
                                <AccordionHeader targetId={key} className={ "my-1"}>                               
          
                                        <Row xl={12}>
                                              <Col xl={1} s={1} className="border-end border-end-dashed">
                                                  <div className="d-flex flex-row bd-highlight">
                                                      <div className="p-1 bd-highlight"> 
                                                          <Rating
                                                              stop={1}
                                                              emptySymbol="mdi mdi-star-outline text-muted "
                                                              fullSymbol="mdi mdi-star text-warning "
                                                              onClick={ (e) => handleStarClick(e) }

                                                          />
                                                          <Input type="checkbox" id="favourite1" className="bookmark-input bookmark-hide" />
                                                          <Label htmlFor="favourite1" className="btn-star">
                                                              <svg width="20" height="20">
                                                              </svg>
                                                          </Label>
                                                      </div>
                                                      <div className="p-1 bd-highlight">
                                                          <UncontrolledDropdown direction='end' className="col text-end">
                                                            <DropdownToggle tag="a" id="dropdownMenuLink2" role="button">
                                                                <i className="ri-more-fill fs-17"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu>
                                                                <DropdownItem><i className="ri-eye-line me-2 align-middle" />View</DropdownItem>
                                                                <DropdownItem><i className="ri-star-line me-2 align-middle" />Favorites</DropdownItem>
                                                                <DropdownItem><i className="ri-delete-bin-5-line me-2 align-middle" />Delete</DropdownItem>
                                                            </DropdownMenu>
                                                          </UncontrolledDropdown>
                                                      </div>
                                                      
                                                  </div>   
                                              </Col>
                                              <Col xl={2} s={2}>
                                                    <Link to="#" onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><h6 className="fs-14 mb-1 text-decoration-underline">#{item.inquiry_no}</h6></Link>
                                                    <p className="text-muted mb-0">  {dateFormatLocal(item.inquiry_date)}  </p>
                                                    {/* <p>{item.source_code} </p> */}
                                              </Col>
                                              <Col xl={2} s={2}>
                                                    <h6 className="mb-1">{item.contact.name}</h6>
                                                    <p className="text-muted mb-0">{item.contact.phone_country_code}{item.contact.phone}</p>
                                                    {/* <p className="text-muted mb-0">{item.contact.email}</p>                                                   */}
                                              </Col>
                                              <Col xl={1} s={1}>                                                     
                                                    <h6 className="mb-1">Prospect</h6>
                                                      <ProspectBadge prospectCode = {item.prospect_code} />                                                    
                                              </Col>
                                              <Col xl={1} s={1}>                                                     
                                                    <h6 className="mb-1">Status</h6>
                                                    <StatusBadge statusCode = {item.status_code} />                                                    
                                              </Col>
                                              <Col xl={1} s={1}>
                                                      <h6 className="mb-1">Destination</h6>
                                                      <p className="text-muted mb-0">{item.destination_name}</p>
                                                      {/* <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date} </p> */}
                                              </Col>
                                              <Col xl={2} s={2}>
                                                      <h6 className="mb-1">Travel Dates</h6>
                                                      <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date}</p>
                                                      {/* <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date} </p> */}
                                              </Col>
                                              <Col xl={2} s={2}>
                                                      <h6 className="mb-1">Assign To</h6>
                                                      <p className="text-muted mb-0">{item.allocated_to_user_full_nme}</p>
                                                      {/* <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date} </p> */}
                                              </Col>
                                        </Row>   
                                                             
                                </AccordionHeader>
                                <AccordionBody accordionId={key}>
                                
                                <Row className="text-muted" style={{width : "100%"}}>
                                              <Col l={3} s={4}>
                                                <Row>
                                                    
                                                </Row>

                                                  {/* <div className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">                                                                 
                                                      <img src={`${url}/Images/Destination/${item.destination_image}`} alt="" className="img-fluid d-block rounded-circle" />
                                                  </div> */}
                                              </Col>
                                              <Col l={3} s={4}>
                                                    <Link to="#" onClick={() => { setIsOpen(!isOpen); setSideBar(item); }}><h6 className="fs-14 mb-1 text-decoration-underline">#{item.inquiry_no}</h6></Link>
                                                    <p className="text-muted mb-0">  {dateFormatLocal(item.inquiry_date)}  </p>
                                                    {/* <p>{item.source_code} </p> */}
                                              </Col>
                                              <Col l={3} s={4}>
                                                    <h6 className="mb-1">{item.contact.name}</h6>
                                                    <p className="text-muted mb-0">{item.contact.phone_country_code}{item.contact.phone}</p>
                                                    {/* <p className="text-muted mb-0">{item.contact.email}</p>                                                   */}
                                              </Col>
                                              <Col l={3} s={4}>                                                     
                                                    <h6 className="mb-1">Prospect</h6>
                                                      <ProspectBadge prospectCode = {item.prospect_code} />                                                    
                                              </Col>
                                              <Col l={3} s={4}>                                                     
                                                    <h6 className="mb-1">Status</h6>
                                                    <StatusBadge statusCode = {item.status_code} />                                                    
                                              </Col>
                                              <Col l={2} s={4}>
                                                      <h6 className="mb-1">Destination</h6>
                                                      <p className="text-muted mb-0">{item.destination_name}</p>
                                                      {/* <p className="text-muted mb-0">{item.travel_from_date} to {item.travel_to_date} </p> */}
                                              </Col>
                                        </Row>   

                                </AccordionBody>
                              </AccordionItem>

                              
                  ))}
            </UncontrolledAccordion>  

        </Container>
    </div>



</React.Fragment>

  );
};

export default InquiryProcess3;
