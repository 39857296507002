import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  FormFeedback,
  Input,
  Button,
} from "reactstrap";
import AuthSlider from "../AuthCarousel";
//formik
import { useFormik } from "formik";
import { ReactSession } from "react-client-session";
import * as Yup from "yup";
import {
  getUserAndCompany,
  loginWithSocial,
  saveCompany,
  signup,
} from "../../services/User/userService";
import { appName } from "../../common/applicationName";
import SignuporsigninErrorMessage from "../../common/SignuporsigninErrorMessage";
import LoadingButton from "../../common/LoadingButton";
import { errornotify } from "../../components/Common/notification";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { faceBookAppId, applicationType } from "../../services/common/const";
import axios from "axios";
import { setLocalStorageItem, setSession } from "../../services/common/session";
import { ToastContainer } from "react-toastify";
const Signup = () => {
  document.title = "SignUp | TravelNinjazB2B";

  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmpasswordShow, setConfirmPasswordShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const signupUser = async (user) => {
    setIsLoading(true);
    try {
      const response = await signup({
        FirstName: user.firstname,
        LastName: user.lastname,
        // Company_name: user.companyname,
        Phone: user.contactnumber.toString(),
        UserName: user.email,
        // Company_gst_no: user.gstno,
        Password: user.password,
        ConfirmPassword: user.confirmpassword,
        Role: "Admin",
        Email: user.email,
        Operation: "ADD",
        Social_media_type: "APPLICATION",
        ApplicationType: applicationType,
        Row_created_by: `${user.firstname} ${user.lastname}`,
        Row_altered_by: `${user.firstname} ${user.lastname}`,
      });
      if (response?.status === "SUCCESS") {
        /**This is for save company details */
        const responseCompany = await saveCompany({
          Company_name: user.companyname,
          Company_gst_no: user.gstno,
          Operation: "ADD",
          Is_active: true,
          Row_created_by: user.email,
          Row_altered_by: user.email,
          AspNetUserId: response.aspNetUserId,
        });
        if (responseCompany?.status === "SUCCESS") {
          navigate("/registrationsuccess");
          setErrorMessage(undefined);
        } else {
          setErrorMessage(response?.message);
        }
      } else {
        setErrorMessage(response?.message);
      }
    } catch (error) {
      setErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  /**This variable for contact number validation */
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: "",
      lastname: "",
      password: "",
      confirmpassword: "",
      companyname: "",
      contactnumber: null,
      email: "",
      gstno: "",
      conditioncheck: false,
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(RegExp("(.*[a-z].*)"), "At least lowercase letter")
        .matches(RegExp("(.*[A-Z].*)"), "At least uppercase letter")
        .matches(RegExp("(.*[0-9].*)"), "At least one number")
        .required("Password is required"),
      confirmpassword: Yup.string()
        .oneOf(
          [Yup.ref("password"), null],
          "Password and Confirm Password are not matched"
        )
        .required("Confirm Password is required"),
      companyname: Yup.string().required("Company Name is required"),
      firstname: Yup.string().max(20).min(3).required("First Name is required"),
      lastname: Yup.string().max(20).min(3).required("Last Name is required"),
      email: Yup.string().email().required("Email Id is required"),
      contactnumber: Yup.string()
        .matches(phoneRegExp, "Contact Number is not valid")
        .required("Contact Number is not valid"),
    }),
    onSubmit: (values) => {
      signupUser(values);
      // ReactSession.setStoreType("sessionStorage");
      // ReactSession.set("fullname", values?.fullname);
      // ReactSession.set("username", values?.email);
      // navigate("/");
    },
  });
  /**login with google */
  const loginWithgoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      try {
        const response = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
            },
          }
        );
        const {
          sub,
          name,
          given_name,
          family_name,
          picture,
          email,
          email_verified,
          local,
        } = response.data;
        const responseLogin = await loginWithSocial({
          Email: email,
          Name: name,
          Email_verified: email_verified,
          Role: "Admin",
          FirstName: given_name,
          LastName: family_name,
          Operation: "ADD",
          Social_media_type: "GOOGLE",
          ApplicationType: applicationType,
        });
        if (responseLogin?.status === "SUCCESS") {
          const responseCompany = await saveCompany({
            Company_name: "",
            Company_gst_no: "",
            Operation: "ADD",
            Is_active: true,
            Row_created_by: responseLogin.email,
            Row_altered_by: responseLogin.email,
            AspNetUserId: responseLogin.userKey,
          });
          if (responseCompany?.status === "SUCCESS") {
            let params = new URLSearchParams();
            params.append("AspNetUserId", responseLogin?.userKey);
            const response = await getUserAndCompany(params);
            if (response?.data?.status === "SUCCESS") {
              // setCurrencySymbol(response.data.data.usercompany.currencyUnit);
              setLocalStorageItem(
                "currencySymbol",
                response?.data?.data?.usercompany?.currencyUnit
              );
              setSession("userDetails", responseLogin);
              navigate("/");
            } else {
              setErrorMessage(response?.data?.message);
            }
          } else {
            setErrorMessage(responseCompany?.message);
          }
        } else {
          // errornotify();
          setErrorMessage(responseLogin?.message);
        }
      } catch (error) {
        setErrorMessage(error);
      }
    },
  });
  /**login with facebook */
  const responseFacebook = async (response) => {
    try {
      const { email, first_name, last_name, name } = response;
      const responseLogin = await loginWithSocial({
        Email: email,
        Name: name,
        Email_verified: true /**This type manualy because not get yet resoponsefacebook */,
        Role: "Admin",
        FirstName: first_name,
        LastName: last_name,
        Operation: "ADD",
        Social_media_type: "FACEBOOK",
        ApplicationType: applicationType
      });
      if (responseLogin?.status === "SUCCESS") {
        const responseCompany = await saveCompany({
          Company_name: "",
          Company_gst_no: "",
          Operation: "ADD",
          Is_active: true,
          Row_created_by: responseLogin.email,
          Row_altered_by: responseLogin.email,
          AspNetUserId: responseLogin.userKey,
        });
        if (responseCompany?.status === "SUCCESS") {
          let params = new URLSearchParams();
          params.append("AspNetUserId", responseLogin?.userKey);
          const response = await getUserAndCompany(params);
          if (response?.data?.status === "SUCCESS") {
            // setCurrencySymbol(response.data.data.usercompany.currencyUnit);
            setLocalStorageItem(
              "currencySymbol",
              response?.data?.data?.usercompany?.currencyUnit
            );
            setSession("userDetails", responseLogin);
            navigate("/");
          } else {
            setErrorMessage(response?.data?.message);
          }
        } else {
          setErrorMessage(responseCompany?.message);
        }
      } else {
        setErrorMessage(responseLogin?.message);
      }
    } catch (error) {
      // console.log(error);
      setErrorMessage(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
        <div className="bg-overlay"></div>
        <div className="auth-page-content overflow-hidden pt-lg-5">
          <Container>
            <Row>
              <Col lg={12}>
                <Card className="overflow-hidden m-0">
                  <Row className="justify-content-center g-0">
                    <AuthSlider />

                    <Col lg={7}>
                      <div className="p-lg-5 p-4">
                        <div>
                          <h5 className="text-primary">Register Account</h5>
                          <p className="text-muted">
                            Get your Free TravelNinjaz B2B account now.
                          </p>
                        </div>

                        <div className="mt-4">
                          <Form
                            onSubmit={validation.handleSubmit}
                            className="needs-validation"
                            noValidate
                            action="index"
                          >
                            <div className="d-flex flex-lg-row justify-content-between flex-md-column flex-sm-column customcolumn w-100">
                              <div className="mb-3 w-50 customboxleft">
                                <label
                                  htmlFor="firstname"
                                  className="form-label"
                                >
                                  First Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="firstname"
                                  placeholder="Enter First Name"
                                  name="firstname"
                                  value={validation.values.fullname}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.firstname &&
                                    validation.touched.firstname
                                      ? true
                                      : false
                                  }
                                  required
                                />
                                {validation.errors.firstname &&
                                validation.touched.firstname ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.firstname}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3 w-50  customboxright">
                                <label
                                  htmlFor="lastname"
                                  className="form-label"
                                >
                                  Last Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="lastname"
                                  placeholder="Enter Last Name"
                                  name="lastname"
                                  value={validation.values.lastname}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.lastname &&
                                    validation.touched.lastname
                                      ? true
                                      : false
                                  }
                                  required
                                />
                                {validation.errors.lastname &&
                                validation.touched.lastname ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lastname}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-lg-row justify-content-between flex-md-column flex-sm-column customcolumn w-100">
                              <div className="mb-3 w-50 customboxleft">
                                <label
                                  htmlFor="useremail"
                                  className="form-label"
                                >
                                  Email Id{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="email"
                                  className="form-control"
                                  id="useremail"
                                  placeholder="Enter Email Id"
                                  name="email"
                                  value={validation.values.email}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.email &&
                                    validation.touched.email
                                      ? true
                                      : false
                                  }
                                  required
                                />
                                {validation.errors.email &&
                                validation.touched.email ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.email}
                                  </FormFeedback>
                                ) : null}
                              </div>
                              <div className="mb-3 w-50  customboxright">
                                <label
                                  htmlFor="contactnumber"
                                  className="form-label"
                                >
                                  Contact Number{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="contactnumber"
                                  placeholder="Enter Contact Number"
                                  name="contactnumber"
                                  value={validation.values.contactnumber}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.contactnumber &&
                                    validation.touched.contactnumber
                                      ? true
                                      : false
                                  }
                                  required
                                />
                                {validation.errors.contactnumber &&
                                validation.touched.contactnumber ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.contactnumber}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </div>
                            <div className="d-flex flex-lg-row flex-md-column justify-content-between flex-sm-column customcolumn">
                              <div className="mb-3 w-50 customboxleft">
                                <label
                                  htmlFor="companyname"
                                  className="form-label"
                                >
                                  Company Name{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="companyname"
                                  placeholder="Enter Company Name"
                                  required
                                  name="companyname"
                                  value={validation.values.companyname}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                  invalid={
                                    validation.errors.companyname &&
                                    validation.touched.companyname
                                      ? true
                                      : false
                                  }
                                />
                                {validation.errors.companyname &&
                                validation.touched.companyname ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.companyname}
                                  </FormFeedback>
                                ) : null}
                              </div>

                              <div className="mb-3 w-50  customboxright">
                                <label
                                  htmlFor="gstnumber"
                                  className="form-label"
                                >
                                  GST No.(Optional)
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="gstnumber"
                                  placeholder="Enter GST number"
                                  name="gstno"
                                  value={validation.values.gstno}
                                  onBlur={validation.handleBlur}
                                  onChange={validation.handleChange}
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-lg-row justify-content-between flex-md-column flex-sm-column flex-xs-column customcolumn">
                              <div className="mb-3 w-50 customboxleft">
                                <label
                                  className="form-label"
                                  htmlFor="password-input"
                                >
                                  Password
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    type={passwordShow ? "text" : "password"}
                                    className="form-control pe-5 password-input"
                                    placeholder="Enter Password"
                                    id="password-input"
                                    name="password"
                                    value={validation.values.password}
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                      validation.errors.password &&
                                      validation.touched.password
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.errors.password &&
                                  validation.touched.password ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.password}
                                    </FormFeedback>
                                  ) : null}
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      setPasswordShow(!passwordShow)
                                    }
                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </Button>
                                </div>
                              </div>
                              <div className="mb-3 w-50  customboxright">
                                <label
                                  className="form-label"
                                  htmlFor="confirmpassword-input"
                                >
                                  Confirm Password
                                  <span className="text-danger mx-1">*</span>
                                </label>
                                <div className="position-relative auth-pass-inputgroup">
                                  <Input
                                    type={
                                      confirmpasswordShow ? "text" : "password"
                                    }
                                    className="form-control pe-5 password-input"
                                    placeholder="Enter Confirm Password"
                                    id="confirmpassword-input"
                                    name="confirmpassword"
                                    value={validation.values.confirmpassword}
                                    onBlur={validation.handleBlur}
                                    onChange={validation.handleChange}
                                    invalid={
                                      validation.errors.confirmpassword &&
                                      validation.touched.confirmpassword
                                        ? true
                                        : false
                                    }
                                  />
                                  {validation.errors.confirmpassword &&
                                  validation.touched.confirmpassword ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.confirmpassword}
                                    </FormFeedback>
                                  ) : null}
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      setConfirmPasswordShow(
                                        !confirmpasswordShow
                                      )
                                    }
                                    className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                                    type="button"
                                    id="password-addon"
                                  >
                                    <i className="ri-eye-fill align-middle"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                            <div className="mb-4 d-flex ">
                              <Input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="conditioncheck"
                                id="SwitchCheck3"
                                value={validation.values.conditioncheck}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange}
                              />
                              <p className="mb-0 px-1 fs-12 text-muted fst-italic">
                                By registering you agree to the TravelNinjaz B2B{" "}
                                <Link
                                  to="#"
                                  className="text-primary text-decoration-underline fst-normal fw-medium"
                                >
                                  Terms of Use
                                </Link>
                              </p>
                            </div>

                            <div
                              id="password-contain"
                              className="p-3 bg-light mb-2 rounded"
                            >
                              <h5 className="fs-13">Password must contain:</h5>
                              <p
                                id="pass-length"
                                className="invalid fs-12 mb-2"
                              >
                                Minimum <b>8 characters</b>
                              </p>
                              <p id="pass-lower" className="invalid fs-12 mb-2">
                                At <b>lowercase</b> letter (a-z)
                              </p>
                              <p id="pass-upper" className="invalid fs-12 mb-2">
                                At least <b>uppercase</b> letter (A-Z)
                              </p>
                              <p
                                id="pass-number"
                                className="invalid fs-12 mb-0"
                              >
                                A least <b>number</b> (0-9)
                              </p>
                            </div>

                            <div className="mt-4">
                              {isLoading ? (
                                <LoadingButton
                                  btnText={"Sign Up"}
                                  externalClass={"success w-100"}
                                  color={"success"}
                                />
                              ) : (
                                <button
                                  className="btn btn-success w-100"
                                  type="submit"
                                  disabled={!validation.values.conditioncheck}
                                >
                                  Sign Up
                                </button>
                              )}
                            </div>
                            {errorMessage !== undefined ? (
                              <SignuporsigninErrorMessage
                                message={errorMessage}
                              />
                            ) : (
                              ""
                            )}
                            <div className="mt-4 text-center">
                              <div className="signin-other-title">
                                <h5 className="fs-13 mb-4 title">
                                  Sign Up with
                                </h5>
                              </div>

                              <div>
                                <FacebookLogin
                                  appId={faceBookAppId}
                                  autoLoad={false}
                                  fields="name,email,picture,first_name, last_name"
                                  callback={responseFacebook}
                                  cssClass="my-facebook-button-class"
                                  icon="fa-facebook facebookIconStyle"
                                  render={(renderProps) => (
                                    <Button
                                      color="primary"
                                      className="btn-icon me-1"
                                      onClick={renderProps.onClick}
                                    >
                                      <i className="ri-facebook-fill fs-16"></i>
                                    </Button>
                                  )}
                                />
                                <Button
                                  color="danger"
                                  className="btn-icon me-1"
                                  onClick={() => loginWithgoogle()}
                                >
                                  <i className="ri-google-fill fs-16"></i>
                                </Button>
                              </div>
                            </div>
                          </Form>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="mb-0">
                            Already have an account ?{" "}
                            <Link
                              to="/login"
                              className="fw-semibold text-primary text-decoration-underline"
                            >
                              Signin
                            </Link>
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        {/* <footer className="footer">
          <Container>
            <div className="row">
              <div className="col-lg-12">
                <div className="text-center">
                  <p className="mb-0">
                    {new Date().getFullYear()} TravelNinjaz B2B. with{" "}
                    <i className="mdi mdi-heart text-danger"></i> by <b>THE</b>
                    Digitalogic
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </footer> */}
      </div>
    </>
  );
};

export default Signup;
