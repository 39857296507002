import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  errornotify,
  successNotify,
} from "../Common/notification";
import Select from "react-select";
import { Col, Row } from 'reactstrap';
import makeAnimated from "react-select/animated";

const DestinationTypeSelectList = ({destinationTypeSelected}) => {

const animatedComponents = makeAnimated();

const [showDestinationTypeSelectList, setShowDestinationTypeSelectList] = useState(localStorage.getItem("global_show_destination_type") =="true" ? true: false);
const [destinationTypeList, setDestinationTypeList] = useState(JSON.parse(localStorage.getItem("global_destinations_type_list")));
const [destinationTypeOptionSelected, setDestinationTypeOptionSelected] = useState(destinationTypeSelected);
const [destinationTypeOptions, setDestinationTypeOptions] = React.useState([]);

const handleDestinationTypeChange = (
    destination_type,
  ) => {

  if(destination_type !=null)
  {
   const global_default_destination_type_object= destinationTypeList.filter(item => item.destination_type_id ===destination_type.value);
   localStorage.setItem("global_default_destination_type_object", JSON.stringify(global_default_destination_type_object[0]));
   localStorage.setItem("global_search_destination_type_option", JSON.stringify(destination_type));

   setDestinationTypeOptionSelected(destination_type);
  }
  else{
    localStorage.setItem("global_search_destination_type_option", null);
    setDestinationTypeOptionSelected(null);    
  }
  };


  const GetDestinationTypeListFunc = () => {

   // debugger;

  // var tmp =  destinationTypeOptionSelected;


    /* Destination Types*/
    if (destinationTypeList?.length > 0) {

      let destinationTypeOptionTemp = [];
    
      destinationTypeList.map((item, index) => {       
        destinationTypeOptionTemp.push({
          label: item.destination_type_name,
          value: item.destination_type_id,
        });
      });

      setDestinationTypeOptions(destinationTypeOptionTemp); 
    }
    /************** Destination Types */  
};

  useEffect(() => {
     GetDestinationTypeListFunc();   

    //  if(localStorage.getItem("global_search_destination_type_option") != null)
    //     {
    //      const selectedDestinationType = JSON.parse(localStorage.getItem("global_search_destination_type_option"));
    //      setSelectedDestinationType(selectedDestinationType);
    //     }

  }, []);
  
  return (
    <>

    {(showDestinationTypeSelectList == "true" || showDestinationTypeSelectList == true )? (

    <>
    <Select 
        id={"destinationType"}
        name={"destinationType"}                
        value={destinationTypeOptionSelected} 
        //defaultValue={destinationTypeOptionSelected}
        onChange={handleDestinationTypeChange} 
        options={destinationTypeOptions}           
        placeholder = {"Destination Type..."}
        components={animatedComponents}
        isDisabled = {true}
    />                   

    </>



    ) :  <>  </>
    }                 
          
         
       
    </>
  );
};

export default DestinationTypeSelectList;
