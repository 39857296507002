import { url } from "../common/const";
import { get, post,post_common } from "../common/https";
/**This api for get Hotel Room list*/
export const GetHotelRoomList = (params) => {
  const getdata = get(`${url}/api/HotelMealPlan/list?` + params);
  return getdata;
};
/**This api for save Hotel Room list*/
export const SaveHotelRoomList = (data) =>
  post(`${url}/api/HotelMealPlan/save`, data);

  
export const importHotelRoomsFileData = (data) =>
post_common(`${url}/api/HotelMealPlan/ImportHotelRoomsFileData`, data);

export const getImportHotelRoomsList = (params) => {
const getdata = get(`${url}/api/HotelMealPlan/GetImportHotelRoomsList?` + params);
return getdata;
};

export const publishImportedHotelRooms= (data) =>
post_common(`${url}/api/HotelMealPlan/PublishImportedHotelRooms`, data);

export const cancelImportedHotelRooms = (data) =>
post_common(`${url}/api/HotelMealPlan/CancelImportedHotelRooms`, data);




