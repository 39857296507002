import React from "react";
import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import ButttonTravelNinjaz from "../Common/GloablMaster/ButttonTravelNinjaz";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

const QuillTextEditor = ({
  editorId, 
  editorName,
  editorHTML,
  className,
  onChangeQuillEditorHandller,  
}) => {

  const { quill, quillRef } = useQuill();

  React.useEffect(() => {

    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(editorHTML);

      quill.on('text-change', (delta, oldDelta, source) => {
        console.log('Text change!');
        console.log(quill.getText()); // Get text only
        console.log(quill.getContents()); // Get delta contents
        console.log(quill.root.innerHTML); // Get innerHTML using quill
        console.log(quillRef.current.firstChild.innerHTML); // Get innerHTML using quillRef

        onChangeQuillEditorHandller(editorId, editorName, quill);
      });
    }
  }, [quill]);

  return (        

      <div className="snow-editor" style={{ height: 300 }}>
        <div ref={quillRef}  />
      </div>
    
  );
};

export default QuillTextEditor;
