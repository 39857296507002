import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const DestinationSelectList = ({destinationSelected}) => {

const animatedComponents = makeAnimated();

const [showDestinationSelectList, setShowDestinationSelectList] = useState(localStorage.getItem("global_show_destination") =="true" ? true: false);
const [destinationList, setDestinationList] = useState( JSON.parse(localStorage.getItem("global_destinations_list")));
const [destinationOptionSelected, setDestinationOptionSelected] = useState(destinationSelected);
const [destinationOptions, setDestinationOptions] = React.useState([]);

const handleDestinationChange = (destination) => {

  if(destination !=null)
{
  const selected_destination= destinationList.filter(item => item.destination_id ===destination.value);
  localStorage.setItem("global_default_destination_object", JSON.stringify(selected_destination[0]));
  localStorage.setItem("global_search_destination_option", JSON.stringify(destination));
  setDestinationOptionSelected(destination);
  
}
else{
  localStorage.setItem("global_search_destination_option", null);
  setDestinationOptionSelected(null);
 
}

  localStorage.setItem("global_search_city_option", null);

};

const GetDestinationListFunc = () => {
  
  /* Destinations for types*/
  let global_default_destination_type_object;
  let filteredDestinationList ;
  if(localStorage.getItem("global_default_destination_type_object") != null && localStorage.getItem("global_default_destination_type_object") != "")
  {
    global_default_destination_type_object = JSON.parse(localStorage.getItem("global_default_destination_type_object") ?? "");
    filteredDestinationList = destinationList.filter(item => 
                                                        item.destination_type_id ===global_default_destination_type_object.destination_type_id
                                                    );
  }
  else{
    global_default_destination_type_object = 0;
    filteredDestinationList = destinationList;
  }

  if (filteredDestinationList?.length > 0) {
      
    let destinationOptionTemp = [];

    filteredDestinationList.map((item, index) => {
      destinationOptionTemp.push({
        label: item.destination_name,
        value: item.destination_id,
      });
    }); 

    setDestinationOptions(destinationOptionTemp);
   }       

  
   /************** Destinations for types */

};

  useEffect(() => {
    GetDestinationListFunc();     
  }, []);
  
  return (
    <>
          { (showDestinationSelectList  == "true" || showDestinationSelectList == true) ? (
     
                <Select 
                    id={"destination"}
                    name={"destination"}
                    isClearable={true}
                    value={destinationOptionSelected} 
                    onChange={handleDestinationChange} 
                    options={destinationOptions}
                    styles={{width : "300px"}} 
                    placeholder = {"Destination..."}
                    isDisabled = {true}
                />


          ) :  <>  </>

    }      
       
    </>
  );
};

export default DestinationSelectList;
