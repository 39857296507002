import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import BreadCrumb from "../../components/Common/BreadCrumb";
import Dropzone from "react-dropzone";
import {
  downloadTemplate,
  getTravellingCompany,
  uploadXlsxfiles,
} from "../../services/MarketingMenu";
import ButttonTravelNinjaz from "../../components/Common/GloablMaster/ButttonTravelNinjaz";
import { Table } from "antd";
import LoadingButton from "../../common/LoadingButton";
import {
  errornotify,
  successNotify,
} from "../../components/Common/notification";
import UseAuth from "../../components/UseAuth";
const UploadFiles = () => {
  UseAuth();
  const [selectedFiles, setselectedFiles] = useState(undefined);
  const [selectedFileName, setSelectedFileName] = useState(undefined);
  const [dataSource, setDataSource] = useState([]);
  const [uploadLoader, setUploadLoader] = useState(false);
  const [value, setValue] = useState("");
  const ref = useRef();

  const getTravellingCompanyData = async () => {
    try {
      const response = await getTravellingCompany();
      if (response?.data?.status === "SUCCESS") {
        setDataSource(response.data.data);
      } else {
        throw response.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**This is for get data */
  useEffect(() => {
    getTravellingCompanyData();
  }, []);

  /**This function for download template handller */
  const downdloadTemplateHandller = async () => {
    try {
      let params = new URLSearchParams();
  
      params.append("InterfaceName", "Travelling_company");
      const response = await downloadTemplate(params);
      if (response?.status === "SUCCESS") {
        successNotify(response?.message);
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    }
  };
  const columns = [
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Mobile 1",
      dataIndex: "mobile_1",
      key: "mobile_1",
    },
    {
      title: "Mobile 2",
      dataIndex: "mobile_2",
      key: "mobile_2",
    },
    {
      title: "Email Id 1",
      dataIndex: "email_id_1",
      key: "email_id_1",
    },
    {
      title: "Email Id 2",
      dataIndex: "email_id_2",
      key: "email_id_2",
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
    },
    {
      title: "Adress",
      dataIndex: "address",
      key: "address",
      render: (text, record) => {
        return (
          <>
            <UncontrolledTooltip
              placement="top"
              target={`tooltipId${record.package_id}`}
            >
              {record.address}
            </UncontrolledTooltip>
            <p
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "150px",
              }}
              id={`tooltipId${record.package_id}`}
            >
              {record.address}
            </p>
          </>
        );
      },
    },

    // {
    //   title: "LandLine",
    //   dataIndex: "landLine",
    //   key: "landLine",
    // },
    // {
    //   title: "Date",
    //   dataIndex: "date",
    //   key: "date",
    // },
    // {
    //   title: "Remarks",
    //   dataIndex: "remarks",
    //   key: "remarks",
    // },
  ];
  // function formatBytes(bytes, decimals = 2) {
  //   if (bytes === 0) return "0 Bytes";
  //   const k = 1024;
  //   const dm = decimals < 0 ? 0 : decimals;
  //   const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  //   const i = Math.floor(Math.log(bytes) / Math.log(k));
  //   return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  // }
  // async function handleAcceptedFiles(files) {
  //   files.map((file) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );
  //   try {
  //     const formData = new FormData();
  //     formData.append("file", files[0], files[0].name);
  //     await uploadXlsxfiles(formData);
  //     setselectedFiles(files);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  /**This is on change handller */
  const onChageHandller = (e) => {
    setSelectedFileName(e.target.files[0].name);
    setselectedFiles(e.target.files[0]);
    setValue(e.target.value);
  };
  /***This is submitButtonHandller  import file*/
  const submitHandller = async () => {
    setUploadLoader(true);
    try {
      const formData = new FormData();
      formData.append("file", selectedFiles, selectedFileName);
      const response = await uploadXlsxfiles(formData);
      if (response?.status === "SUCCESS") {
        successNotify(response?.message);
        /**This is for getting data */
        getTravellingCompanyData();
        /**This is selection clear */
        setSelectedFileName(null);
        setselectedFiles(null);
        ref.current.value = "";
        setValue("");
      } else {
        throw response?.message;
      }
    } catch (error) {
      errornotify(error);
    } finally {
      setUploadLoader(false);
    }
  };
  /**This is cancel button handller */
  const cancelButtonHandller = async () => {
    setSelectedFileName(null);
    setselectedFiles(null);
    ref.current.value = "";
    setValue("");
  };
  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Upload Files"
            pageTitle="Marketing"
            isSubTitle={true}
          />
          <Card>
            <CardBody>
              <div id="customerList">
                <Row className="g-4 mb-3">
                  <Col lg={4}>
                    <Input
                      className="form-control"
                      id="formSizeDefault"
                      type="file"
                      accept=".xlsx,.csv"
                      onChange={onChageHandller}
                      ref={ref}
                      value={value}
                    />
                  </Col>
                  <Col lg={6}>
                    {uploadLoader ? (
                      <LoadingButton
                        color={"primary"}
                        btnText={"Submit"}
                        externalClass={"add-btn"}
                      />
                    ) : (
                      <ButttonTravelNinjaz
                        backGroundColor="primary"
                        className="add-btn"
                        id="create-btn"
                        buttonText="Submit"
                        onCLickHancller={submitHandller}
                      />
                    )}
                    <ButttonTravelNinjaz
                      // backGroundColor="primary"
                      style={{ backgroundColor: "#CC563D", border: "none" }}
                      className="add-btn mx-2"
                      id="create-btn"
                      buttonText="Cancel"
                      type={"cancel"}
                      onCLickHancller={cancelButtonHandller}
                    />
                  </Col>
                  <Col lg={2}>
                    <ButttonTravelNinjaz
                      backGroundColor="primary"
                      className="add-btn"
                      id="create-btn"
                      buttonText="Download template"
                      type={"submit"}
                      onCLickHancller={downdloadTemplateHandller}
                    />
                  </Col>
                  {/* <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                    multiple={false}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone dz-clickable">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h4>
                            Drop Xlsx or Csv file here or click to upload.
                          </h4>
                        </div>
                      </div>
                    )}
                  </Dropzone> */}
                </Row>
              </div>
              {/* <Row className="my-2">
                <Col lg={12}> */}
              <div className="tableCustom">
                <Table
                  // rowClassName={() => "editable-row"}
                  bordered
                  dataSource={dataSource}
                  scroll={{
                    y: 350,                    
                  }}
                  columns={columns}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: [
                      10, 20, 50, 100,
                      // dataSource?.length,
                    ],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items  `,
                  }}
                  // onChange={onChange}
                  locale={{
                    triggerDesc: null,
                    triggerAsc: null,
                    cancelSort: null,
                  }}
                />
              </div>
              {/* </Col>
              </Row> */}
            </CardBody>
          </Card>
        </Container>
      </div>
    </>
  );
};

export default UploadFiles;
