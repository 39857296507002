import React from "react";

const ProspectBadge = 
({
    prospectCode     
}) => {


  return (  
    <>
        {(() => {
          switch (prospectCode) {
            case "HOT":   return <span className="fs-10 badge bg-success"> {prospectCode} </span>;
            case "WARM": return <span className="fs-10 badge bg-danger"> {prospectCode} </span>;
            case "COLD":  return <span className="fs-10 badge bg-info">  {prospectCode} </span>;
            case "DEAD":  return <span className="fs-10 badge bg-primary">  {prospectCode} </span>;
            default:      return "";
          }
        })()}
    </>
  );

}
  


   

export default ProspectBadge;