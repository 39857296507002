import { url } from "../common/const";
import { get, post } from "../common/https";

/**This api for get PickupAndDrop list*/
export const GetPickupAndDropLocationList = () => {
  const getdata = get(`${url}/api/PickupAndDropLocation/List`);
  return getdata;
};
/**This api for save PickupAndDrop list*/
export const SavePickupAndDropLocation = (data) =>
  post(`${url}/api/PickupAndDropLocation/Save`, data);
