import React, {useState, useEffect, } from "react";
import { Button,Card,CardBody,CardHeader,Col,Container,Row,Input,Modal,Label,ModalBody,UncontrolledDropdown,
    Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Form,  Offcanvas, OffcanvasBody, OffcanvasHeader, UncontrolledTooltip,  FormFeedback, 
    ModalHeader,Collapse
    ,UncontrolledCollapse
  } from "reactstrap";

import SimpleBar from 'simplebar-react';
import { Link,useNavigate } from "react-router-dom";

//Import Flatepicker
import Flatpickr from "react-flatpickr";
import Select from "react-select";

import { setIn, useFormik } from "formik";
import * as Yup from "yup";
import LoadingButton from "../../common/LoadingButton";
import Cleave, { displayName } from "cleave.js/react";
import {dateFormatLocal} from '../../functions/utility/common'

//Dropdown Binding 
import {errornotify,successNotify, dismissNotify} from "../../components/Common/notification";
import { url } from "../../services/common/const";
import countryList from './Country.json';
import { GetConfigurationList } from "../../services/GlobalMasters/configurationServices";

import { getUsersList } from "../../services/User/userService";
import { GetDestinationList } from "../../services/GlobalMasters/destinationService";
import { GetDestinationDatesList } from "../../services/GlobalMasters/destinationDatesService";

import {  SaveInquiryProcess } from "../../services/Inquiry/inquiryProcessService";
import { useSelector, useDispatch } from "react-redux";


// New Imports Added



const InquiryProcessFiltersOffCanvas = ({
    inquiryDetails,
    isInquiryFiltersOffCanvasOpen,
    toggleInquiryFiltersOffCanvasOpen,
    fetchData
}) => {


    //Login

    const [userLogin, setUserLogin] = useState([]);
    const [errorMessage, setErrorMessage] = useState(undefined);
    const [passwordShow, setPasswordShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();


  // Tooltip Open state
  const [tooltipOpen, setTooltipOpen] = React.useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");
  const [configurationList, setConfigurationList] = useState([]);
  const [inquiryStatusOption, setInquiryStatusOption] = useState([]);
  const [inquiryProspectOption, setInquiryProspectOption] = useState([]);
  const [inquirySourceOption, setInquirySourceOption] = useState([]);
  const [countryOption, setCountryOption] = useState([]);
  
  const [userList, setUserList] = useState([]);
  const [userOption, setUserOption] = useState([]);


  const [destinationList, setDestinationList] = useState([]);
  const [destinationOption, setDestinationOption] = useState([]);

  const [destinationDatesList, setDestinationDatesList] = useState([]);
  const [destinationDatesOption, setDestinationDatesOption] = useState([]);

  const [showInvalid, setShowInvalid] = useState(false);
  const [loading, setIsloading] = useState(false);
  
  const [status_code, setStatus_code] = useState([]);
  const [inquiry, setInquiry] = useState([]);


  const [selectedMulti, setselectedMulti] = useState(null);
  const [productList, setProductList] = useState([]);
  const [product, setProduct] = useState(null);


  function handleMulti(selectedMulti) {
    setselectedMulti(selectedMulti);
  }

  const SingleOptions = [
    { value: 'Watches', label: 'Watches' },
    { value: 'Headset', label: 'Headset' },
    { value: 'Sweatshirt', label: 'Sweatshirt' },
    { value: '20% off', label: '20% off' },
    { value: '4 star', label: '4 star' },
  ];


  const [cate, setCate] = useState("all");

  const categories = (category) => {
    let filteredProducts = products;
    if (category !== "all") {
      filteredProducts = products.filter((product) => product.category === category);
    }
    setProductList(filteredProducts);
    setCate(category);
  };

  
const productsData = [
  {
    id: 1,
    image: "img-1.png",
    name: "Half Sleeve Round Neck T-Shirts",
    category: "Clothes",
    stock: "12",
    price: 115,
    orders: "48",
    type: "published",
    rating: 4.2,
    publishedDate: ["12 Oct, 2021", "10:05 AM"],
    categories: "fashion"
  },
  {
    id: 2,
    image: "img-2.png",
    name: "Urban Ladder Pashe Chair",
    category: "Furniture",
    stock: "06",
    price: 160,
    orders: "30",
    type: "unpublished",
    rating: 3.3,
    publishedDate: ["06 Jan, 2021", "01:31 PM"],
    categories: "furniture"
  },
  {
    id: 3,
    image: "img-3.png",
    name: "350 ml Glass Grocery Container",
    category: "Kitchen Storage & Containers",
    stock: "10",
    price: 25,
    orders: "48",
    type: "unpublished",
    rating: 4.5,
    publishedDate: ["26 Mar, 2021", "11:40 AM"],
    categories: "grocery"
  },
  {
    id: 4,
    image: "img-4.png",
    name: "Fabric Dual Tone Living Room Chair",
    category: "Furniture",
    stock: "15",
    price: 140,
    orders: "40",
    type: "unpublished",
    rating: 3.2,
    publishedDate: ["19 Apr, 2021", "02:51 PM"],
    categories: "furniture"
  },
  {
    id: 5,
    image: "img-5.png",
    name: "Crux Motorsports Helmet",
    category: "Bike Accessories",
    stock: "08",
    price: 135,
    orders: "55",
    type: "unpublished",
    rating: 4.4,
    publishedDate: ["30 Mar, 2021", "09:42 AM"],
    categories: "auto"
  },
  {
    id: 6,
    image: "img-6.png",
    name: "Half Sleeve T-Shirts (Blue)",
    category: "Clothes",
    stock: "15",
    price: 125,
    orders: "48",
    type: "unpublished",
    rating: 3.2,
    publishedDate: ["12 Oct, 2021", "04:55 PM"],
    categories: "fashion"
  },
  {
    id: 7,
    image: "img-7.png",
    name: "Noise Evolve Smartwatch",
    category: "Watches",
    stock: "12",
    price: 95,
    orders: "45",
    type: "published",
    rating: 4.3,
    publishedDate: ["15 May, 2021", "03:40 PM"],
    categories: "watches"
  },
  {
    id: 8,
    image: "img-8.png",
    name: "Sweatshirt for Men (Pink)",
    category: "Clothes",
    stock: "20",
    price: 120,
    orders: "48",
    type: "published",
    rating: 2.2,
    publishedDate: ["21 Jun, 2021", "12:18 PM"],
    categories: "fashion"
  },
  {
    id: 9,
    image: "img-9.png",
    name: "Reusable Ecological Coffee Cup",
    category: "Tableware & Dinnerware",
    stock: "14",
    price: 125,
    orders: "55",
    type: "published",
    rating: 2.3,
    publishedDate: ["15 Jan, 2021", "10:29 AM"],
    categories: "grocery"
  },
  {
    id: 10,
    image: "img-10.png",
    name: "Travel Carrying Pouch Bag",
    category: "Bags, Wallets and Luggage",
    stock: "20",
    price: 115,
    orders: "60",
    type: "published",
    rating: 2.3,
    publishedDate: ["15 Jun, 2021", "03:51 Pm"],
    categories: "kids"
  },
  {
    id: 11,
    image: "img-1.png",
    name: "Half Sleeve Round Neck T-Shirts",
    category: "Clothes",
    stock: "12",
    price: 115,
    orders: "48",
    type: "published",
    rating: 4.2,
    publishedDate: ["12 Oct, 2021", "10:05 AM"],
    categories: "fashion"
  },
  {
    id: 12,
    image: "img-2.png",
    name: "Urban Ladder Pashe Chair",
    category: "Furniture",
    stock: "06",
    price: 160,
    orders: "30",
    type: "published",
    rating: 4.3,
    publishedDate: ["06 Jan, 2021", "01:31 PM"],
    categories: "furniture"
  },
];

const { products } = useSelector((state) => ({
    products: productsData,
  }));


  const onChangeRating = value => {
    setProductList(productsData.filter(product => product.rating >= value));

    var modifiedRating = [...ratingvalues];
    modifiedRating.push(value);
    setRatingvalues(modifiedRating);
  };

  const [ratingvalues, setRatingvalues] = useState([]);

  const onUncheckMark = (value) => {
    var modifiedRating = [...ratingvalues];
    const modifiedData = (modifiedRating || []).filter(x => x !== value);
    /*
    find min values
    */
    var filteredProducts = productsData;
    if (modifiedData && modifiedData.length && value !== 1) {
      var minValue = Math.min(...modifiedData);
      if (minValue && minValue !== Infinity) {
        filteredProducts = productsData.filter(
          product => product.rating >= minValue
        );
        setRatingvalues(modifiedData);
      }
    } else {
      filteredProducts = productsData;
    }
    setProductList(filteredProducts);
  };


//   const [selected_phone_country_code, setSelected_phone_country_code] = useState({
//     value: "+91",
//     label: "(+91) India"
//   });

  
//   const [selected_phone2_country_code, setSelected_phone2_country_code] = useState({
//     value: "+91",
//     label: "(+91) India"
//   });


  /**This function for get Inquiry list from database */
  const getConfigurationsListFunc = async () => {
    try {
        const result = await GetConfigurationList();
        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setConfigurationList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /**This function for get User list from database */
  const getUsersListFunc = async () => {
    try {
        const result = await getUsersList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setUserList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /*This function for get Destinations list from database */
  const getDestinationListFunc = async () => {
    try {
        const result = await GetDestinationList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setDestinationList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  /*This function for get Destinations list from database */
  const getDestinationDatesListFunc = async () => {
    try {
        const result = await GetDestinationDatesList();

        if (result?.data?.status === "SUCCESS") {
            let resultData = result.data.data;          
            setDestinationDatesList(resultData);
        } else 
        {
            //throw result?.message;
            errornotify(result?.data?.message);
        }
    } catch (error) {
      errornotify(error);
    }
  };

  const setDefaultModalValues = async () => {
    try {

        const countryOption = countryList.filter(item => item.dialCode === inquiryDetails?.contact.phone_country_code);

        // inquiryStatusList.map((item, index) => {      
        //     inquiryStatusOptionTemp.push({
        //       label: item.param_name,
        //       value: item.param_value,
        //     });
        //   });

        let inquiryStatusOptionTemp = [];

        setModalValue(
            { 
                ...modalValue, 
                 selected_inquiry_date:  new Date(inquiryDetails?.inquiry_date),       
                 selected_last_call_date: new Date(inquiryDetails?.last_call),
                 selected_follow_up_date: new Date(inquiryDetails?.follow_up),
                selected_phone_country_code : {
                     value: countryOption[0]?.dialCode,
                     label: '(' + countryOption[0]?.dialCode + ') ' + countryOption[0]?.country,
                     //label : "(+93) Afghanistan"
                    },
                selected_destination_code : {          
                    label: inquiryDetails?.destination_name,
                    value: inquiryDetails?.destination_code,
                },     
                selected_travel_date_range:{
                    label: inquiryDetails?.travel_date_range,
                    value: inquiryDetails?.travel_date_range,
                },            
                selected_status_code:{
                    label: inquiryDetails?.status_code,
                    value: inquiryDetails?.status_code,
                },  
                selected_prospect_code:{
                    label: inquiryDetails?.prospect_code,
                    value: inquiryDetails?.prospect_code,
                },
                selected_source_code:{
                    label: inquiryDetails?.source_code,
                    value: inquiryDetails?.source_code,
                },
                selected_allocated_to_user:{
                    label: inquiryDetails?.allocated_to_user_full_nme,
                    value: inquiryDetails?.allocated_to_user,
                }  
            }
            );
 
        

    } catch (error) {
      errornotify(error);
    }
  };



  useEffect(() => {
    
    //alert(inquiryDetails.inquiry_date);

    setInquiry(inquiryDetails);

    getDestinationDatesListFunc();


   //All Configuration List 
   if (destinationDatesList?.length > 0) {

    //const destinationDatesList = configurationList.filter(item => item.param_group === "InquiryStatus");

    let destinationDatesOptionTemp = [];
    
    destinationDatesList.map((item, index) => {      
        destinationDatesOptionTemp.push({
          label: dateFormatLocal(item.from_date) + ' to ' + dateFormatLocal(item.to_date),
          value: dateFormatLocal(item.from_date) + ' to ' + dateFormatLocal(item.to_date),
        });
      });

      setDestinationDatesOption(destinationDatesOptionTemp);
   }
        // name=== "selected_phone_country_code" ||
        // name === "selected_phone2_country_code" ||
        // name === "selected_destination_code"  ||
        // name === "selected_status_code"       ||
        // name === "selected_prospect_code"    ||
        // name === "selected_source_code"      ||
        // name === "selected_allocated_to_user" ||
        // name === "selected_travel_date_range"
         
    

  }, [inquiryDetails]);

  useEffect(() => {   

      /**city get api call */
      //setInquiryStatusOption([]);
      //setInquiryProspectOption([]);

      setInquiry(inquiryDetails);


     //alert(inquiryDetails?.contact?.contact_id);


      getConfigurationsListFunc();           

      //All Configuration List 
      if (configurationList?.length > 0) {

        //Inquiry Status
        const inquiryStatusList = configurationList.filter(item => item.param_group === "InquiryStatus");

        let inquiryStatusOptionTemp = [];
        
        inquiryStatusList.map((item, index) => {      
            inquiryStatusOptionTemp.push({
              label: item.param_name,
              value: item.param_value,
            });
          });

          setInquiryStatusOption(inquiryStatusOptionTemp);
      

        //Prospect Code
        const inquiryPropspectList = configurationList.filter(item => item.param_group === "InquiryProspect");

        let inquiryPropspectOptionTemp = [];

        inquiryPropspectList.map((item, index) => {      
            inquiryPropspectOptionTemp.push({
              label: item.param_name,
              value: item.param_value,
            });
          });
           
        setInquiryProspectOption(inquiryPropspectOptionTemp);

         //Source Code
         const inquirySourceList = configurationList.filter(item => item.param_group === "InquirySource");

         let inquirySourceOptionTemp = [];
 
         inquirySourceList.map((item, index) => {      
            inquirySourceOptionTemp.push({
               label: item.param_name,
               value: item.param_value,
             });
           });
            
           setInquirySourceOption(inquirySourceOptionTemp);


      }         
        
       //All Country List
       if (countryList?.length > 0) {
        const countryOptionTemp = [];


        countryList.map((item, index) => {
            countryOptionTemp.push({
                value: item.dialCode,
                label: `(${item.dialCode}) ${item.country}`,
                });
        });
       
        setCountryOption(countryOptionTemp);   
        }

       //All User List
       getUsersListFunc();   

       if (userList?.length > 0) {
            //Inquiry Status
            //const userSellerList = userList.filter(item => item.param_group === "SLR");
    
            let userOptionTemp = [];
            
            userList.map((item, index) => {      
                userOptionTemp.push({
                  label: item.fullName,
                  value: item.userName,
                });
              });
    
              setUserOption(userOptionTemp);
        }

        //All Destination List
       getDestinationListFunc();   

       if (destinationList?.length > 0) {
            //Inquiry Status
            //const userSellerList = userList.filter(item => item.param_group === "SLR");
    
            let destinationOptionTemp = [];
            
                    
            destinationList.map((item, index) => {      
                destinationOptionTemp.push({
                  label: item.destination_name,
                  value: item.destination_code,
                });
              });
    
              setDestinationOption(destinationOptionTemp);
        }


        setDefaultModalValues();
       

  }, [inquiryDetails]);

    const [modalValue, setModalValue] = useState({
    selected_inquiry_date: "",
    selected_follow_up_date: "",
    selected_last_call_date: "",
    selected_follow_up_date : "",
    //selected_travel_date_range : "",
    selected_phone_country_code: {        
        label: "(+91) India",
        value: "+91",
    },
    selected_phone2_country_code: {
        label: "(+91) India",
        value: "+91",
    },
    selected_destination_code:{
        label: "",
        value: "",
      },
    selected_travel_date_range: {
        label: "",
        value: "",
    },
    selected_status_code:{
        label: "",
        value: "",
    },
    selected_prospect_code:{
        label: "",
        value: "",
    },
    selected_source_code:{
        label: "",
        value: "",
    },
    selected_allocated_to_user:{
        label: "",
        value: "",
    }      
  });




  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {  
      operation:   inquiryDetails?.operation,
      inquiry_no: inquiry?.inquiry_no || "",
    //   inquiry_date: new Date(inquiryDetails?.inquiry_date),
    inquiry_date: inquiryDetails?.inquiry_date || "" ,
      contact_id: inquiryDetails?.contact?.contact_id || 0,
      name:  inquiryDetails?.contact?.name || "", 
      email: inquiryDetails?.contact?.email || "",  

        phone_country_code: inquiryDetails?.contact?.phone_country_code || "",
        //phone_country_code:  modalValue?.selected_phone_country_code || "",

        phone: inquiryDetails?.contact?.phone || "",
        phone2_country_code: inquiryDetails?.contact?.phone2_country_code || "",
       // phone2_country_code: modalValue?.selected_phone2_country_code || "",
        phone2: inquiryDetails?.contact?.phone2 || "",
        

      status_code: inquiryDetails?.status_code || "",
      prospect_code: inquiryDetails?.prospect_code || "",
      source_code: inquiryDetails?.source_code || "",

      destination_code: inquiryDetails?.destination_code | "",
      is_fixed_departure: Boolean(inquiryDetails?.is_fixed_departure) || false,

      pax: inquiryDetails?.pax || 0,
      city: inquiryDetails?.city || "",
      expected_budget: inquiryDetails?.expected_budget || 0,
      //allocated_to_user: modalValue?.selected_allocated_to_user|| "", (Danger - This is making all other fields blank)

      allocated_to_user: inquiryDetails?.allocated_to_user|| "",
    //   last_call: new Date(inquiryDetails?.last_call) || "",
    //   follow_up: new Date(inquiryDetails?.follow_up) || "",

    last_call: inquiryDetails?.last_call || "",
    follow_up: inquiryDetails?.follow_up || "",

      travel_date_range: inquiryDetails?.travel_date_range || "",
      allocated_to_user: inquiryDetails?.allocated_to_user|| "",
    },
    validationSchema: Yup.object({
        //inquiry_date: Yup.string().required("Please Select Inquiry Date"),     
        name: Yup.string().required("Please Enter Name"), 
        //email: Yup.string().email().required("Please Enter Email"),
        //phone: Yup.string().required("Please Enter Primary Contact"), 
        //phone_country_code: Yup.string().required("Please Select Country Code")        
    }),
    onSubmit: (values) => {
        //alert(values);
        SaveInquiryProcessData(values);      
          
    },
  });

    /*This function for get Destinations list from database */
    const SaveInquiryProcessData = async (values) => {
        try {         
     

                const addInquiry = values;        
                
       
              
                //addInquiry.status_code = modalValue.selected_status_code.value;
                addInquiry.prospect_code = modalValue.selected_prospect_code.value;
                addInquiry.source_code = modalValue.selected_source_code.value;
               // addInquiry.destination_code = modalValue.selected_destination_code.value;
                addInquiry.phone_country_code = modalValue.selected_phone_country_code.value;
                addInquiry.allocated_to_user = modalValue.selected_allocated_to_user.value;
                addInquiry.inquiry_date = modalValue.selected_inquiry_date;
                addInquiry.last_call = modalValue.selected_last_call_date;
                addInquiry.follow_up = modalValue.selected_follow_up_date;
    
         
                let currentSystemUser = "manisha@aahvan.com";
                let selected_travel_date_range = modalValue.selected_travel_date_range.value;
                let travel_from_date = "";
                let travel_to_date = "";

                if(selected_travel_date_range !=null)
                {
                let arrDates = selected_travel_date_range?.split(' to ');  
                if(arrDates.length == 1)
                {
                    travel_from_date = arrDates[1];
                    travel_to_date = arrDates[1];
                }
                else if(arrDates.length == 2)
                {
                    //alert(arrDates[0] + " - " + arrDates[1]);
                    travel_from_date = arrDates[0];
                    travel_to_date = arrDates[1];
                }
            }
    
                  const newInquiry =  {
                    "operation": values?.operation,
                    "inquiry_id": 0,
                    "inquiry_no": values?.inquiry_no,
                    "inquiry_date": modalValue.selected_inquiry_date,
                    "destination_code": modalValue.selected_destination_code.value,                  
                    "status_code":  modalValue.selected_status_code.value,
                    "prospect_code":modalValue.selected_prospect_code.value,
                    "source_code": modalValue.selected_source_code.value,
                    "allocated_to_user": modalValue.selected_allocated_to_user.value,         
                    "allocated_date": new Date(),
                    "allocated_by_user": currentSystemUser,   //Current System User  
                    "contact": {
                        "contact_id": values?.contact_id,
                        "name": values?.name,
                        "phone_country_code": modalValue.selected_phone_country_code.value,
                        "phone": values?.phone,
                        //"phone2_country_code": "",
                        //"phone2": "",
                        "email":  values?.email,
                    },
                    "last_call":  modalValue.selected_last_call_date,
                    "follow_up":modalValue.selected_follow_up_date,               
                    "pax": parseInt(values?.pax) || 0,
                    "duration": "5",
                    "is_fixed_departure": values?.is_fixed_departure,
                    "travel_from_date": travel_from_date,
                    "travel_to_date": travel_to_date,
                    "travel_date_range": modalValue.selected_travel_date_range.value,                            
                    "city": values?.city,
                    "expected_budget":  values?.expected_budget !== 0 ? parseFloat(values?.expected_budget.toString().replaceAll(",", "")) : 0,           
                    "row_created_date":  new Date(),
                    "row_created_by": currentSystemUser,
                    "row_altered_date":  new Date(),
                    "row_altered_by": currentSystemUser,               
                    "is_active": true,               
                };
    
    
              setInquiry(newInquiry);
    
              // alert('Add Inquiry' + JSON.stringify(newInquiry));
    
               const response = await SaveInquiryProcess(newInquiry);
    
               //alert('response?.status ' + response?.status);
            
               //alert('response.errors' + JSON.stringify(response.errors));

               //dismissNotify();
               if (response?.status === "SUCCESS") {
                        successNotify(response?.message);

                        //Hide Off Canvas
                        toggleInquiryFiltersOffCanvasOpen();

                        //Refresh Datagrid
                        fetchData();
                } else 
                {
                    if(response?.errors)                    
                        errornotify(JSON.stringify(response.errors));
                    else
                        errornotify(response?.message);
                }
            // } 
              
            //   else {
            //     const updateInquiry = values;    
                
            //   }

              
    
          
        } catch (error) {
          errornotify(error);
        }
      };     


  const handleStatus = (sts) => {
    setStatus_code(sts);
  }

  const [isAltContactCoppaseOpen, setIsAltContactCoppaseOpen] = useState(false);

  const toggleAltContactCoppaseOpen = () => {
    setIsAltContactCoppaseOpen(!isAltContactCoppaseOpen);
  };

  const onChangeHandller = (e, name, isDateRangePicker ) => {  

    //Dropdown controls will have e == Option as selected option
    if (
        name=== "selected_phone_country_code" ||
        name === "selected_phone2_country_code" ||
        name === "selected_destination_code"  ||
        name === "selected_status_code"       ||
        name === "selected_prospect_code"    ||
        name === "selected_source_code"      ||
        name === "selected_allocated_to_user"         
    ) 
     {        
        setModalValue({ ...modalValue, [name] : e});
      } 
      //Date pickers
      else if (name === "selected_inquiry_date" ||
               name === "selected_last_call_date" ||
               name === "selected_follow_up_date"
      ) 
      {  
        if(e.length > 0)
        setModalValue({ ...modalValue, [name]: dateFormatLocal(e[0])});
      }
       else if (name === "selected_travel_date_range"
      ) {     

        if(isDateRangePicker == true)
        {
            if(e.length > 0)
                setModalValue({ ...modalValue, [name]: dateFormatLocal(e[0]) + " to " + dateFormatLocal(e[1]) });
        }
        else{
            setModalValue({ ...modalValue, [name]: e });
        }
       
      }
      //Check boxes
      else if (name === "is_fixed_departure") {
        setModalValue({ ...modalValue, [name]: e.target.checked });
      }  
    
        return false;

        //General 
    // setModalValue({ ...modalValue, [e.target.name]: e.target.value });
  };


    
/**Package Save*/
const modalSaveHandller = async () => {
    try {
      setShowInvalid(true);
    //   if (name?.length < 0) {
    //     throw "Package Name is required.";
    //   }

    } 
    catch (error) {
        errornotify(error);
    }
};   
  return ( 
        <>     
        {/* Enable body scrolling */}
        <Offcanvas
                isOpen={isInquiryFiltersOffCanvasOpen}
                scrollable
                direction="end"
                backdrop={true}
                toggle={toggleInquiryFiltersOffCanvasOpen}
                id="offcanvasScrolling" 
                className="offcanvas-end border-0"
            >              

               
<Row>
          <Col xl={12} lg={12}>
            <Card>
              <CardHeader >
                <div className="d-flex mb-3">
                  <div className="flex-grow-1">
                    <h5 className="fs-16">Filters</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <Link to="#" className="text-decoration-underline">
                      Clear All
                    </Link>
                  </div>
                </div>

                <div className="filter-choices-input">
                  <Select
                    value={selectedMulti}
                    isMulti={true}
                    onChange={() => {
                      handleMulti();
                    }}
                    options={SingleOptions}
                  />
                </div>
              </CardHeader>

              <div className="accordion accordion-flush">
                <div className="card-body border-bottom">
                  <div>
                    <p className="text-muted text-uppercase fs-12 fw-medium mb-2">
                      Products
                    </p>
                    <ul className="list-unstyled mb-0 filter-list">
                      <li>
                        <Link to="#" className={cate === "Kitchen Storage & Containers" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Kitchen Storage & Containers")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Grocery</h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "Clothes" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Clothes")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Fashion</h5>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <span className="badge bg-light text-muted">5</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "Watches" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Watches")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Watches</h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "electronics" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("electronics")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Electronics</h5>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <span className="badge bg-light text-muted">5</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "Furniture" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Furniture")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Furniture</h5>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <span className="badge bg-light text-muted">6</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "Bike Accessories" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Bike Accessories")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Automotive Accessories</h5>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "appliances" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("appliances")}>
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Appliances</h5>
                          </div>
                          <div className="flex-shrink-0 ms-2">
                            <span className="badge bg-light text-muted">7</span>
                          </div>
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className={cate === "Bags, Wallets and Luggage" ? "active d-flex py-1 align-items-center" : "d-flex py-1 align-items-center"} onClick={() => categories("Bags, Wallets and Luggage")} >
                          <div className="flex-grow-1">
                            <h5 className="fs-13 mb-0 listname">Kids</h5>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="card-body border-bottom">
                  <p className="text-muted text-uppercase fs-12 fw-medium mb-5">
                    Price
                  </p>

                  {/* <Nouislider
                    range={{ min: 0, max: 600 }}
                    tooltips={true}
                    start={[100, 500]}
                    connect
                    onSlide={onUpdate}
                  /> */}
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-transparent shadow-none"
                      type="button"
                      id="flush-headingBrands"
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Brands
                      </span>{" "}
                      <span className="badge bg-success rounded-pill align-middle ms-1">
                        2
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse
                    toggler="#flush-headingBrands"
                    defaultOpen
                  >
                    <div
                      id="flush-collapseBrands"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingBrands"
                    >
                      <div className="accordion-body text-body pt-0">
                        <div className="search-box search-box-sm">
                          <input
                            type="text"
                            className="form-control bg-light border-0"
                            placeholder="Search Brands..."
                          />
                          <i className="ri-search-line search-icon"></i>
                        </div>
                        <div className="d-flex flex-column gap-2 mt-3">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productBrandRadio5"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio5"
                            >
                              Boat
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productBrandRadio4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio4"
                            >
                              OnePlus
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productBrandRadio3"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio3"
                            >
                              Realme
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productBrandRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio2"
                            >
                              Sony
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productBrandRadio1"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productBrandRadio1"
                            >
                              JBL
                            </label>
                          </div>

                          <div>
                            <button
                              type="button"
                              className="btn btn-link text-decoration-none text-uppercase fw-medium p-0"
                            >
                              1,235 More
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-transparent shadow-none collapsed"
                      type="button"
                      id="flush-headingDiscount"
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Discount
                      </span>{" "}
                      <span className="badge bg-success rounded-pill align-middle ms-1">
                        1
                      </span>
                    </button>
                  </h2>
                  <UncontrolledCollapse toggler="#flush-headingDiscount">
                    <div
                      id="flush-collapseDiscount"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body text-body pt-1">
                        <div className="d-flex flex-column gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio6"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio6"
                            >
                              50% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio5"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio5"
                            >
                              40% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio4"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio4"
                            >
                              30% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio3"
                              defaultChecked
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio3"
                            >
                              20% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio2"
                            >
                              10% or more
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productdiscountRadio1"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productdiscountRadio1"
                            >
                              Less than 10%
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-transparent shadow-none collapsed"
                      type="button"
                      id="flush-headingRating"
                    >
                      <span className="text-muted text-uppercase fs-12 fw-medium">
                        Rating
                      </span>{" "}
                      <span className="badge bg-success rounded-pill align-middle ms-1">
                        1
                      </span>
                    </button>
                  </h2>

                  <UncontrolledCollapse toggler="#flush-headingRating">
                    <div
                      id="flush-collapseRating"
                      className="accordion-collapse collapse show"
                      aria-labelledby="flush-headingRating"
                    >
                      <div className="accordion-body text-body">
                        <div className="d-flex flex-column gap-2">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productratingRadio4"
                              onChange={e => {
                                if (e.target.checked) {
                                  onChangeRating(4);
                                } else {
                                  onUncheckMark(4);
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio4"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star"></i>
                              </span>{" "}
                              4 & Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productratingRadio3"
                              onChange={e => {
                                if (e.target.checked) {
                                  onChangeRating(3);
                                } else {
                                  onUncheckMark(3);
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio3"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                              </span>{" "}
                              3 & Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productratingRadio2"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio2"
                              onChange={e => {
                                if (e.target.checked) {
                                  onChangeRating(2);
                                } else {
                                  onUncheckMark(2);
                                }
                              }}
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                              </span>{" "}
                              2 & Above
                            </label>
                          </div>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="productratingRadio1"
                              onChange={e => {
                                if (e.target.checked) {
                                  onChangeRating(1);
                                } else {
                                  onUncheckMark(1);
                                }
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="productratingRadio1"
                            >
                              <span className="text-muted">
                                <i className="mdi mdi-star text-warning"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                                <i className="mdi mdi-star"></i>
                              </span>{" "}
                              1
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </div>
            </Card>
          </Col>

      
        </Row>


        </Offcanvas>
        </>            
  );
};

export default InquiryProcessFiltersOffCanvas;
