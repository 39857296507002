import React from "react";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import {Alert,Button,Card,CardBody,CardHeader,Col,Container,DropdownMenu,DropdownToggle,Input,Label,Modal,ModalBody,ModalHeader,Row,UncontrolledDropdown,} from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useEffect } from "react";
import { GetDestinationList } from "../../../services/GlobalMasters/destinationService";
import { GetCityList } from "../../../services/GlobalMasters/cityServices";
import { GetHotelList } from "../../../services/GlobalMasters/hotelServices";
import { Table, Space, message } from "antd";
import { useRef } from "react";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { GetMealPlansList } from "../../../services/GlobalMasters/mealPlansServices";
import {GetHotelRoomList,SaveHotelRoomList} from "../../../services/GlobalMasters/hotelRoomsServices";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { currencyMask } from "../../../components/currencyInput";
import ButttonTravelNinjaz from "../../../components/Common/GloablMaster/ButttonTravelNinjaz";
import {destinationTypes,destinationTypesFilter} from "../../../components/constants/destinationTypes";
import {downloadExcel,filterUniqueCity,filterUniqueDestination,filterUniqueHotel} from "../../../functions/downloadtoExcel";
import { appName } from "../../../common/applicationName";
import { getSessionUsingSessionStorage } from "../../../services/common/session";
import CurrencyShow from "../../../common/CurrencyShow";
import {errornotify,successNotify} from "../../../components/Common/notification";
import { useNavigate } from "react-router-dom";
import { authError } from "../../../services/common/const";
import UseAuth from "../../../components/UseAuth";
import PreviewCardHeader from '../../../components/Common/PreviewCardHeader';

import {  
  showDestinationType ,
  showDestination,

  destinationTypeList, 
  destinationList ,
  cityList ,

  defaultDestinationType ,
  defaultDestination ,
  defaultCity 

  ,defaultCityOption
} from "../../../services/common/GlobalVariables";
import Rating from "react-rating";
import Operation from "antd/lib/transfer/operation";

const animatedComponents = makeAnimated();

const HotelRooms = () => {
  UseAuth();
  // document.title = "Hotel Rooms | TravelNinjaz B2B";
  document.title = `${appName}-Hotel`;
  const [dataSource, setDataSource] = useState([]);
  const [showInvalid, setShowInvalid] = useState(false);
  const currencySymbol = localStorage.getItem("currencySymbol");
  const [userName, setUserName] = useState();
  
  const navigate = useNavigate();
  /** get session details*/
  useEffect(() => {
    let promise = getSessionUsingSessionStorage();
    promise
      .then(function (value) {
        return value;
      })
      .then((value) => {
        setUserName(value.userName);
      });
  }, []);
  const dates = new Date();
  const updatedDate = `${dates.getDate()}/${
    dates.getMonth() + 1
  }/${dates.getFullYear()}`;
  /**This is for search columns */
  const searchInput = useRef(null);
  const [searchTextantd, setSearchTextantd] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [filterColumnWiseDestination, setFilterColumnWiseDestination] =
    useState([]);
  const [filterColumnWiseCity, setFilterColumnWiseCity] = useState([]);
  const [filterColumnWiseHotel, setFilterColumnWiseHotel] = useState([]);
  /**This is for hidden/show column*/
  const [rowCreatedBy, setRowCreatedBy] = useState(true);
  const [rowCreatedDate, setRowCreatedDate] = useState(true);
  const [rowModifiedBy, setRowModifiedBy] = useState(true);
  const [rowModifiedDate, setRowModifiedDate] = useState(true);
  /**This is for over all search */
  const [searchText, setSearchText] = useState("");
  const [filterData, setFilterData] = useState([]);
  /**destination selected */
  const [selectedDestinationType, setselectedDestinationType] = useState(null);
  const [selectedDestinationTypeId, setselectedDestinationTypeId] = useState(null);
  /**destination name selected*/
  const [selectedDestination, setselectedDestination] = useState(null);
  const [selectedDestinationId, setselectedDestinationId] = useState(null);
  /**city name selected */
  const [selectedCity, setselectedCity] = useState(null);
  const [selectedCityId, setselectedCityId] = useState(null);

  /**This is for destination,city,hotel list*/
  //const [destinationList, setDestinationList] = useState([]);
  //const [cityList, setCityList] = useState([]);
  const [hotelList, setHotelList] = useState([]);
  /**This is option for destination*/
  const [destinationOption, setDestinationOption] = useState([]);
  const [exportTableData, setExportableData] = useState([]);
  /**meal plans*/
  const [mealPlans, setMealPlans] = useState([]);
  const [readOnly, setReadOnly] = useState(false);

  
  /**This is for destination options*/
  useEffect(() => {
    let tempDestinationList = [];
    if (destinationList.length > 0 && selectedDestinationTypeId) {
      const filterDestinationList = destinationList.filter((item) => {
        return (
          item.destination_type_id === selectedDestinationTypeId &&
          item.is_active
        );
      });
      filterDestinationList.map((item, index) => {
        tempDestinationList.push({
          label: item.destination_name,
          value: item.destination_id,
        });
      });
      setDestinationOption(tempDestinationList);
    }
  }, [destinationList, selectedDestinationTypeId]);

  const [modal_scroll, setmodal_scroll] = useState(false);
  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
  }

  /**This is option for city */
  const [cityOption, setcityOption] = useState([]);
  useEffect(() => {
    let tempCityOption = [];
    // if (cityList.length > 0 && selectedDestinationId) {
    //   cityList
    //     .filter((item) => {
    //       return (
    //         item.destination_id === selectedDestinationId && item.is_active
    //       );
    //     })
    //     .map((item, index) => {
    //       tempCityOption.push({
    //         label: item.city_name,
    //         value: item.city_id,
    //       });
    //     });
    //   setcityOption(tempCityOption);
    // }

    if (cityList.length > 0) {
      let tempCityOption = [];

      // tempCityOption.push({
      //   label:"Select City",
      //   value: "",
      // });
      
      cityList
        // .filter((item) => {
        //   return (
        //     item.destination_id === selectedDestinationId && item.is_active
        //   );
        // })
 
        .map((item, index) => {
          tempCityOption.push({
            label: item.city_name,
            value: item.city_id,
          });
        });
      setcityOption(tempCityOption);
    }

  }, [cityList, selectedDestinationId]);


  /**This is function for select destination type handllder */
  const selectDestinationTypeHandller = (selectdestinationType) => {
    setDestinationOption([]);
    setselectedDestinationType(selectdestinationType);
    setselectedDestinationTypeId(selectdestinationType.value);
    setselectedDestination(null);
    setselectedDestinationId(null);
    setselectedCity(null);
    setselectedCityId(null);
  };
  /**This is function for destination handller*/
  const selectDestinationHandller = (selectdestination) => {
    setcityOption([]);
    setselectedDestination(selectdestination);
    setselectedDestinationId(selectdestination.value);
    setselectedCity(null);
    setselectedCityId(null);

  };

  /***This is function for city handller*/
  const selectCityHandller = (selectcity) => {
    debugger;
    setselectedCity(selectcity);
    setselectedCityId(selectcity.value);

  };


  /**This function for get list hotel,city and destination */
  const getAllList = async () => {
    try {
      /**destination get api call */

      // const destinationResult = await GetDestinationList();
      // if (destinationResult?.data?.status === "SUCCESS") {
      //   setDestinationList(destinationResult.data.data);
      // } else {
      //   throw destinationResult.message;
      //   // return;
      // }

      /**city get api call */
      // const cityResult = await GetCityList();

      // if (cityResult?.data?.status === "SUCCESS") {
      //   setCityList(cityResult.data.data);
      // } else {
      //   throw cityResult.message;
      //   // return;
      // }

      /**hotel get api call */
      const hotelResult = await GetHotelList();
      if (hotelResult?.data?.status === "SUCCESS") {
        setHotelList(hotelResult.data.data);
      } else {
        throw hotelResult.message;
        // return;
      }

      /**Meal plan get api call */
      const mealPlansResult = await GetMealPlansList();
      if (mealPlansResult?.data?.status === "SUCCESS") {
        setMealPlans(mealPlansResult.data.data);
      } else {
        throw mealPlansResult.message;
        // return;
      }

      /**hotel meal plans get api call*/
      let params = new URLSearchParams();
      let global_default_city_object;
      if(localStorage.getItem("global_default_city_object") != "" && localStorage.getItem("global_default_city_object") != "null")
      { 
        global_default_city_object = JSON.parse(localStorage.getItem("global_default_city_object") ?? "");
        params.append("city_id",global_default_city_object.city_id);
      } 


      const hotelRoomsResult = await GetHotelRoomList(params);
      if (hotelRoomsResult?.data?.status === "SUCCESS") {
        setDataSource(hotelRoomsResult.data.data);
        let uniqueDestination = filterUniqueDestination(
          hotelRoomsResult.data.data
        );
        let filterDestination = [];
        uniqueDestination.map((item, index) => {
          filterDestination.push({
            text: item?.destination_name,
            value: item?.destination_id,
          });
        });
        setFilterColumnWiseDestination(filterDestination);
        /**This is for unique city */
        let uniqueCity = filterUniqueCity(hotelRoomsResult.data.data);
        let filterCity = [];
        uniqueCity.map((item, index) => {
          filterCity.push({
            text: item?.city_name,
            value: item?.city_id,
          });
        });
        setFilterColumnWiseCity(filterCity);

        /**This is for unique hotel */
        let uniqueHotel = filterUniqueHotel(hotelRoomsResult.data.data);
        let filterHotel = [];
        uniqueHotel.map((item, index) => {
          filterHotel.push({
            text: item?.hotel_name,
            value: item?.hotel_id,
          });
        });
        setFilterColumnWiseHotel(filterHotel);
      } else {
        throw mealPlansResult.message;
        // return;
      }
    } catch (error) {
      errornotify(error);
      // if (error === authError) {
      //   navigate("/login");
      // }
    }
  };
  /**This useEffect  for get list*/
  useEffect(() => {
    getAllList();
  }, []);

  /**This is for children data*/
  const expandedRowRender = (row) => {
    const columns = [
      {
        title: "Meal Plans",
        dataIndex: "meal_plan_desc",
      },
      {
        title: "Adult Price (2 pax)",
        dataIndex: "adult_price",
        render: (text, record) => {
          return (
            <CurrencyShow
              currency={record.adult_price}
              currencySymbol={currencySymbol}
            />
          );
        },
      },
      {
        title: "Child/ Ex Adult Price (With bed)",
        dataIndex: "child_price",
        render: (text, record) => {
          return (
            <span>
              <CurrencyShow
                currency={record.child_price}
                currencySymbol={currencySymbol}
              />
            </span>
          );
        },
      },
      {
        title: "Child Price (Without bed)",
        dataIndex: "child_price_without_bed",
        render: (text, record) => {
          console.log("bedPrice", record.child_price_without_bed);
          return (
            <CurrencyShow
              currency={
                record.child_price_without_bed
                  ? record.child_price_without_bed
                  : 0
              }
              currencySymbol={currencySymbol}
            />
          );
        },
      },
    ];
    return (
      <Table
        columns={columns}
        dataSource={row.hotelMealPlanList}
        pagination={false}
      />
    );
  };

  const [inputList, setInputList] = useState({
    hotel_id: null,
    hotel_name: null,
    hotel_type: null,
    is_active: true,
    adult_roomonly: null,
    adult_cp: null,
    adult_map: null,
    adult_ap: null,
    exadult_roomonly: null,
    exadult_CP: null,
    exadult_MAP: null,
    exadult_AP: null,
    child_without_bed_roomonly: null,
    child_without_bed_cp: null,
    child_without_bed_map: null,
    operation: null
  });
  const [hotelRoomAndMealPlans, setHotelRoomAndMealPlans] = useState([]);
  const formatter = new Intl.NumberFormat("en-IN");
  /**input change handller*/
  const onChangeHandller = (e) => {
    const adult_price = e.target.value;
    const precentage = 70;
    if (e.target.name === "is_active")         
    {
      setInputList({ ...inputList, [e.target.name]: e.target.checked });
    } 
    else if (
    e.target.name === "hotel_name"  || 
    e.target.name === "hotel_type" )         
    {
      setInputList({ ...inputList, [e.target.name]: e.target.value });
    }
    else {
      // setInputList({ ...inputList, [e.target.name]: e.target.value });
      const newArray = mealPlans.map((item, i) => {
        if (parseInt(e.target.id) === item.meal_plan_id) {
          if (e.target.name === "adult_price") {
            return {
              ...item,
              [e.target.name]: adult_price,
              child_price: (
                (parseInt(adult_price) * precentage) /
                100
              ).toString(),
            };
          } else {
            return {
              ...item,
              [e.target.name]: e.target.value,
            };
          }
        } else {
          return item;
        }
      });
      setMealPlans(newArray);
      const newArrayRoomAndMealPlans = hotelRoomAndMealPlans.map((item, i) => {
        if (parseInt(e.target.id) === item.meal_plan_id) {
          if (e.target.name === "adult_price") {
            return {
              ...item,
              [e.target.name]: adult_price,
              child_price: (
                (parseInt(adult_price) * precentage) /
                100
              ).toString(),
            };
          } else {
            return { ...item, [e.target.name]: e.target.value };
          }
        } else {
          return item;
        }
      });
      setHotelRoomAndMealPlans(newArrayRoomAndMealPlans);
    }
  };

  /**check box handller*/
  const checkBoxHandller = (e, key) => {
    const newArray = mealPlans.map((item, i) => {
      if (key === item.meal_plan_id) {
        return {
          ...item,
          is_active: e.target.checked,
          adult_price: null,
          child_price: null,
          child_price_without_bed: null,
        };
      } else {
        return item;
      }
    });
    setMealPlans(newArray);
    if (e.target.checked) {
      hotelRoomAndMealPlans.push({
        meal_plan_code: e.target.value,
        adult_price: null,
        child_price: null,
        child_price_without_bed: null,
        meal_plan_id: key,
      });
    } else {
      const index = hotelRoomAndMealPlans.findIndex((object) => {
        return object.meal_plan_id === key;
      });
      if (index > -1) {
        setShowInvalid(false);
        hotelRoomAndMealPlans.splice(index, 1);
      }
    }
  };
  /**Modal save handller */
  const modalSaveHandller = async () => {
    try {

      debugger;
      setShowInvalid(true);
      hotelRoomAndMealPlans.map((item, index) => {
        if (!item.adult_price) {
          throw "Adult Price is required.";
        }
        if (!item.child_price) {
          throw "Child Price is required.";
        }
        if (!item.child_price_without_bed) {
          throw "Child Price without bed is required.";
        }
      });
   
      if (hotelRoomAndMealPlans.length > 0) {
        const updatableData = {
          City_id: selectedCityId,
          Hotel_id: inputList?.hotel_id === null? 0:inputList?.hotel_id  ,
          Hotel_name: inputList?.hotel_name,
          hotel_type: inputList?.hotel_type,
          HotelMealPlanList: hotelRoomAndMealPlans,
          Is_active: inputList?.is_active,
          Row_created_by: userName,
          Row_created_date: new Date(),
          Row_altered_by: userName,
          Row_altered_date: new Date(),
          Operation: (!readOnly ? "ADD " : "UPDATE")
        };
        //Loop through Hotel Meal Plan List
        // const hotelMealPlanList = hotelRoomAndMealPlans.map((item, index) => {
        //   return {
        //     Hotel_id: selectedHotelId,
        //     Meal_plan_id: item.meal_plan_id,
        //     Meal_plan_code: item.meal_plan_code,
        //     Meal_plan_desc: item.meal_plan_desc,
        //     Adult_price: item.adult_price,
        //     Child_price: item.child_price,
        //     Is_active: inputList.is_active,
        //     Row_created_by: userName,
        //     Row_created_date: new Date(),
        //     Row_altered_by: userName,
        //     Row_altered_date: new Date(),
        //   };
        // });

        const response = await SaveHotelRoomList(updatableData);
        if (response.status === "SUCCESS") {
          await getAllList();
          successNotify(response?.message);
          tog_scroll();
          setShowInvalid(false);
          setHotelRoomAndMealPlans([]);
          setReadOnly(false);
        } else {
          errornotify(response?.message);
        }
      }
    } catch (error) {
      errornotify(error);
    }
  };
  /**For column search */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchTextantd(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchTextantd("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
              backgroundColor: "#233163",
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchTextantd(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
            style={{ backgroundColor: "#233163" }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#233163" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchTextantd]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /***Edit handller*/
  const editHandller = (record) => {

    debugger;

    //setInputList({ ...inputList, operation: "UPDATE" });

    setReadOnly(true);
    {
      record.destination_type_id === 1
        ? setselectedDestinationType({ label: "Domestic", value: 1 })
        : setselectedDestinationType({ label: "International", value: 2 });
    }
    setselectedDestination({
      label: record.destination_name,
      value: record.destination_id,
    });
    setselectedCity({
      label: record.city_name,
      value: record.city_id,
    });

    setselectedCityId(record.city_id);
   
    setInputList({ 
                ...inputList, 
                is_active: record.is_active ,
                hotel_id: record.hotel_id   ,
                hotel_name: record.hotel_name,
                hotel_type: record.hotel_type           
              });

    mealPlans.map((item, index) => {
      record.hotelMealPlanList.map((itemRecord, indexRecord) => {
        if (item.meal_plan_code === itemRecord.meal_plan_code) {
          mealPlans[index] = record.hotelMealPlanList[indexRecord];
        }
      });
    });
    record.hotelMealPlanList.map((item, index) => {
      hotelRoomAndMealPlans.push({
        meal_plan_code: item.meal_plan_code,
        adult_price: item.adult_price,
        child_price: item.child_price,
        child_price_without_bed: item.child_price_without_bed,
        meal_plan_id: item.meal_plan_id,
      });
    });
    tog_scroll();
  };
  const date = new Date();
  /**This is for add button handller*/
  const AddHandler = () => {

    debugger;

    setselectedDestinationTypeId(null);
    setReadOnly(false);
    setselectedDestinationType();
    setselectedDestination();

    
     //const global_search_city = JSON.parse(localStorage.getItem(SELECT_GLOBAL_SEARCH_CITY_VALUE_KEY) ?? "[]");
     const global_search_city = defaultCityOption;

     if(global_search_city != null && global_search_city != 0 )
     {
       setselectedCity(global_search_city);
       setselectedCityId(global_search_city.City_id);
     }
    else 
       setselectedCity([]);

    console.log(global_search_city)
    tog_scroll();
  };
  /**This is for cancel handller */
  const cancelHandller = () => {
    setReadOnly(false);
    setShowInvalid(false);
    setmodal_scroll(false);
    /**This for mounting call*/
    getAllList();
    setHotelRoomAndMealPlans([]);
  };
  /**Table and column*/
  const columns = [
    {
      title: "Action",
      dataIndex: "action",
      width: 80,
      render: (text, record) => {
        return (
          <button
            type="button"
            className="btn btn-sm btn-info"
            onClick={() => editHandller(record)}
          >
            {" "}
            Edit{" "}
          </button>
        );
      },
    },
    {
      title: "Destination Type",
      dataIndex: "destination_type_id",
      width: 190,
      filters: destinationTypesFilter,
      // filterMode: "tree",
      onFilter: (value, record) => record.destination_type_id === value,
      render: (text, record) => {
        return record.destination_type_id === 1 ? "Domestic" : "International";
      },
      sorter: {
        compare: (a, b) => a.destination_type_id - b.destination_type_id,
        multiple: 10,
      },
      defaultSortOrder: "ascend",
      // sorter: (a, b) => a.destination_type_id - b.destination_type_id,
      hidden: (showDestinationType == true || showDestinationType == "true" ? false : true ),
    },
    {
      title: "Destination",
      width: 140,
      dataIndex: "destination_name",
      defaultSortOrder: "ascend",
      // ...getColumnSearchProps("destination_name"),
      filters: filterColumnWiseDestination,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.destination_id === value,
      sorter: {
        compare: (a, b) => a.destination_name.localeCompare(b.destination_name),
        multiple: 9,
      },
      hidden: (showDestination == true || showDestination == "true" ? false : true ),
    },
    {
      title: "City",
      width: 140,
      dataIndex: "city_name",
      defaultSortOrder: "ascend",
      // ...getColumnSearchProps("city_name"),
      filters: filterColumnWiseCity,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.city_id === value,
      sorter: {
        compare: (a, b) => a.city_name.localeCompare(b.city_name),
        multiple: 8,
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "Hotel",
      width: 280,
      // ...getColumnSearchProps("hotel_name"),
      dataIndex: "hotel_name",
      defaultSortOrder: "ascend",
      filters: filterColumnWiseHotel,
      // filterMode: "tree",
      filterSearch: true,
      onFilter: (value, record) => record.hotel_id === value,
      sorter: {
        compare: (a, b) => a.hotel_name.localeCompare(b.hotel_name),
        multiple: 7,
      },
      // sortable: true,
    },
    {
      title: "Starting Price",
      dataIndex: "hotel_room_starting_price",
      width: 150,
      defaultSortOrder: "ascend",
      sorter: {
        compare: (a, b) =>
          a.hotel_room_starting_price - b.hotel_room_starting_price,
        multiple: 6,
      },
      render: (text, record) => {
        return (
          <>
            <CurrencyShow
              currency={record?.hotel_room_starting_price}
              currencySymbol={currencySymbol}
            />
            {/* {record?.hotel_room_starting_price?.toLocaleString("en-IN", {
              style: "currency",
              currency: "INR",
            })} */}
          </>
        );
      },
    },
    {
      title: "Is Active",
      dataIndex: "is_active",
      width: 130,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      // filterMode: "tree",
      onFilter: (value, record) => record.is_active === value,
      sorter: {
        compare: (a, b) => a.is_active - b.is_active,
        multiple: 5,
      },
      // sorter: (a, b) => a.is_active - b.is_active,
      render: (text, record) => {
        return (
          <div className="form-check form-switch form-switch-success">
            <Input
              className="form-check-input"
              type="checkbox"
              role="switch"
              id="SwitchCheck3"
              onChange={(e) => (record.is_active = e.target.checked)}
              defaultChecked={record.is_active}
              disabled
            />
          </div>
        );
      },
    },
    {
      title: "Created By",
      width: 250,
      dataIndex: "row_created_by",
      sorter: {
        compare: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
        multiple: 4,
      },
      // sorter: (a, b) => a.row_created_by.localeCompare(b.row_created_by),
      hidden: rowCreatedBy,
    },
    {
      title: "Created On",
      dataIndex: "row_created_date",
      width: 160,
      sorter: {
        compare: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
        multiple: 3,
      },
      // sorter: (a, b) => a.row_created_date.localeCompare(b.row_created_date),
      hidden: rowCreatedDate,
      render: (text, record) => {
        const date = new Date(record.row_created_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
    {
      title: "Modified By",
      width: 250,
      dataIndex: "row_altered_by",
      sorter: {
        compare: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
        multiple: 2,
      },
      // sorter: (a, b) => a.row_altered_by.localeCompare(b.row_altered_by),
      hidden: rowModifiedBy,
    },
    {
      title: "Modified On",
      width: 160,
      dataIndex: "row_altered_date",
      sorter: {
        compare: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
        multiple: 1,
      },
      // sorter: (a, b) => a.row_altered_date.localeCompare(b.row_altered_date),
      hidden: rowModifiedDate,
      render: (text, record) => {
        const date = new Date(record.row_altered_date);
        return (
          <>
            {date.getDate()}/
            {date.toLocaleString("default", { month: "short" })}/
            {date.getFullYear()}
            {` ${date.getHours()}:${date.getMinutes()}`}
          </>
        );
      },
    },
  ].filter((item) => !item.hidden);
  /**This is overAll serch handller */
  const inputHandller = (searchValue) => {
    setSearchText(searchValue);
    if (searchText !== "") {
      const filterData = dataSource.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setFilterData(filterData);
      setExportableData(JSON.parse(JSON.stringify(filterData)));
    } else {
      setFilterData(dataSource);
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  };
  /**This is for export table data to excel*/
  useEffect(() => {
    setExportableData(JSON.parse(JSON.stringify(dataSource)));
  }, [
    dataSource,
    rowCreatedBy,
    rowCreatedDate,
    rowModifiedBy,
    rowModifiedDate,
  ]);
  useEffect(() => {
    if (searchText === "") {
      setExportableData(JSON.parse(JSON.stringify(dataSource)));
    }
  }, [searchText]);

  const [exportedData, setExportedData] = useState([]);
  let exportArray = [];

  /** Export data*/
  useEffect(() => {
    // setExportedData([]);
    if (exportTableData.length > 0) {
      exportTableData?.forEach((elementParent) => {
        elementParent?.hotelMealPlanList?.forEach((element) =>
          exportArray.push({
            destination_type:
              elementParent.destination_type_id === 1
                ? "Domestic"
                : "International",
            destination_name: elementParent.destination_name,
            city_name: elementParent.city_name,
            hotel_name: elementParent.hotel_name,
            hotel_room_starting_price: elementParent.hotel_room_starting_price,
            meal_plan_code: element.meal_plan_code,
            adult_price: element.adult_price,
            child_price: element.child_price,
            child_price_without_bed: element.child_price_without_bed,
            meal_plan_desc: element.meal_plan_desc,
            ...(!rowCreatedBy && {
              row_created_by: elementParent.row_created_by,
            }),
            ...(!rowCreatedDate && {
              row_created_date: elementParent.row_created_date,
            }),
            ...(!rowModifiedBy && {
              row_altered_by: elementParent.row_altered_by,
            }),
            ...(!rowModifiedDate && {
              row_altered_date: elementParent.row_altered_date,
            }),
          })
        );
      });
      setExportedData(exportArray);
    }
  }, [exportTableData, filterData]);

  const navigateImportHotelRooms = () => {
    navigate('../importHotelRooms', {replace: true});
  };


  return (
    <>
      <ToastContainer />
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title="Hotels"
            pageTitle="Masters"
            isSubTitle={true}
          />
          <Row>
            <Col lg={12}>
              <Card>
                {/* <CardHeader>
                  <h4 className="card-title mb-0 flex-grow-1">
                    Hotel-Rooms Add ,Edit
                  </h4>
                </CardHeader> */}
                <CardBody>
                  <div id="customerList">
                    <Row className="g-4 mb-3">
                      <Col className="col-sm-auto">
                        <div>
                          <ButttonTravelNinjaz
                            backGroundColor="success"
                            className="add-btn me-1 my-1"
                            id="create-btn"
                            onCLickHancller={AddHandler}
                            buttonIcon={
                              <i className="ri-add-line align-bottom me-1"></i>
                            }
                            buttonText="Add"
                          />
                          <UncontrolledDropdown
                            style={{ display: "inline-block" }}
                            className="my-1"
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                              type="button"
                            >
                              Export
                            </DropdownToggle>
                            <DropdownMenu>
                              <CSVLink
                                filename={"TravelNinjazB2BDestination.csv"}
                                data={exportedData}
                                className="btn btn-secondary m-1 mx-2"
                                onClick={() => {
                                  message.success("The file is downloading");
                                }}
                              >
                                Export to CSV
                              </CSVLink>
                              <ButttonTravelNinjaz
                                backGroundColor="secondary"
                                className="add-btn m-1 mx-1"
                                id="cancel-btn"
                                onCLickHancller={() =>
                                  downloadExcel(exportedData, "HotelRoomsTable")
                                }
                                buttonText="Export to Excel"
                              />
                            </DropdownMenu>
                          </UncontrolledDropdown>

                          <ButttonTravelNinjaz
                            backGroundColor="primary"
                            className="btn btn-secondary me-1 mx-1 my-1"
                            id="cancel-btn"
                            onCLickHancller={() =>
                              navigateImportHotelRooms()
                            }                            
                            buttonText="Import"                            
                          />

                          <UncontrolledDropdown
                            style={{
                              display: "inline-block",
                            }}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-light mx-1"
                              type="button"
                            >
                              <MoreOutlined style={{ fontSize: "21px" }} />
                            </DropdownToggle>
                            <DropdownMenu>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck1"
                                  onChange={(e) =>
                                    setRowCreatedBy(!e.target.checked)
                                  }
                                  defaultChecked={!rowCreatedBy}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck1"
                                >
                                  Created By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck2"
                                  onChange={(e) =>
                                    setRowCreatedDate(!e.target.checked)
                                  }
                                  defaultChecked={!rowCreatedDate}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck2"
                                >
                                  Created On
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  defaultChecked={!rowModifiedBy}
                                  id="SwitchCheck3"
                                  onChange={(e) =>
                                    setRowModifiedBy(!e.target.checked)
                                  }
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck3"
                                >
                                  Modified By
                                </Label>
                              </div>
                              <div className="form-check form-switch form-switch-success mb-3">
                                <Input
                                  className="form-check-input mx-1"
                                  type="checkbox"
                                  role="switch"
                                  id="SwitchCheck4"
                                  onChange={(e) =>
                                    setRowModifiedDate(!e.target.checked)
                                  }
                                  defaultChecked={!rowModifiedDate}
                                />
                                <Label
                                  className="form-check-label"
                                  htmlFor="SwitchCheck4"
                                >
                                  Modified On
                                </Label>
                              </div>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      </Col>
                      <Col className="col-sm">
                        <div className="d-flex justify-content-sm-end my-1">
                          <div className="search-box ms-2">
                            <input
                              type="text"
                              className="form-control search"
                              placeholder="Search..."
                              onChange={(e) => inputHandller(e.target.value)}
                            />
                            <i className="ri-search-line search-icon"></i>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    {searchText.length > 0 ? (
                      <Table
                        dataSource={filterData}
                        scroll={{
                          y: 320,
                        }}
                        columns={columns}
                        pagination={{
                          defaultPageSize: 10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        expandedRowRender={expandedRowRender}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    ) : (
                      <Table
                        dataSource={dataSource}
                        expandedRowRender={expandedRowRender}
                        columns={columns}
                        scroll={{
                          y: 320,
                        }}
                        pagination={{
                          defaultPageSize:10,
                          showSizeChanger: true,
                          pageSizeOptions: [10, 20, 50, 100],
                          showTotal: (total, range) =>
                            `${range[0]}-${range[1]} of ${total} items  `,
                        }}
                        bordered
                        locale={{
                          triggerDesc: null,
                          triggerAsc: null,
                          cancelSort: null,
                        }}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Add Modal */}
      <Modal
        isOpen={modal_scroll}
        toggle={() => {
          tog_scroll();
        }}
        centered
        size="xl"
        scrollable={true}
        // style={{ height: "100vh" }}
        // className="modal-fullscreen"
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            tog_scroll();
          }}
        >
          {!readOnly ? "Add " : "Edit"} - Hotel
        </ModalHeader>
        <ModalBody>
        <form>
          <Row>
          <Col lg={12}>
              <Card>
                  <PreviewCardHeader title="Hotel" />

                  <CardBody className="card-body">
                      <div className="live-preview">
                          <Row className="gy-4">

                          {(showDestinationType == "true" || showDestinationType == true )? (
                              <Col xxl={6}>
                              <div>
                                <label htmlFor="destinationType" className="form-label">
                                  Destination Type
                                </label>
                                <Select
                                  value={selectedDestinationType}
                                  id="destinationType"
                                  onChange={(chooseOption) => {
                                    selectDestinationTypeHandller(chooseOption);
                                  }}
                                  options={destinationTypes}
                                  components={animatedComponents}
                                  isDisabled={readOnly}
                                />
                              </div>
                            </Col>
                              ) :  <>  </>
                            }

                            {(showDestination == "true" || showDestination == true )? (
                                    <Col xxl={6}>
                                    <div>
                                      <label htmlFor="destination" className="form-label">
                                        Destination
                                      </label>
                                      <Select
                                        value={selectedDestination}
                                        id="destination"
                                        onChange={(chooseOption) => {
                                          selectDestinationHandller(chooseOption);
                                        }}
                                        options={destinationOption}
                                        components={animatedComponents}
                                        isDisabled={readOnly}
                                      />
                                    </div>
                                  </Col>
                              ) :  <>  </>
                              }
            
                    <Col xxl={6}>
                      <div>
                        {" "}
                        <label htmlFor="city" className="form-label">
                          City
                        </label>
                        <Select
                          value={selectedCity}
                          id="city"
                          isDisabled={readOnly}
                          onChange={(chooseOption) => {
                            selectCityHandller(chooseOption);
                          }}
                          options={cityOption}
                          components={animatedComponents}
                          required
                        />

                        {(selectedCity && Object.entries(selectedCity).length === 0 ) &&  showInvalid && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            Please select City
                          </p>
                        )}

                      </div>
                    </Col>
                    <Col xxl={6}>
                      <div>
                        <label htmlFor="hotel" className="form-label">
                          Hotel Name
                        </label>
                       
                              <Input
                                  type="text"
                                  className="form-control"
                                  name={"hotel_name"}
                                  id={"hotel_name"}
                                  value={inputList.hotel_name}
                                  defaultValue={inputList.hotel_name}
                                  onChange={(e) => onChangeHandller(e)}
                                  invalid={
                                    inputList.hotel_name < 1 && showInvalid
                                  }
                                  // readOnly={readOnly}
                                  required
                                />

                        {!inputList.hotel_name && showInvalid && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "5px",
                            }}
                          >
                            {" "}
                            Please Enter Hotel Name
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col xxl={6} md={6}>
                        <div>
                            <Label htmlFor="basiInput" className="form-label">Hotel Ratings</Label>
                          
                            <div dir="ltr">
                              <div id="rater-onhover" className="align-middle d-inline">
                                <Rating
                                  style={{ fontSize: "22px" }}
                                  stop={5}
                                  emptySymbol="mdi mdi-star-outline text-muted "
                                  fullSymbol="mdi mdi-star text-warning "
                                  initialRating={inputList.hotel_type || 0}
                                  onChange={(customize) => {
                                    inputList.hotel_type = customize;
                                  }}                                 
                                />
                              </div>
                            </div>

                        </div>
                    </Col>
                    <Col xxl={6} md={6}>
                    <div className="form-check form-switch form-switch-success my-3">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          name="is_active"

                          id="SwitchCheck3"
                          onChange={(e) => onChangeHandller(e)}
                          defaultChecked={inputList.is_active}
                        />
                        <Label className="form-check-label" htmlFor="SwitchCheck3">
                          Is Active
                        </Label>
                    </div>
                    </Col>
                   
                </Row>
            </div>
                                  
                    </CardBody>
                </Card>
            </Col>

                <Col lg={12}>
                    <Card>
                        <PreviewCardHeader title="Hotel Rooms" />

                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                  

                                    <Col xxl={12} md={12}>
                                      
                                    <div className="table-responsive table-card mt-3 mb-1">
                                      <table
                                        className="table align-middle table-nowrap"
                                        id="customerTable"
                                      >
                                        <thead className="table-light">
                                          <tr>
                                            <th className="">Available</th>
                                            <th className="">Meal Plans</th>
                                            <th className="">Adult Price (2 pax)</th>
                                            <th className="">Child/ Ex Adult Price (with bed)</th>
                                            <th className="">Child Price (without bed)</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {mealPlans.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <Input
                                                    type="checkbox"
                                                    value={item.meal_plan_code}
                                                    name={`checkBox${item.meal_plan_desc}`}
                                                    onChange={(e) =>
                                                      checkBoxHandller(e, item.meal_plan_id)
                                                    }
                                                    defaultChecked={item.is_active}
                                                  ></Input>
                                                </td>
                                                <td className="roomonly">{item.meal_plan_desc}</td>
                                                <td className="price">
                                                  <div className="input-group">
                                                    <span
                                                      className="input-group-text"
                                                      id="basic-addon1"
                                                    >
                                                      ₹
                                                    </span>
                                                    <Input
                                                      type="number"
                                                      name="adult_price"
                                                      id={item.meal_plan_id}
                                                      // step="0.01"
                                                      // value={
                                                      //   !item.is_active
                                                      //     ? ""
                                                      //     : item.adult_price
                                                      //     ? new Intl.NumberFormat("en-IN").format(
                                                      //         item.adult_price
                                                      //       )
                                                      //     : item.adult_price
                                                      // }
                                                      value={
                                                        !item.is_active ? "" : item.adult_price
                                                      }
                                                      readOnly={!item.is_active}
                                                      onChange={(e) =>
                                                        // onChangeHandller(currencyMask(e))
                                                        onChangeHandller(e)
                                                      }
                                                      min={0}
                                                      invalid={
                                                        item.is_active &&
                                                        !item.adult_price &&
                                                        showInvalid
                                                      }
                                                    ></Input>
                                                  </div>
                                                  {item.is_active &&
                                                    !item.adult_price &&
                                                    showInvalid && (
                                                      <p style={{ color: "red" }}>
                                                        Adult Price is required
                                                      </p>
                                                    )}
                                                </td>
                                                <td>
                                                  <div className="input-group">
                                                    <span
                                                      className="input-group-text"
                                                      id="basic-addon1"
                                                    >
                                                      ₹
                                                    </span>
                                                    <Input
                                                      name="child_price"
                                                      id={item.meal_plan_id}
                                                      type="number"
                                                      min={0}
                                                      value={
                                                        !item.is_active ? "" : item.child_price
                                                      }
                                                      readOnly={!item.is_active}
                                                      onChange={(e) =>
                                                        // onChangeHandller(currencyMask(e))
                                                        onChangeHandller(e)
                                                      }
                                                      invalid={
                                                        item.is_active &&
                                                        !item.child_price &&
                                                        showInvalid
                                                      }
                                                    ></Input>
                                                  </div>
                                                  {item.is_active &&
                                                    !item.child_price &&
                                                    showInvalid && (
                                                      <p style={{ color: "red" }}>
                                                        Child Price is required
                                                      </p>
                                                    )}
                                                </td>
                                                <td>
                                                  <div className="input-group">
                                                    <span
                                                      className="input-group-text"
                                                      id="basic-addon1"
                                                    >
                                                        <CurrencyShow
                                                          currency={0}
                                                          currencySymbol={currencySymbol}
                                                        />
                                                    </span>
                                                    <Input
                                                      name="child_price_without_bed"
                                                      id={item.meal_plan_id}
                                                      type="number"
                                                      min={0}
                                                      value={
                                                        !item.is_active
                                                          ? ""
                                                          : item.child_price_without_bed
                                                      }
                                                      readOnly={!item.is_active}
                                                      onChange={(e) =>
                                                        // onChangeHandller(currencyMask(e))
                                                        onChangeHandller(e)
                                                      }
                                                      invalid={
                                                        item.is_active &&
                                                        !item.child_price_without_bed &&
                                                        showInvalid
                                                      }
                                                    ></Input>
                                                  </div>
                                                  {item.is_active &&
                                                    !item.child_price_without_bed &&
                                                    showInvalid && (
                                                      <p style={{ color: "red" }}>
                                                        Child Price without bed is required
                                                      </p>
                                                    )}
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      </table>
                                    </div>

                                    </Col>

                                </Row>

                            </div>
                          
                        </CardBody>
                    </Card>
                </Col>

            </Row>

          </form>
        </ModalBody>
        <div className="modal-footer">
          {hotelRoomAndMealPlans.length < 1 && showInvalid && (
            <>
              {" "}
              <Alert
                color="danger"
                className="alert-border-left mb-xl-0"
                style={{ marginRight: "auto" }}
              >
                <i className="ri-error-warning-line me-3 align-middle fs-16"></i>
                Please select Atleast One meal plan.
              </Alert>
            </>
          )}

          {/* <Button color="light" onClick={cancelHandller}>
            Cancel
          </Button> */}

          {/* <Button color="primary" onClick={modalSaveHandller}>
            Save{" "}
          </Button> */}
          <ButttonTravelNinjaz
            backGroundColor={"primary"}
            onCLickHancller={modalSaveHandller}
            buttonText={"Save"}
          />
          <ButttonTravelNinjaz
            backGroundColor={"danger"}
            onCLickHancller={cancelHandller}
            buttonText={"Cancel"}
          />
        </div>
      </Modal>
    </>
  );
};

export default HotelRooms;
